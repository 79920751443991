import { deleteAllUrlParams } from '../url-params/url-params'
export default class ClearTag {
    constructor() {
        this.tagsParent = document.getElementById('filter_tags')
        this.clearTag = document.createElement('a')
        this.clearTag.setAttribute('class', 'clear-tag button')
        this.clearTag.innerText = `${window.globals.trans('filter.clear_all_filters')}`
        this.clearTag.addEventListener('click', () => {
            const allTags = this.tagsParent.querySelectorAll('.filter-tag')
            allTags.forEach((tag) => {
                tag.click()
            })
            deleteAllUrlParams()
        })
    }

    render() {
        return this.clearTag
    }
}
