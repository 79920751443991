import genericBodyWithVariants from './genericBodyWithVariants'
import genericBodyWithSubvariants from './genericBodyWithSubvariants'
import subvariantsBody from './subvariantsBody'
import swrBody from './swrBody'
import genericBodyWithSwr from './genericBodyWithSwr'
import VARIANTS_TYPES from '../../config/variants-config'

export default class bodiesFactory {
    constructor(type, props = {}) {
        if (type === VARIANTS_TYPES.GENERIC_VARIANTS) return new genericBodyWithVariants(props)
        if (type === VARIANTS_TYPES.GENERIC_SUBVARIANTS) return new genericBodyWithSubvariants(props)
        if (type === VARIANTS_TYPES.SUBVARIANTS) return new subvariantsBody(props)
        if (type === VARIANTS_TYPES.GENERIC_VARIANTS_SWR) return new genericBodyWithSwr(props)
        if (type === VARIANTS_TYPES.SWR) return new swrBody(props)
    }
}
