import rowsFactory from '../rowsFactory/rowsFactory'
import { selectRow } from '../partials/rowController'
import VARIANTS_TYPES from '../../config/variants-config'
export default class subvariantsBody {
    constructor(props) {
        this.props = props
        this.type = props.type
        this.tbody = document.createElement('tbody')
        this.productVariantPreselection = props.data.options.productVariantPreselection
    }
    render() {
        let isEven = false
        this.tbody.setAttribute('class', 'v-tbody subvariants-body')
        this.props.data.variants.map((variant) => {
            const parentRow = new rowsFactory(VARIANTS_TYPES.SUBVARIANTS_PARENT, { ...this.props, isEven, variant }).render()
            const childRow = new rowsFactory(VARIANTS_TYPES.SUBVARIANTS_CHILD, { ...this.props, variant }).render()
            this.tbody.appendChild(parentRow)
            this.tbody.appendChild(childRow)
            isEven = !isEven
        })

        if (this.productVariantPreselection) selectRow.first(this.type, this.tbody)
        return this.tbody
    }
}
