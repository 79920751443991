//
// Cart
//

import CartManager from './cart-manager'
;(($) => {
    $(() => {
        let module = $('.shopping-cart-wrapper')

        // Only continue if module is on page
        if (!module.length) return

        const cartExpandQuoteButton = $("form[name='coreshop_cart']").find('.expand-quote')
        const cartSummaryBlock = $('.cart-refresh-summary-block')
        const quoteFormWrapper = $('.cart-quote-form-wrapper')
        const quoteFormBackButton = quoteFormWrapper.find('button.back')
        const cartBody = module.find('.cart-body-wrapper')
        const totalSaving = module.find('.cart-summary-info-wrapper').find('.cart-total-saving .value')
        const totalPrice = module.find('.cart-summary-info-wrapper').find('.cart-total .value')
        const checkoutButton = module.find('.cart-buttons .checkout')
        const spinnerContainer = module.find('.cart-spinner-container')
        let erpIndividualPricesInt = null
        const locale = document.documentElement.lang || 'en'

        cartExpandQuoteButton.on('click', (e) => {
            quoteFormWrapper.addClass('expanded')
            cartSummaryBlock.addClass('collapsed')
            $(e.currentTarget).attr('aria-expanded', 'true')
            quoteFormBackButton.attr('aria-expanded', 'true')
        })

        quoteFormBackButton.on('click', (e) => {
            quoteFormWrapper.removeClass('expanded')
            cartSummaryBlock.removeClass('collapsed')
            $(e.currentTarget).attr('aria-expanded', 'false')
            cartExpandQuoteButton.attr('aria-expanded', 'false')
        })

        $('.cart-quote-form-wrapper')
            .find('#quote_form_business_logged_in')
            .on('submit', function (e) {
                e.preventDefault()
                // $(this).find("button[type='submit']").attr("disabled", true);
                window.grecaptcha.reset()
                window.grecaptcha.execute()
            })

        checkoutButton.on('click', function () {
            if ($(this).hasClass('disabled')) {
                return false
            }
        })

        const certificateCheckboxes = module.find('.item-certificate')
        certificateCheckboxes.change(function () {
            let itemId = $(this).attr('data-item-id')
            CartManager.updateItemCertificate(itemId, $(this).is(':checked'))
        })

        if (spinnerContainer.length) {
            queryAndRefreshIndividualPrices()

            erpIndividualPricesInt = setInterval(() => {
                queryAndRefreshIndividualPrices(erpIndividualPricesInt)
            }, 4000)
        }

        function refreshPricesAndUnlockCart(data) {
            // cart items
            cartBody.find('.cart-item-row').each(function () {
                let item = $(this)
                let itemData = data[item.attr('id')]
                if (itemData) {
                    item.find('.discount').find('.value').text(itemData.discount)
                    item.find('.subtotal').find('.value').text(itemData.subtotal)
                }
            })

            // total saving
            totalSaving.text(data.cartDiscount)

            // total price
            totalPrice.text(data.total)

            // hide spinner
            spinnerContainer.hide()

            // unlock checkout button
            checkoutButton.removeClass('disabled')
        }

        function queryAndRefreshIndividualPrices(interval = null) {
            $.ajax({
                type: 'GET',
                url: `/${locale}/shop/cart/price`,
                cache: false,
                success: (data) => {
                    if (Object.keys(data).length) {
                        if (interval) clearInterval(interval)
                        refreshPricesAndUnlockCart(data)
                    }
                },
                error: () => {
                    console.log('Error while fetching cart individual prices.')
                },
            })
        }
    })
})(jQuery)
