/**
 * TimedCallback
 *
 * Call a function after a interruptible delay
 */

export default class TimedCallback {
    constructor(callback, delay) {
        this.callback = callback
        this.removeDelay = delay
    }

    start(delay) {
        this.pause()
        this.active = true
        this.timerTotal = 0
        if (delay != null) {
            this.removeDelay = delay
        }
        this.resume()
    }

    stop() {
        this.pause()
        this.active = false
    }

    resume() {
        if (this.active && !this.timerId && this.timerTotal < this.removeDelay) {
            this.timerStart = Date.now()
            this.timerId = setTimeout(() => {
                this.timerDone()
            }, this.removeDelay - this.timerTotal)
        }
    }

    pause() {
        if (this.timerId) {
            this.timerTotal += Date.now() - this.timerStart
            clearTimeout(this.timerId)
            this.timerId = null
        }
    }

    timerDone() {
        if (this.active) {
            this.active = false
            this.pause()
            this.callback()
        }
    }
}
