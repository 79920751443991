let progressbarComponent

if (window.Vue) {
    progressbarComponent = window.Vue.component('progressbar-component', {
        props: {
            translations: Object,
            progress: Number,
            smallProgressbar: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
        computed: {
            progressWidth() {
                return {
                    width: this.progress + '%',
                }
            },
        },
        template: `
            <div class="progressbar-container" :class="{'fs-13 pb-2 border-bottom-catskill-white': smallProgressbar }">
                <div class="progress-horizontal">
                  <div class="bar-horizontal"></div>
                </div>
                
                <div v-if="!progress" class="text-center mt-2">{{ translations["orders_history.progressbar_waiting_for_data"] }}</div>
                <div v-else class="text-center mt-2">{{ translations["orders_history.progressbar_fetching_orders"] }}</div>

                <div class="text-center">{{ translations["orders_history.progressbar_info"] }}</div>
            </div>
        `,
    })
} else {
    progressbarComponent = null
}

export default progressbarComponent
