import { getUrlParams } from '../url-params/url-params'

const prepareFilterData = () => {
    let dataToSend = {}
    const groups = document.querySelectorAll('.checkbox-group, .range-group, .radio-group')

    groups.forEach((group) => {
        const groupName = group.dataset.name
        dataToSend[groupName] = []
        let inputs = null
        let type = null

        if (group.classList.contains('radio-group')) {
            type = 'radio'
        } else if (group.classList.contains('range-group')) {
            type = 'range'
        } else if (group.classList.contains('checkbox-group')) {
            type = 'checkbox'
        }

        if (type === 'checkbox') {
            inputs = group.querySelectorAll('input[type=checkbox]:checked')
            inputs.forEach((element) => {
                dataToSend[groupName] = [...dataToSend[groupName], element.id]
            })
        } else if (type === 'range') {
            const inputFromInitVal = parseFloat(group.querySelectorAll('input[type=input]')[0].dataset.value)
            const inputToInitVal = parseFloat(group.querySelectorAll('input[type=input]')[1].dataset.value)

            const inputFromVal = parseFloat(group.querySelectorAll('input[type=input]')[0].value)
            const inputToVal = parseFloat(group.querySelectorAll('input[type=input]')[1].value)

            const hasChange = inputFromInitVal.toFixed(2) !== inputFromVal.toFixed(2) || inputToInitVal.toFixed(2) !== inputToVal.toFixed(2)

            if (hasChange) {
                if (inputFromVal !== '' || inputToVal !== '') {
                    dataToSend[groupName] = [
                        {
                            from: inputFromVal,
                            to: inputToVal,
                        },
                    ]
                }
            }
        } else if (type === 'radio') {
            inputs = group.querySelectorAll('input[type=radio]:checked')
            inputs.forEach((element) => {
                const radioVal = element.value
                dataToSend[groupName] = radioVal
            })
        }
    })

    const dataFromUrl = getUrlParams()
    if (dataFromUrl.query) {
        dataToSend.query = dataFromUrl.query
    }

    return dataToSend
}

export default prepareFilterData
