import Util from '../utils/utils'

//
// Homepage News
//
;(($) => {
    $(() => {
        let module = $('.area-news')

        // Only continue if on page
        if (!module.length) return

        let newsCarousel = module.find('.owl-carousel')

        if (Util.viewport() === 'xs' || Util.viewport() === 'sm') {
            initCarousel()
        }

        window.onresize = () => {
            if (timerId) {
                clearTimeout(timerId)
                timerId = null
            }

            timerId = setTimeout(carouselHandler, delayMs)
        }

        let delayMs = 200
        let timerId

        function carouselHandler() {
            if (Util.viewport() === 'xs' || Util.viewport() === 'sm') {
                // Init carousel if it does not exist
                if (!newsCarousel.find('.owl-stage').length) {
                    initCarousel()
                }
            } else {
                // destroy carousel if it exists
                if (newsCarousel.find('.owl-stage').length) {
                    newsCarousel.trigger('destroy.owl.carousel')
                }
            }
        }

        function initCarousel() {
            newsCarousel.owlCarousel({
                items: 1,
                nav: true,
                autoHeight: false,
                navText: [
                    '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#previous"></use></svg>',
                    '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#next"></use></svg>',
                ],
                dots: false,
                thumbs: false,
            })
        }
    })
})(jQuery)
