import Util from '../utils/utils'

//
// Office group view
//
;(($) => {
    $(() => {
        let module = $('.office-group-view')

        // Only continue if module is on page
        if (!module.length) return

        let offices = module.find('.offices')
        let countryLabel = offices.find('strong')
        let countryToggle = countryLabel.find('> .icon')
        let countryOffices = countryLabel.next('ul')

        countryToggle.click(function () {
            if (Util.viewport() === 'xs') {
                $(this).closest('strong').next('ul').collapse('toggle')
            }
        })

        countryOffices.on('hide.bs.collapse', function () {
            $(this).prev('strong').addClass('collapsed')
        })

        countryOffices.on('show.bs.collapse', function () {
            $(this).prev('strong').removeClass('collapsed')
        })
    })
})(jQuery)
