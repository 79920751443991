import * as TracePartsApi from '../../../../api/trace-parts'
import multiDownload from '../../../../multi-download/multi-download'
import { onCadDownloadClick } from '../../../../gtm/cad'

const productCad = $('.product-cad')
const tracePartsContainer = $('.trace-parts-container')
const tracePartsHubSpotPlaceholder = $('.trace-parts-hubspot-form-placeholder')
const tracePartsResults = tracePartsContainer.find('.trace-parts-results')
const tracePartsDownloads = tracePartsContainer.find('.trace-parts-downloads')
const tracePartsResultsLoaderEl = tracePartsContainer.find('.trace-parts-loading-text')
const tracePartsNotificationEl = document.querySelector('.trace-parts-notification')
const hubSpotFormId = tracePartsHubSpotPlaceholder.attr('data-hbspt-form-id')
const hubSpotPortalId = tracePartsHubSpotPlaceholder.attr('data-hbspt-portal-id')
const hubSpotRegion = tracePartsHubSpotPlaceholder.attr('data-hbspt-region')
const isHubSpotLoaded = typeof hbspt !== 'undefined'
let customerEmail = ''

const initHubspotForm = () => {
    if (!hubSpotFormId) console.error('Hubspot "formId" for CAD download not provided.')
    if (!hubSpotPortalId) console.error('Hubspot "portalId" for CAD download not provided.')
    if (!hubSpotRegion) console.error('Hubspot "region" for CAD download not provided.')
    if (!isHubSpotLoaded) console.error('Hubspot script not loaded.')

    if (!isHubSpotLoaded || !hubSpotRegion || !hubSpotFormId || !hubSpotPortalId) return

    // eslint-disable-next-line no-undef
    hbspt.forms.create({
        region: hubSpotRegion,
        portalId: hubSpotPortalId,
        formId: hubSpotFormId,
        target: '.trace-parts-hubspot-form-placeholder',
        onFormSubmitted: function ($form, data) {
            customerEmail = data.submissionValues.email
            unlockFormatSelect()
        },
    })
}

if (tracePartsHubSpotPlaceholder.length > 0) initHubspotForm()

const unlockFormatSelect = () => {
    $('#trace_parts_formats').attr('disabled', false).selectric('refresh')
}
const blockFormatSelect = () => {
    $('#trace_parts_formats').attr('disabled', true).selectric('refresh')
}

const resetNotifications = (element) => {
    while (element.firstChild && element.removeChild(element.firstChild));
}

const generateCADButtons = (selectric, partNo, partCode) => {
    tracePartsResultsLoaderEl.show()
    blockFormatSelect()
    tracePartsDownloads.empty()
    const index = selectric.state.selectedIdx
    const formatId = selectric.items[index].value
    const formatName = selectric.items[index].text
    const email = customerEmail
    const id = partNo
    TracePartsApi.getDownloadPaths({ id, formatId, email }).then((resp) => {
        const paths = resp.data.paths
        tracePartsResultsLoaderEl.hide()

        paths.map((path, index) => {
            const btn = generateDownloadButton(path, formatName, index, partCode)
            tracePartsDownloads.append(btn)
        })
        if (paths.length > 1) {
            const btnDownloadAll = generateDownloadAllButton(paths, partCode)
            tracePartsDownloads.append(btnDownloadAll)
        }
        unlockFormatSelect()
    })
}

const generateDownloadButton = (path, formatName, index, partCode) => {
    const element = document.createElement('a')
    const icon = document.createElement('i')
    icon.className = 'icon-download-cad'
    element.href = path
    element.className = 'trace-parts-format'
    element.text = `${formatName} - ${index + 1}`
    element.append(icon)
    resetNotifications(tracePartsNotificationEl)
    element.addEventListener('click', () => {
        onCadDownloadClick(partCode, 'cad_download')
        if (!/^(f|ht)tps?:\/\//i.test(path)) {
            tracePartsNotificationEl.innerHTML = `<div class="products-table-info">${window.globals.trans(
                'product-page.application-needed-notification',
            )}</div>`
        }
    })
    return element
}

const generateDownloadAllButton = (paths, partCode) => {
    const element = document.createElement('a')
    const icon = document.createElement('i')
    icon.className = 'icon-download-cad'
    element.href = '#'
    element.className = 'trace-parts-download-all'
    element.text = `Download all`
    element.append(icon)
    element.addEventListener('click', (e) => {
        e.preventDefault()
        onCadDownloadClick(partCode, 'cad_download')
        multiDownload(paths)
    })
    return element
}

const initCadModalForm = (e, partNo, partCode) => {
    tracePartsResults.hide()
    tracePartsDownloads.empty()
    productCad.addClass('product-cad-show')
    tracePartsResultsLoaderEl.show()

    TracePartsApi.getFormats({ id: partNo }).then((resp) => {
        const formats = resp.data.formats
        tracePartsResultsLoaderEl.hide()
        tracePartsResults.find('.selectric-trace-parts-formats').remove()
        tracePartsResults.prepend(`
            <select id="trace_parts_formats" class="trace-parts-formats">
                <option value="" disabled selected>${window.globals.trans('product-page.choose-cad-format')}</option>
                ${formats.map((format) => `<option value="${format.id}">${format.name}</option>`).join('')}
            </select>
        `)
        tracePartsResults.show()

        $('#trace_parts_formats').selectric({
            nativeOnMobile: false,
            onChange: (select, selectric) => {
                generateCADButtons(selectric, partNo, partCode)
            },
        })
        if (customerEmail !== '') {
            unlockFormatSelect()
        } else {
            blockFormatSelect()
        }
    })
}

export { initCadModalForm, generateCADButtons, generateDownloadButton, generateDownloadAllButton }
