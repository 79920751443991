//
// Loader
//

const startLoader = (loaderID) => {
    const element = document.getElementById(loaderID)
    element.classList.add('now-loading')
}
const stopLoader = (loaderID) => {
    const element = document.getElementById(loaderID)
    element.classList.remove('now-loading')
}

export { startLoader, stopLoader }
