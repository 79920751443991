import { getPercentage } from '../helpers/helpers'

const onVideoStateChange = (event, duration, filename) => {
    const type = event.type
    let eventLabel = type.toUpperCase()
    const currentTime = event.target.currentTime
    const percentage = Math.round(getPercentage(currentTime, duration))

    // Video Playing
    if (type === 'playing') {
        eventLabel = `PLAYING FROM ${percentage}%`
    }

    // Video Paused
    if (type === 'pause') {
        eventLabel = `PAUSED ON ${percentage}%`
    }

    pushData(eventLabel, filename)
}

const pushData = (eventLabel, filename) => {
    window.dataLayer.push({
        event: 'GA Tracking',
        eventAction: filename,
        eventCategory: 'Video',
        eventLabel: eventLabel,
    })
}

export { onVideoStateChange }
