import cellsFactory from '../cellsFactory/cellsFactory'
import VARIANTS_TYPES from '../../config/variants-config'

export default class subvariantsChildRow {
    constructor(props) {
        this.props = props
        this.row = document.createElement('tr')
    }
    render() {
        this.row.className = 'v-tr swr-child-row'
        const td = new cellsFactory(VARIANTS_TYPES.EMPTY, { colspan: '100%' }).render()
        const swrContent = document.createElement('div')
        swrContent.classList.add('swr-content')
        td.appendChild(swrContent)
        this.row.appendChild(td)
        return this.row
    }
}
