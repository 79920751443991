//
// GTM event tracking
//

import { onCategoryBannerClick } from './category-banner'
;(($) => {
    $(() => {
        const blueprintButton = $('#view-drawing-button')
        let isVisible = false
        blueprintButton.click(() => {
            isVisible = !isVisible
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'GA Tracking',
                    eventCategory: 'Blueprint',
                    eventAction: 'Download blueprint',
                    eventLabel: document.getElementsByClassName('npp__title--heading1')[0].innerText,
                    eventValue: 0,
                    eventNonInteraction: false,
                })
            }
        })

        $('.shopping-cart-wrapper')
            .find('.item-remove a')
            .click(function () {
                if (window.dataLayer) {
                    let itemRow = $(this).parents('.cart-item-row')
                    window.dataLayer.push({
                        event: 'removeFromCart',
                        ecommerce: {
                            currencyCode: itemRow.attr('data-currencyCode'),
                            remove: {
                                products: [
                                    {
                                        id: itemRow.attr('data-item-id'),
                                        name: itemRow.attr('data-item-name'),
                                        price: itemRow.attr('data-item-price'),
                                        category: itemRow.attr('data-item-category'),
                                        quantity: parseInt(itemRow.attr('data-item-quantity'), 10),
                                    },
                                ],
                            },
                        },
                    })
                }
            })

        $('#btn-login').click(function () {
            sessionStorage.setItem('isVisited', 'false')
        })

        if ($('.my-profile').length) {
            if (window.dataLayer && sessionStorage.getItem('isVisited') === 'false') {
                sessionStorage.setItem('isVisited', 'true')
                window.dataLayer.push({ event: 'GA Tracking', eventCategory: 'User', eventAction: 'Login' })
            }
        }

        const globalMessageLink = $('.global-message').find('a')
        if (globalMessageLink) {
            globalMessageLink.click(function (e) {
                onCategoryBannerClick(e, 'sticky')
            })
        }
    })
})(jQuery)
