import YouTubePlayer from 'youtube-player'
import { onPlayerStateChange, onPlayerError } from '../../gtm/youtube'
//
// Videos
//
;(($) => {
    $(() => {
        let modules = document.querySelectorAll('.area-videos')

        // Only continue if module is on page
        if (!modules.length) return

        let players = []

        const setActiveThumb = (allElements, activeElement) => {
            allElements.forEach((thumb) => {
                thumb.classList.remove('active')
            })
            activeElement.classList.add('active')
        }

        const initFirstMovie = (iFrameId, movieId, videoImageContainer) => {
            initPlayer(iFrameId)
            loadVideo(iFrameId, movieId)
            videoImageContainer.remove()
        }

        const initPlayer = (iframeId) => {
            let player = YouTubePlayer(iframeId, {
                width: null,
                height: null,
            })
            player.on('stateChange', (event) => {
                onPlayerStateChange(event)
            })
            player.on('onError', (event) => {
                onPlayerError(event)
            })
            players.push({
                iframeId,
                player,
            })
        }

        const findPlayerByIframeId = (iFrameId) => {
            return players.find((item) => item.iframeId === iFrameId)?.player
        }

        const loadVideo = (iFrameId, videoId) => {
            const player = findPlayerByIframeId(iFrameId)
            player.loadVideoById(videoId)
            player.playVideo()
        }

        const stopAllPlayers = () => {
            players.forEach((item) => {
                item.player.stopVideo()
            })
        }

        modules.forEach((areaVideo) => {
            const videosThumbnailsContainer = areaVideo.querySelectorAll('.videos-list li')
            const videoContainer = areaVideo.querySelector('.video-player')
            const videoImageContainer = videoContainer.querySelector('.video-image-container')
            const videoData = JSON.parse(videoContainer.getAttribute('data-video'))
            const iFrameId = videoContainer.querySelector('.video-iframe').id

            videoImageContainer.addEventListener('click', function (e) {
                e.preventDefault()
                initFirstMovie(iFrameId, videoData.id, videoImageContainer)
            })
            videosThumbnailsContainer.forEach((thumb) => {
                const id = thumb.firstElementChild.getAttribute('data-youtubeid')
                thumb.addEventListener('click', function (e) {
                    e.preventDefault()
                    stopAllPlayers()
                    setActiveThumb(videosThumbnailsContainer, e.currentTarget)
                    if (findPlayerByIframeId(iFrameId)) {
                        loadVideo(iFrameId, id)
                    } else {
                        initFirstMovie(iFrameId, id, videoImageContainer)
                    }
                })
            })
        })
    })
})(jQuery)
