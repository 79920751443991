import axios from 'axios'

const devMode = false

axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
}

const PROTOCOL = window.location.protocol
const API_URL = `product-data`
const LOCALE = document.documentElement.getAttribute('data-locale') || 'en_GB'
const FRESH_PRICE_LOCALE = document.documentElement.lang || 'en'
const newProductCardSelector = $('.new-product-card')

let FULL_PATH = `/${LOCALE}/${API_URL}`
let INIT_FILTERS_FULL_PATH = `/${LOCALE}/${API_URL}/initial-filters`
let FRESH_PRICE_PATH = `/${FRESH_PRICE_LOCALE}/${API_URL}/fresh-price`
let DISCOUNT_PRICE_PATH = `/${FRESH_PRICE_LOCALE}/${API_URL}/discount-price`
axios.defaults.baseURL = `${PROTOCOL}//${window.location.hostname}`

if (devMode) {
    FULL_PATH = 'http://localhost:3333/mock/api/product-data-sub'
    INIT_FILTERS_FULL_PATH = 'http://localhost:3333/mock/api/product-data/initial-filters'
    FRESH_PRICE_PATH = 'http://localhost:3333/mock/api/product-data/fresh-price'
}

const getCategoryId = () => newProductCardSelector.data('category-id')
const getProductId = () => newProductCardSelector.data('product-id')

const getFilters = async () => {
    const categoryId = getCategoryId()
    const productId = getProductId()
    return await axios.get(INIT_FILTERS_FULL_PATH, {
        params: {
            categoryId,
            productId,
        },
    })
}

const getPage = async (page, filterData) => {
    const categoryId = getCategoryId()
    const productId = getProductId()
    return await axios.get(FULL_PATH, {
        params: {
            categoryId,
            productId,
            pageNo: page,
            ...filterData,
        },
    })
}

const postFilters = async (data) => {
    const categoryId = getCategoryId()
    const productId = getProductId()
    return await axios.get(FULL_PATH, {
        params: Object.assign(
            {
                categoryId,
                productId,
            },
            data,
        ),
    })
}

const getFreshPrice = async (PartCode) => {
    return await axios.get(FRESH_PRICE_PATH, {
        params: {
            PartCode,
        },
    })
}

const getDiscountProductPrice = async (id) => {
    return await axios.get(DISCOUNT_PRICE_PATH, {
        params: {
            id,
        },
    })
}

export { getFilters, getPage, postFilters, getFreshPrice, getDiscountProductPrice }
