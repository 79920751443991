import columnsHeader from './columnsHeader'
import subColumnsHeader from './subColumnsHeader'
import VARIANTS_TYPES from '../../config/variants-config'

export default class headersFactory {
    constructor(type, props = {}) {
        if (type === VARIANTS_TYPES.COLUMNS_HEADER) return new columnsHeader(props)
        if (type === VARIANTS_TYPES.SUBCOLUMNS_HEADER) return new subColumnsHeader(props)
    }
}
