//
// ApplyForm
//

;(($) => {
    $(() => {
        const module = $('.apply-form-step')

        // Only continue if module is on page
        if (!module.length) return

        // init tooltip
        $('[data-toggle="form-tooltip"]').tooltip()

        const countrySelect = $('.address-data').find('select')
        countrySelect.selectric()

        // disable on init
        countrySelect.attr('disabled', true).selectric('refresh')

        $('form.apply-form-step-3').submit((e) => {
            $(e.target).append("<input type='hidden' name='addressForm[country]' value='" + countrySelect.val() + "'>")
            return true
        })

        $("form[name='oneStepDataForm']").submit((e) => {
            $(e.target).append("<input type='hidden' name='oneStepDataForm[country]' value='" + countrySelect.val() + "'>")
            return true
        })
    })
})(jQuery)
