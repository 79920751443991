import { onCTAButtonClick } from '../../gtm/introduction'
//
// Area - Introduction
//
;(($) => {
    $(() => {
        const module = $('.area-introduction')

        // Only continue if module is on page
        if (!module.length) return

        module.find('.btn').on('click', onCTAButtonClick);
    })
})(jQuery)
