import rowsFactory from '../rowsFactory/rowsFactory'
import VARIANTS_TYPES from '../../config/variants-config'

export default class genericBodyWithSubVariants {
    constructor(props) {
        this.props = props
        this.tbody = document.createElement('tbody')
        this.columnsData = props.data.subColumns
        this.cart = props.cart
    }
    render() {
        let isEven = false
        this.tbody.setAttribute('class', 'v-tbody generic-body-with-subvariants')
        Object.keys(this.props.variant.subVariants).map((key) => {
            const subvariant = this.props.variant.subVariants[key]
            const row = new rowsFactory(VARIANTS_TYPES.GENERIC_SUBVARIANTS, { ...this.props, isEven, subvariant, cart: this.cart }).render()
            const moreRow = new rowsFactory(VARIANTS_TYPES.MORE_DETAILS, { ...this.props, variantsData: subvariant, columnsData: this.columnsData }).render()
            this.tbody.appendChild(row)
            this.tbody.appendChild(moreRow)
            isEven = !isEven
        })

        return this.tbody
    }
}
