import cellsFactory from '../cellsFactory/cellsFactory'
import checkCadForRow from '../partials/cadController'
import { changeColumnsOrder, isMeasurementKey, maxColumnsDesktop, setColumnsClasses } from '../partials/columnsController'
import { getDiscountProductPrice, getFreshPrice } from '../../../../api/variants'
import VARIANTS_TYPES from '../../config/variants-config'

export default class SwrRow {
    constructor(props) {
        this.props = props
        this.isBatchPrice = !!props.variantsData.BatchPrice
        this.piecePrice = props.variantsData.Price || null
        this.batchPrice = props.variantsData.BatchPrice || null
        this.observer = props.observer
        this.options = props.data.options
        this.productName = props.data.productName
        this.row = document.createElement('tr')
        this.priceRefreshed = false
        this.updatePrice = this.options.isCustomerUniquePriceActive
        this.activeRow = null
        this.filterAttributes = props.data.columns.filter((item) => item.dropdown === true).map((item) => item.key)
        this.userFilterSelected = this.filterAttributes.map((attribute) => ({ [attribute]: false }))
        this.matchingItems = props.variantsData.subVariants
        this.dropdownsData = {}
    }

    publishActivateCart() {
        this.observer.publish('activateCart', {
            id: this.props.variantsData.id,
            price: this.isBatchPrice ? this.batchPrice : this.piecePrice,
            currencyCode: this.props.variantsData.currencyCode,
        })
    }

    getDropdownsData() {
        const selectedValues = {}

        this.filterAttributes.forEach((item) => {
            const selectElement = this.row.querySelector(`select[data-key="${item}"]`)
            if (selectElement) {
                selectedValues[item] = selectElement.value
            }
        })

        return selectedValues
    }

    filterObjects(array, criteria) {
        return array.filter((item) => {
            return Object.keys(criteria).every((key) => item[key] === criteria[key])
        })
    }

    searchWithReducedCriteria(array, criteria) {
        let result = this.filterObjects(array, criteria)

        while (result.length === 0 && Object.keys(criteria).length > 0) {
            const keys = Object.keys(criteria)
            delete criteria[keys[keys.length - 1]]

            result = this.filterObjects(array, criteria)
        }

        return result
    }

    updateRow() {
        this.dropdownsData = this.getDropdownsData()
        const matchingItems = this.searchWithReducedCriteria(this.props.variantsData.subVariants, { ...this.dropdownsData })
        this.matchingItems = matchingItems
        this.observer.publish('updateMoreDetailsSwrRow', { id: this.matchingItems[0].id, variant: matchingItems[0] })
        this.render()
    }

    generateDropdownValues(data, key) {
        const groupedValues = [...new Set(data.map((item) => item[key]))]
        const wrapperElement = document.createElement('div')
        wrapperElement.classList.add('css-select')
        const selectElement = document.createElement('select')
        selectElement.setAttribute('data-key', key)

        selectElement.addEventListener('change', (e) => {
            this.updateRow(e)
        })

        groupedValues.forEach((value) => {
            const optionElement = document.createElement('option')
            optionElement.value = value
            optionElement.textContent = value
            if (this.dropdownsData[key] === value) {
                optionElement.selected = true
            }
            selectElement.appendChild(optionElement)
        })

        if (groupedValues.length === 1) {
            selectElement.disabled = true
            wrapperElement.classList.add('css-select-disabled')
        } else {
            selectElement.disabled = false
            wrapperElement.classList.remove('css-select-disabled')
        }

        wrapperElement.appendChild(selectElement)
        return wrapperElement
    }

    createCell(column) {
        const cellValue = column.dropdown ? this.generateDropdownValues(this.props.variantsData.subVariants, column.key) : this.matchingItems[0][column.key]
        let cell

        switch (column.key) {
            case 'Stock':
                cell = new cellsFactory(VARIANTS_TYPES.STOCK, {
                    key: column.key,
                    variant: this.matchingItems[0],
                    stockDisplay: this.options.stockDisplay,
                }).render()
                break
            case 'Price':
                cell = new cellsFactory(VARIANTS_TYPES.PRICE, {
                    key: column.key,
                    variant: this.matchingItems[0],
                    priceType: 'piece',
                    observer: this.observer,
                    updatePrice: this.updatePrice,
                }).render()
                break
            case 'BatchPrice':
                cell = new cellsFactory(VARIANTS_TYPES.PRICE, {
                    key: column.key,
                    variant: this.matchingItems[0],
                    priceType: 'batch',
                    observer: this.observer,
                    updatePrice: this.updatePrice,
                }).render()
                break
            default:
                if (!isMeasurementKey(column.key)) {
                    cell = new cellsFactory(typeof cellValue === 'string' ? VARIANTS_TYPES.DATA : VARIANTS_TYPES.SELECT, {
                        value: cellValue,
                        key: column.key,
                    }).render()
                }
        }

        return cell
    }

    render() {
        this.row.innerHTML = ''
        this.row.className = `v-tr generic-variant-row ${this.props.isEven ? 'even' : 'odd'}`
        const variantId = this.matchingItems[0].id
        const partCode = this.matchingItems[0].PartCode

        if (variantId) {
            this.row.setAttribute('id', variantId)
        }

        let columnsToRender = this.props.data.columns.map((column) => this.createCell(column)).filter((cell) => cell)

        if (this.props.data.options.isBlueprint) {
            columnsToRender.push(
                new cellsFactory(VARIANTS_TYPES.BLUEPRINT, {
                    variant: this.matchingItems[0],
                    columns: this.props.data.columns,
                    productName: this.productName,
                }).render(),
            )
        }

        if (this.props.data.options.isCadDownloadActive) {
            columnsToRender.push(
                new cellsFactory(VARIANTS_TYPES.CAD, {
                    variant: this.matchingItems[0],
                    columns: this.props.data.columns,
                    observer: this.observer,
                }).render(),
            )
        }

        changeColumnsOrder(columnsToRender).forEach((item) => {
            this.row.appendChild(item)
        })

        const cadRowObserver = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        checkCadForRow(this.row, this.observer)
                        observer.unobserve(this.row)
                    }
                })
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            },
        )

        if (this.props.data.options.isCadDownloadActive) cadRowObserver.observe(this.row)

        setColumnsClasses(this.row)

        this.appendSpecialCells()

        if (!this.priceRefreshed && this.updatePrice) {
            this.refreshPrice(variantId, partCode)
        }

        this.subscribeToPriceUpdates()

        return this.row
    }

    appendSpecialCells() {
        const configureCell = new cellsFactory(VARIANTS_TYPES.CONFIGURE, {
            ...this.props,
            parent: this.row,
            exclude: true,
            selectedVariant: this.matchingItems,
        }).render()
        this.row.appendChild(configureCell)
        const isMoreRowOpened = this.row.nextElementSibling?.classList.contains('open')
        const moreCell = new cellsFactory(VARIANTS_TYPES.MORE, { ...this.props, parent: this.row, exclude: true, open: isMoreRowOpened }).render()
        if (this.row.childNodes.length > maxColumnsDesktop) {
            moreCell.classList.remove('hide-on-desktop')
        }

        this.row.appendChild(moreCell)
    }

    refreshPrice(variantId, partCode) {
        this.observer.publish('deactivateCart')
        if (this.options.isCustomerUniquePriceActive && !this.options.erpPrice) {
            getDiscountProductPrice(variantId)
                .then((res) => {
                    const freshPriceData = res.data
                    this.observer.publish('updatePrice', { variantId, freshPriceData, success: true, options: this.options })
                })
                .catch((err) => {
                    this.observer.publish('updatePrice', { variantId, success: false })
                    console.error(err)
                })
                .finally(() => {
                    this.priceRefreshed = true
                })
        } else if (this.options.isCustomerUniquePriceActive && this.options.erpPrice) {
            getFreshPrice(partCode)
                .then((res) => {
                    const freshPriceData = res.data
                    this.observer.publish('updatePrice', { variantId, freshPriceData, success: true, options: this.options })
                })
                .catch((err) => {
                    this.observer.publish('updatePrice', { variantId, success: false })
                    console.error(err)
                })
                .finally(() => {
                    this.priceRefreshed = true
                })
        }
    }

    subscribeToPriceUpdates() {
        this.observer.subscribe({
            event: 'updatePrice',
            action: (data) => {
                const { variantId, freshPriceData, success } = data
                if (variantId === this.props.variantsData.id) {
                    if (success) {
                        this.piecePrice = freshPriceData.Price
                        this.row.setAttribute('data-fresh-price', freshPriceData.Price)
                    }
                    if (this.activeRow && this.activeRow.id === variantId && this.activeRow.getAttribute('data-fresh-price') !== null) {
                        this.piecePrice = this.activeRow.getAttribute('data-fresh-price')
                        this.publishActivateCart()
                    }
                }
            },
        })
    }
}
