export default class arrowCell {
    constructor(props) {
        this.cell = document.createElement('td')
        this.props = props
    }
    render() {
        this.cell.className = 'v-td arrow-cell'
        const element = document.createElement('i')
        element.setAttribute('class', 'icon-arrow-solid')
        this.cell.appendChild(element)
        return this.cell
    }
}
