import cellsFactory from '../cellsFactory/cellsFactory'
import checkCadForRow from '../partials/cadController'
import { maxColumnsDesktop, setColumnsClasses, isMeasurementKey, changeColumnsOrder } from '../partials/columnsController'
import { getFreshPrice, getDiscountProductPrice } from '../../../../api/variants'
import VARIANTS_TYPES from '../../config/variants-config'

export default class GenericVariantsRow {
    constructor(props) {
        this.props = props
        this.isBatchPrice = !!props.variantsData.BatchPrice
        this.piecePrice = props.variantsData.Price || null
        this.batchPrice = props.variantsData.BatchPrice || null
        this.observer = props.observer
        this.options = props.data.options
        this.productName = props.data.productName
        this.cart = props.cart
        this.row = document.createElement('tr')
        this.priceRefreshed = false
        this.updatePrice = this.options.isCustomerUniquePriceActive
        this.activeRow = null
    }

    publishActivateCart() {
        this.observer.publish('activateCart', {
            id: this.props.variantsData.id,
            price: this.isBatchPrice ? this.batchPrice : this.piecePrice,
            currencyCode: this.props.variantsData.currencyCode,
        })
    }

    createCell(column) {
        const cellValue = this.props.variantsData[column.key]
        let cell

        switch (column.key) {
            case 'Stock':
                cell = new cellsFactory(VARIANTS_TYPES.STOCK, {
                    key: column.key,
                    variant: this.props.variantsData,
                    stockDisplay: this.options.stockDisplay,
                }).render()
                break
            case 'Price':
                cell = new cellsFactory(VARIANTS_TYPES.PRICE, {
                    key: column.key,
                    variant: this.props.variantsData,
                    priceType: 'piece',
                    observer: this.observer,
                    updatePrice: this.updatePrice,
                }).render()
                break
            case 'BatchPrice':
                cell = new cellsFactory(VARIANTS_TYPES.PRICE, {
                    key: column.key,
                    variant: this.props.variantsData,
                    priceType: 'batch',
                    observer: this.observer,
                    updatePrice: this.updatePrice,
                }).render()
                break
            default:
                if (!isMeasurementKey(column.key)) {
                    cell = new cellsFactory(VARIANTS_TYPES.DATA, { value: cellValue, key: column.key }).render()
                }
        }

        return cell
    }

    render() {
        this.row.className = `v-tr generic-variant-row ${this.props.isEven ? 'even' : 'odd'}`
        const variantId = this.props.variantsData.id
        const partCode = this.props.variantsData.PartCode

        if (variantId) {
            this.row.setAttribute('id', variantId)
        }

        let columnsToRender = this.props.data.columns.map((column) => this.createCell(column)).filter((cell) => cell)

        if (this.props.data.options.isBlueprint) {
            columnsToRender.push(
                new cellsFactory(VARIANTS_TYPES.BLUEPRINT, {
                    variant: this.props.variantsData,
                    columns: this.props.data.columns,
                    productName: this.productName,
                }).render(),
            )
        }

        if (this.props.data.options.isCadDownloadActive) {
            columnsToRender.push(
                new cellsFactory(VARIANTS_TYPES.CAD, {
                    variant: this.props.variantsData,
                    columns: this.props.data.columns,
                    observer: this.observer,
                }).render(),
            )
        }

        changeColumnsOrder(columnsToRender).forEach((item) => {
            this.row.appendChild(item)
        })

        const cadRowObserver = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        checkCadForRow(this.row, this.observer)
                        observer.unobserve(this.row)
                    }
                })
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1,
            },
        )

        if (this.props.data.options.isCadDownloadActive) cadRowObserver.observe(this.row)

        setColumnsClasses(this.row)

        this.appendSpecialCells()

        if (!this.priceRefreshed && this.updatePrice) {
            this.refreshPrice(variantId, partCode)
        }

        this.subscribeToPriceUpdates()

        return this.row
    }

    appendSpecialCells() {
        const fastBuyCell = new cellsFactory(VARIANTS_TYPES.FAST_BUY, { ...this.props, parent: this.row, exclude: true }).render()
        this.row.appendChild(fastBuyCell)

        const moreCell = new cellsFactory(VARIANTS_TYPES.MORE, { ...this.props, parent: this.row, exclude: true }).render()
        if (this.row.childNodes.length > maxColumnsDesktop) {
            moreCell.classList.remove('hide-on-desktop')
        }
        this.row.appendChild(moreCell)
    }

    refreshPrice(variantId, partCode) {
        this.observer.publish('deactivateCart')
        if (this.options.isCustomerUniquePriceActive && !this.options.erpPrice) {
            getDiscountProductPrice(variantId)
                .then((res) => {
                    const freshPriceData = res.data
                    this.observer.publish('updatePrice', { variantId, freshPriceData, success: true, options: this.options })
                })
                .catch((err) => {
                    this.observer.publish('updatePrice', { variantId, success: false })
                    console.error(err)
                })
                .finally(() => {
                    this.priceRefreshed = true
                })
        } else if (this.options.isCustomerUniquePriceActive && this.options.erpPrice) {
            getFreshPrice(partCode)
                .then((res) => {
                    const freshPriceData = res.data
                    this.observer.publish('updatePrice', { variantId, freshPriceData, success: true, options: this.options })
                })
                .catch((err) => {
                    this.observer.publish('updatePrice', { variantId, success: false })
                    console.error(err)
                })
                .finally(() => {
                    this.priceRefreshed = true
                })
        }
    }

    subscribeToPriceUpdates() {
        this.observer.subscribe({
            event: 'updatePrice',
            action: (data) => {
                const { variantId, freshPriceData, success } = data
                if (variantId === this.props.variantsData.id) {
                    if (success) {
                        this.piecePrice = freshPriceData.Price
                        this.row.setAttribute('data-fresh-price', freshPriceData.Price)
                    }
                    if (this.activeRow && this.activeRow.id === variantId && this.activeRow.getAttribute('data-fresh-price') !== null) {
                        this.piecePrice = this.activeRow.getAttribute('data-fresh-price')
                        this.publishActivateCart()
                    }
                }
            },
        })
    }
}
