const params = new URLSearchParams(window.location.hash.replace('#', '?'))

const refreshHistory = (params) => {
    window.history.replaceState({}, '', `${location.pathname}#${params.toString()}`)
}

const updateUrlParam = (key, value) => {
    params.set(key, value)
    refreshHistory(params)
}

const deleteUrlParamValue = (key, value) => {
    const valuesFromUrl = params.getAll(key)
    const valuesArray = valuesFromUrl[0].split(',')
    const updatedValuesArray = valuesArray.filter((item) => {
        return item != value
    })
    if (updatedValuesArray.length > 0) {
        params.set(key, [updatedValuesArray.join(',')])
        refreshHistory(params)
    } else {
        deleteUrlParam(key)
    }
}

const deleteUrlParam = (key) => {
    params.delete(key)
    refreshHistory(params)
}

const deleteAllUrlParams = () => {
    refreshHistory(new URLSearchParams())
}

const isUrlHasParams = () => {
    const urlParams = getUrlParams()
    return urlParams && Object.keys(urlParams).length > 0 && urlParams.constructor === Object ? true : false
}

const getUrlParams = () => {
    let vars = {}
    if (location.hash !== '') {
        location.hash
            .substr(1)
            .split('&')
            .map(function (pair) {
                let item = pair.split('=', 2)
                try {
                    const key = item[0]
                    const value = item[1].replace(/\+/g, ' ')
                    vars[decodeURIComponent(key)] = decodeURIComponent(value)
                } catch (e) {
                    console.log('Invalid URL parameter')
                }
            })
    }

    return vars
}

const getUrlParam = (key) => {
    return params.get(key)
}

export { isUrlHasParams, getUrlParams, getUrlParam, updateUrlParam, deleteUrlParam, deleteAllUrlParams, deleteUrlParamValue }
