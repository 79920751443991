import Util from '../utils/utils'
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

//
// Related products
//
;(($) => {
    $(() => {
        let module = $('.related-products')

        // Only continue if on product view page
        if (!module.length) return

        const minElements = 5
        const countGalleryElements = module.find('.swiper-slide').length
        const relatedProductsSelector = '.swiper-related-products'

        const initCarousel = () => {
            new Swiper(relatedProductsSelector, {
                modules: [Navigation],
                slidesPerView: 2,
                spaceBetween: 16,
                grabCursor: true,
                breakpoints: {
                    768: {
                        slidesPerView: 4,
                    },
                    992: {
                        slidesPerView: 5,
                    },
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            })
        }

        if (Util.viewport() === 'md' || Util.viewport() === 'lg' || Util.viewport() === 'xl') {
            if (countGalleryElements > minElements) {
                module.find('.swiper-button-next, .swiper-button-prev').removeClass('d-none')
                initCarousel()
            }
        }
    })
})(jQuery)
