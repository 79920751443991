import getSibling from '../../../../utils/getSibling'
import checkCadForRow from '../partials/cadController'
export default class moreCell {
    constructor(props) {
        this.cell = document.createElement('td')
        this.props = props
        this.parent = props.parent
        this.observer = props.observer
        this.open = props.open || false
    }

    handleClick(e) {
        e.stopPropagation()
        const iconArrow = this.cell.querySelector('.icon-arrow')
        iconArrow.classList.toggle('up')
        const childRowToActivate = getSibling.next(this.parent, '.more-details-row')
        childRowToActivate.classList.toggle('open')

        const parentRow = e.currentTarget.parentElement.parentElement
        const moreDetailsRow = getSibling.next(parentRow, '.more-details-row')
        if (this.props.data.options.isCadDownloadActive) {
            const isCadChecked = moreDetailsRow.classList.contains('cad-checked')
            if (!isCadChecked) {
                checkCadForRow(moreDetailsRow, this.observer)
                moreDetailsRow.classList.add('cad-checked')
            }
        }
    }

    render() {
        this.cell.className = 'v-td more-cell hide-on-desktop'
        const element = document.createElement('div')
        element.setAttribute('class', 'more-details-button')
        element.addEventListener('click', (e) => this.handleClick(e))
        const icon = document.createElement('i')
        icon.setAttribute('class', `icon-arrow${this.open ? ' up' : ''}`)
        element.appendChild(icon)
        this.cell.appendChild(element)
        return this.cell
    }
}
