import * as TracePartsApi from '../../../../api/trace-parts'

const checkCadForRow = (row, observer) => {
    const icon = row.querySelector('.icon-cad')
    const loader = row.querySelector('.cad-loader')
    if (row.id) {
        const manufacturePartCode = row.querySelector('.cad-cell').getAttribute('data-manufacture')
        TracePartsApi.checkCadAvailability({ id: row.id }).then((resp) => {
            let isCADAvailable = resp.data.status
            icon.classList.remove('hide')
            loader.classList.add('hide')
            if (isCADAvailable) {
                icon.classList.add('available')
                observer.publish('enableCadColumn', manufacturePartCode)
            } else {
                icon.classList.add('unavailable')
            }
        })
    } else {
        icon.classList.remove('hide')
        loader.classList.add('hide')
        icon.classList.add('unavailable')
    }
    return row
}

export default checkCadForRow
