import OrderItemModuleLoader from '../common/orderItemModuleLoader'
import ItemDetailsTablet from '../common/item-details-tablet'
import CollapsibleBlock from '../common/collapsible-block'
import ReorderButton from '../common/reorder-button'

let OrderItem
let orderRow

if (window.Vue) {
    OrderItem = window.Vue.component('order-item', OrderItemModuleLoader)
} else {
    OrderItem = null
}

if (window.Vue) {
    orderRow = window.Vue.component('order-row', {
        components: {
            OrderItem,
            ItemDetailsTablet,
            CollapsibleBlock,
            ReorderButton,
        },
        props: {
            orderData: Object,
            translations: Object,
            isCustomCr: Boolean,
        },
        data() {
            return {
                isExpanded: false,
            }
        },
        methods: {
            toggleExpand() {
                this.isExpanded = !this.isExpanded
            },
            emitReorderModal(orderId) {
                this.$emit('reorder-comment-modal', orderId)
            },
        },
        template: `
            <div class="order-row row d-flex flex-column flex-md-row flex-md-wrap pt-4 py-md-2 mx-md-0 align-items-center" :class="{ 'collapsed': isExpanded }">
                <div class="col-md-2 text-md-center mb-2 mb-md-0 text-primary order-num-col">
                    <div class="py-2 py-md-0">
                        <span class="mobile-label d-md-none w-50">{{ translations["orders_history.heading.order_nr_title"] }}</span>
                        {{ orderData.orderNumberErp }}
                    </div>
                </div>
                <div class="col-md-2 col-xxl-1 text-md-center no-wrap py-2 py-md-0">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.heading.date_title"] }}</span>
                    {{ orderData.date }}
                </div>
                <div class="col-md-2 text-md-center py-2 py-md-0">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.order_status_title"] }}</span>
                    {{ orderData.status }}
                </div>
                <!-- <div class="col-md-1 d-md-none d-xxl-block text-md-center py-2 py-md-0">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.customer_reference_title"] }}</span>
                    {{ orderData.customerReference }}
                </div> -->
                <div class="col-md-2 d-md-none d-xxl-block text-md-center py-2 py-md-0">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.heading.customer_po_title"] }}</span>
    
                    <span v-if="orderData.customerPoNumber">{{ orderData.customerPoNumber }}</span>
                    <div v-else class="alert alert-info d-inline-block p-1 px-lg-2 m-0">
                        {{ translations["orders_history.customer_po.no_number"] }}
                    </div>
                </div>
    
                <div class="col-md-2 text-md-center py-2 py-md-0">
                    <div class="mobile-label d-md-none w-50">
                        <div>{{ translations["orders_history.heading.order_value_title"] }}</div>
                        <div class="fs-12">{{ translations["orders_history.heading.excl_shipping_vat"] }}</div>
                    </div>
                    
                    <span class="price-value">{{ orderData.totalPrice }}</span>
                </div>
                <div class="col-md-1 text-md-center py-2 px-md-0 details-col">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.heading.details_title"] }}</span>
                    <button
                        type="button"
                        class="chevron-circle position-relative toggle-details"
                        :aria-expanded="isExpanded ? 'true' : 'false'"
                        @click="toggleExpand"
                    >
                        <svg class="icon icon-chevron"><use xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#chevron"></use></svg>
                    </button>
                </div>
    
                <div class="col-md-3 col-xxl-2 text-md-center py-2 py-md-0">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.reorder_title"] }}</span>
                    <reorder-button
                        :translations="translations"
                        :order-id="orderData.orderId"
                        :reorder-possible="orderData.reorderPossible"
                        :reorder-allowed="orderData.reorderAllowed"
                        @reorder-comment-modal="emitReorderModal"
                    />
                </div>
    
                <div v-if="isExpanded" class="order-items-details-container w-100 p-0">
                    <div class="d-none d-md-block d-xxl-none px-md-4 px-lg-6 py-4">
                        <!-- <div>{{ translations["orders_history.customer_reference_title"] }}: {{ orderData.customerReference }}</div> -->
                        <div>{{ translations["orders_history.heading.customer_po_title"] }}: {{ orderData.customerPoNumber || translations["orders_history.customer_po.no_number"] }}</div>
                    </div>
                    <div class="px-md-4 px-lg-6 d-none d-lg-block">
                        <hr class="mb-0 mt-1">
                    </div>
                    <div class="heading-row d-none d-lg-flex align-items-center px-md-4 px-lg-6 py-2">
                        <div class="col-5">{{ translations["orders_history.row.order_details_title"] }}</div>
                        <div class="col-1 text-center">{{ translations["orders_history.row.quantity_title"] }}</div>
                        <div class="text-center" :class="{'col-2': !isCustomCr, 'col-3': isCustomCr}">{{ translations["orders_history.row.net_value_title"] }}</div>
                        <div class="col-2 text-center" v-if="!isCustomCr">{{ translations["orders_history.row.discount_value_title"] }}</div>
                        <div class="text-center" :class="{'col-2': !isCustomCr, 'col-3': isCustomCr}">{{ translations["orders_history.row.subtotal_value_title"] }}</div>
                    </div>
    
                    <div class="order-items-container w-100 d-flex flex-column px-4 px-lg-6">
                        <order-item v-for="item in orderData.items" :translations="translations" :item-data="item" :is-custom-cr="isCustomCr" :key="" />
                    </div>
    
                    <div class="order-checkout-details-container d-flex flex-column flex-md-row flex-wrap p-4 px-md-6 py-md-6 fs-14">
                        <div class="d-none d-md-block d-lg-none col-12 mb-8 details-table-tablet">
                            <div class="row py-2">
                                <div class="col">{{ translations["orders_history.row.quantity_title"] }}</div>
                                <div class="col">{{ translations["orders_history.row.net_value_title"] }}</div>
                                <div class="col">{{ translations["orders_history.row.discount_value_title"] }}</div>
                                <div class="col">{{ translations["orders_history.row.subtotal_value_title"] }}</div>
                            </div>
                            <item-details-tablet v-for="item in orderData.items"
                                :translations="translations"
                                :quantity="item.quantity"
                                :net-price="item.netPrice"
                                :discount="item.discount"
                                :gross-price="item.grossPrice"
                            />
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <collapsible-block
                                v-if="orderData.invoice && orderData.invoice.data"
                                :heading-text="translations['orders_history.row.invoice_details']"
                            >
                                <p v-for="row in orderData.invoice.data">{{ row }}</p>
                            </collapsible-block>

                            <collapsible-block
                                v-if="orderData.comment"
                                :heading-text="translations['orders_history.row.comment']"
                            >
                                <p>{{ orderData.comment }}</p>
                            </collapsible-block>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <collapsible-block
                                v-if="orderData.delivery && orderData.delivery.data"
                                :heading-text="translations['orders_history.delivery_address']"
                            >
                                <p v-for="row in orderData.delivery.data">{{ row }}</p>
                            </collapsible-block>
                        </div>
    
                        <div class="col-md-4 col-lg-3">
                            <collapsible-block
                                v-if="orderData.documents"
                                :heading-text="translations['orders_history.documents']"
                            >
                                <div v-for="doc in orderData.documents" class="d-flex align-items-center text-primary pb-2">
                                    <a :href="doc.url" target="_blank" class="text-primary">{{ doc.label }}</a>
                                    <svg class="icon icon-next ml-1"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#next"></use></svg>
                                </div>
                            </collapsible-block>
                        </div>
                        
                        <div class="col-md-4 col-lg-3">
                            <collapsible-block
                                v-if="orderData.paymentStatus && !isCustomCr"
                                :heading-text="translations['orders_history.row.payment_details']"
                            >
                                <p>{{ orderData.paymentStatus }}</p>
                            </collapsible-block>

                            <collapsible-block
                                v-if="orderData.orderDetails.deliveryMethod"
                                :heading-text="translations['orders_history.row.delivery_method_details']"
                            >
                                <p>{{ orderData.orderDetails.deliveryMethod }}</p>
                            </collapsible-block>

                            <collapsible-block
                                v-if="orderData.customerReference"
                                :heading-text="translations['orders_history.row.customer_reference']"
                            >
                                <p>{{ orderData.customerReference }}</p>
                            </collapsible-block>
                        </div>
                    </div>
                </div>
            </div>
        `,
    })
} else {
    orderRow = null
}

export default orderRow
