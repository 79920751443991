const placeholderImage = $('.es-form__results-group--articles').data('placeholderImage')

const getHTML = (data) => {
    const header = generateHeader(data)
    const content = generateList(data)
    const footer = generateFooter(data)
    return `${header}${content}${footer}`
}

const generateHeader = (data) => {
    return `<h3 class="es-form__results-header">${window.globals.trans('search.articles_heading')} (${data.total})</h3>`
}

const generateList = (data) => {
    const listItems = data.entries
        .map((item) => {
            const imageElement =
                item.image != ''
                    ? `<figure class="es-form__results-block-photo--figure" style="background-image: url('${item.image}')"></figure>`
                    : `<img class="es-form__results-block-photo--img" src="${placeholderImage}" />`
            return `<li>
                <a href="${item.url}">
                    <div class="es-form__results-block-photo ${item.image == '' ? 'use-placeholder' : ''}">${imageElement}</div>
                    <div class="es-form__results-block-title">${item.name}</div>
                </a>
            </li>`
        })
        .join('')

    return `<ul class="es-form__results-list es-form__results-list--row es-form__results-list--article">${listItems}</ul>`
}

const generateFooter = (data) => {
    return `<a href="${data.url}&tab=articles" data-tab="articles" class="es-form__results-show-all">${window.globals.trans('search.show_all_content')} ></a>`
}

export default { getHTML }
