let CyesaOrderHeadingRowConfig = {
    props: {
        translations: Object,
    },
    template: `
        <div class="align-items-center heading-row d-none d-md-flex py-2">
            <div class="col-md-3 col-xxl-3 text-center">{{ translations["orders_history.heading.order_nr_title"] }}</div>
            <div class="col-md-3 col-xxl-2 text-center">{{ translations["orders_history.heading.date_title"] }}</div>
            <div class="d-none d-xxl-block col-xxl-2 text-center">{{ translations["orders_history.heading.customer_po_title"] }}</div>
            <div class="col-md-3 col-xxl-3 text-center">{{ translations["orders_history.heading.order_nr_erp"] }}</div>
            <div class="col-md-3 col-xxl-2 text-center">{{ translations["orders_history.heading.details_title"] }}</div>
        </div>
    `,
}

export default CyesaOrderHeadingRowConfig
