import getDataAttributeValue from '../../../../attributes/attributes'

const nodeName = '.new-product-view #products_table'
const CLASS_CELL = 'v-td price-cell count'
const CLASS_PRICE_UPDATED = 'price-updated'
const CLASS_PRICE_UPDATED_WITH_ERROR = 'price-updated-with-error'
const CLASS_HIDE = 'hide'
const CLASS_PRICE_LOADER = 'price-loader'
const CLASS_PRICE_TEXT = 'price-text'
const CLASS_FRESH_PRICE_TEXT = 'fresh-price-text'
const CLASS_LINE_THROUGH = 'line-through'
const CLASS_ASK = 'ask'

export default class priceCell {
    constructor(props) {
        const { observer, priceType, variant, updatePrice } = props
        this.props = props
        this.observer = observer
        this.cell = document.createElement('td')
        this.isBatchPrice = priceType === 'batch'
        this.priceFormatted = this.isBatchPrice ? variant.BatchPriceFormatted : variant.PriceFormatted
        this.price = variant.Price
        this.stock = variant.Stock || 0
        this.url = getDataAttributeValue(nodeName, 'data-contact-url')
        this.updatePrice = updatePrice
    }

    render() {
        this.cell.className = CLASS_CELL
        this.cell.setAttribute('data-key', this.isBatchPrice ? 'BatchPrice' : 'Price')

        const element = this.createPriceElement()
        const loader = this.createLoaderElement()

        this.cell.appendChild(loader)
        this.cell.appendChild(element)

        this.observer.subscribe({
            event: 'updatePrice',
            action: (data) => this.handlePriceUpdate(data, element, loader),
        })

        return this.cell
    }

    createPriceElement() {
        const element = document.createElement('span')
        if (typeof this.priceFormatted !== 'undefined') {
            element.innerHTML = this.priceFormatted
        } else {
            const link = document.createElement('a')
            link.href = this.url
            link.className = CLASS_ASK
            link.target = '_blank'
            link.innerText = `${window.globals.trans('filter.ask')}`
            element.appendChild(link)
        }
        element.className = `${CLASS_PRICE_TEXT} ${this.updatePrice ? CLASS_HIDE : ''}`
        return element
    }

    createLoaderElement() {
        const loader = document.createElement('span')
        loader.className = `${CLASS_PRICE_LOADER} ${!this.updatePrice ? CLASS_HIDE : ''}`
        return loader
    }

    handlePriceUpdate(data, element, loader) {
        const { variantId, freshPriceData, success, options } = data // Default value for options
        if (variantId === this.props.variant.id) {
            if (success) {
                this.updateElementOnSuccess(element, freshPriceData, options)
            } else {
                this.updateElementOnError(element)
            }

            element.classList.remove(CLASS_HIDE)
            loader.classList.add(CLASS_HIDE)
        }
    }

    updateElementOnSuccess(element, freshPriceData, options) {
        const priceEqual = parseFloat(this.price) === parseFloat(freshPriceData.Price)
        const { isCustomerUniquePriceActive = false, displayDualPrices = false } = options
        const siteName = window.globals.setting('siteName')

        if (
            (freshPriceData.Price && (siteName === 'haklift' || siteName === 'haklift-com') && !priceEqual) ||
            (isCustomerUniquePriceActive && displayDualPrices && !priceEqual)
        ) {
            if (freshPriceData.PriceFormatted !== undefined && freshPriceData.PriceFormatted !== '-') {
                element.style.opacity = '0.5'
                element.classList.add(CLASS_LINE_THROUGH)

                const freshPriceElement = document.createElement('span')
                freshPriceElement.className = CLASS_FRESH_PRICE_TEXT
                freshPriceElement.innerHTML = freshPriceData.PriceFormatted

                this.cell.appendChild(freshPriceElement)
            }
        } else {
            element.innerHTML = freshPriceData.PriceFormatted || this.priceFormatted
        }

        this.cell.classList.add(CLASS_PRICE_UPDATED)
    }

    updateElementOnError(element) {
        element.style.opacity = '0.5'
        this.cell.classList.add(CLASS_PRICE_UPDATED_WITH_ERROR)
    }
}
