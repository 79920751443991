const onCTAButtonClick = (e) => {
    window.dataLayer = window.dataLayer || []
    const eventAction = e.currentTarget.href.toLowerCase()
    const eventLabel = e.currentTarget.innerText.toLowerCase()

    window.dataLayer.push({
        event: 'GA Tracking',
        eventCategory: 'cta',
        eventAction: eventAction,
        eventLabel: eventLabel,
        eventValue: 0,
        eventNonInteraction: false,
    })
}

export { onCTAButtonClick }
