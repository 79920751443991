import VARIANTS_TYPES from '../../config/variants-config'

const selectRow = {
    first: (type, tbody) => {
        const firstRow = tbody.querySelector('tr')
        firstRow.click()
        if (type === VARIANTS_TYPES.SUBVARIANTS) {
            const childRow = tbody.querySelector('tr.open')
            const childTBody = childRow.querySelector('tbody')
            const secondRow = childTBody.querySelector('tr')
            secondRow.click()
        }
    },
}
export { selectRow }
