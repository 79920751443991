import Util from '../utils/utils'
import multiDownload from '../multi-download/multi-download'
import CartManager from '../cart/cart-manager'
import * as TracePartsApi from '../api/trace-parts'

//
// Product view
//
;(($) => {
    $(() => {
        let module = $('.product-view')

        // Only continue if module is on page
        if (!module.length) return

        // Drawing
        let viewDrawingButton = module.find('#view-drawing-button')
        let drawingImage = module.find('#drawing-image')
        // Product table
        const selectedProductTable = module.find('.selected-item-table')
        const selectedProductTableBody = selectedProductTable.find('tbody')
        const addToCartNode = module.find('.add-to-cart')
        const addToCartForm = module.find('#addToCartForm')
        const pageLang = document.getElementsByTagName('html')[0].getAttribute('lang')

        viewDrawingButton.click(function () {
            drawingImage.collapse('toggle')
        })

        drawingImage.on('show.bs.collapse', function () {
            viewDrawingButton.text(viewDrawingButton.data('hide-label'))
        })

        drawingImage.on('hide.bs.collapse', function () {
            viewDrawingButton.text(viewDrawingButton.data('show-label'))
        })

        const rfqTable = module.find('.rfq-table')
        const isCadColumnActive = rfqTable[0].classList.contains('cad-active')
        const rfqCad = module.find('.rfq-cad')

        async function checkCad(rows) {
            for (const row of rows) {
                const tracePartsInfoEl = $(row).find('.trace-parts-info')
                const tracePartsLoaderEl = $(row).find('.trace-parts-loader')
                const productId = row.dataset.partno
                tracePartsInfoEl.hide()
                tracePartsLoaderEl.show()

                await TracePartsApi.checkCadAvailability({ id: productId }).then((resp) => {
                    let isCADAvailable = resp.data.status
                    tracePartsLoaderEl.hide()
                    tracePartsInfoEl.show()

                    if (isCADAvailable) {
                        if (!rfqCad.hasClass('rfq-cad-show')) {
                            rfqCad.addClass('rfq-cad-show')
                        }
                    } else {
                        tracePartsInfoEl.removeClass('available')
                        tracePartsInfoEl.addClass('unavailable')
                    }
                })
            }
        }

        if (rfqTable.length > 0 && isCadColumnActive) {
            const productRows = module.find('.cad-item')
            if (productRows.length > 0) {
                checkCad(productRows)
            }
        }

        const getSwrVariants = function (diameter) {
            const previewObjectId = Util.getUrlParameter('pimcore_object_preview')
            return $.get('?variants-by-diameter=' + diameter + (previewObjectId ? '&pimcore_object_preview=' + previewObjectId : ''))
        }

        const getGenericVariants = function (attr) {
            const previewObjectId = Util.getUrlParameter('pimcore_object_preview')
            return $.get('?variants-by-attr=' + attr + (previewObjectId ? '&pimcore_object_preview=' + previewObjectId : ''))
        }

        const getSwrVariantDetails = function (id) {
            return $.get(`/${pageLang}/shop/rope/options/${id}`)
        }

        const showSwrVariants = function (row, diameter) {
            // let container = row.data("container");

            // commented out to prevent from caching request data
            // if (container) {
            // 	container.show();
            // 	return;
            // }

            let container = $("<tr class='swr-options'><td colspan='999'><div class='load-indicator'><i></i></div></td></tr>")
            container.insertAfter(row)
            row.data('container', container)

            const td = container.find('td')

            getSwrVariants(diameter).done(function (data) {
                td.html(data)
            })
        }

        const showGenericVariants = function (row, attr) {
            let container = row.data('container')

            if (container) {
                container.show()
                return
            }

            container = $("<tr class='generic-options'><td colspan='999'><div class='load-indicator'><i></i></div></td></tr>")
            container.insertAfter(row)
            row.data('container', container)

            const td = container.find('td')

            getGenericVariants(attr).done(function (data) {
                td.html(data)
            })
        }

        const showSwrVariantConfig = function (row, id) {
            if (!row.next().hasClass('swr-variant-config')) {
                // clear old config forms
                row.siblings('.swr-variant-config').remove()

                // add config form
                $("<tr class='swr-variant-config'><td colspan='999'><div class='load-indicator'><i></i></div></td></tr>").insertAfter(row)
                getSwrVariantDetails(id).done(function (data) {
                    row.next('.swr-variant-config').html(`<td colspan='999' class='p-0'>${data}</td>`)

                    // init selectric
                    row.next().find('.rope-ending').find('select').selectric({
                        forceRenderAbove: true,
                        maxHeight: 240,
                    })

                    refreshSwrTotalPrice()
                })
            }
        }

        const hideVariants = function (option) {
            const container = option.data('container')
            const active = container.find('.active')
            container.hide()
            active.removeClass('active')
            active.find('input').prop('checked', false)
        }

        let activeOption = [null, null] // [0: regular variant option, 1: SWR option]

        const refreshTotalPrice = function (productData, isQuantityChange = false) {
            let selectedEl = rfqTable.find('.active')
            let isGenericTable = rfqTable.find('.generic')
            const DetailsInRow = module.find('.itemDetails')
            const AddToCartInRow = module.find('.itemAddToCart')
            let cartNode = DetailsInRow.length > 0 || AddToCartInRow.length > 0 ? AddToCartInRow : addToCartNode
            const totalPriceNode = cartNode.find('.price-total')
            const quantity = parseInt(cartNode.find('input[name="quantity"]').val(), 10) || 0

            if (productData) {
                if (!quantity) {
                    totalPriceNode.text('0.00')
                } else if (productData.priceValue && productData.stock && productData.stock >= quantity) {
                    let money = quantity * productData.priceValue

                    let number = new Intl.NumberFormat(productData.localeCode, {
                        style: 'currency',
                        currency: productData.currencyCode,
                    }).format(money)

                    totalPriceNode.text(number)

                    cartNode.find('.price-stock-info').hide()
                    cartNode.find('.alert-price-container').show()
                } else if (productData.priceValue && productData.outOfStockLeadTime > 0) {
                    let money = quantity * productData.priceValue

                    let number = new Intl.NumberFormat(productData.localeCode, {
                        style: 'currency',
                        currency: productData.currencyCode,
                    }).format(money)

                    totalPriceNode.text(number)

                    cartNode.find('.price-stock-info').hide()
                    cartNode.find('.alert-price-container').show()
                } else {
                    totalPriceNode.text('0.00')
                    cartNode.find('.alert-price-container').hide()
                    cartNode.find('.price-stock-info').show()
                }
            }

            if (DetailsInRow.length === 0 || AddToCartInRow.length === 0) {
                // Move details from bottom to row
                const selectedDetails = $('#addToCartForm .selected-item-table-wrapper')
                const selectedAddToCart = $('#addToCartForm .add-to-cart.my-4')
                if (selectedDetails.length > 0 || selectedAddToCart.length > 0) {
                    selectedDetails.hide()
                    selectedAddToCart.hide()
                    DetailsInRow.remove()
                    AddToCartInRow.remove()

                    setTimeout(() => {
                        let element = isGenericTable.length ? $('.generic-variant.active') : selectedEl
                        $(`<tr class="itemAddToCart"><td colspan="100%">${selectedAddToCart.html()}</td></tr>`).insertAfter(element).hide().show(200)
                        $(`<tr class="itemDetails"><td colspan="100%">${selectedDetails.html()}</td></tr>`).insertAfter(element).hide().show(200)
                        selectedAddToCart.find('input.quantity').attr('disabled', true)
                    }, 1)
                }
            } else if ((DetailsInRow.length > 0 || AddToCartInRow.length > 0) && !isQuantityChange) {
                // Move details from row to other row
                if (productData.stock > 0) {
                    cartNode.find('.price-stock-info').hide()
                }
                DetailsInRow.remove()
                AddToCartInRow.remove()
                setTimeout(() => {
                    let element = isGenericTable.length ? $('.generic-variant.active') : selectedEl
                    $(`<tr class="itemAddToCart">${AddToCartInRow.html()}</tr>`).insertAfter(element).hide().show(200)
                    $(`<tr class="itemDetails">${DetailsInRow.html()}</tr>`).insertAfter(element).hide().show(200)
                }, 1)
            }
        }

        const getProductDataObject = function (node) {
            if (node && node.length) {
                let data
                if (node.attr('data-productinfo')) {
                    try {
                        data = JSON.parse(node.attr('data-productinfo'))
                    } catch (e) {
                        console.error('Error while parsing data-productinfo attribute - invalid object format')
                        data = null
                    }
                }
                return data
            }
            return null
        }

        rfqTable.on('click', '.generic-options .generic-variant', function () {
            const selectedItemData = getProductDataObject($(this))
            refreshRegItemTable(selectedItemData)
        })

        const refreshRegItemTable = function (selectedItemData) {
            if (selectedItemData) {
                const DetailsInRow = module.find('.itemDetails')
                const AddToCartInRow = module.find('.itemAddToCart')
                let newSelectedProductTableBody = DetailsInRow.length > 0 || AddToCartInRow.length > 0 ? DetailsInRow : selectedProductTableBody
                if (selectedItemData.partCode) {
                    newSelectedProductTableBody.find('.artno-col').text(selectedItemData.partCode)
                } else {
                    newSelectedProductTableBody.find('.artno-col').text('')
                }

                const stockCol = newSelectedProductTableBody.find('.stock-col')
                const packingQtyCol = newSelectedProductTableBody.find('.packingQty-col')
                const packingQtyHeader = newSelectedProductTableBody.parent().find('.packingQty-header')
                const leadTimeCol = newSelectedProductTableBody.find('.lead-time-col')

                if (!stockCol.hasClass('in-out')) {
                    stockCol.find('.value').removeClass('stock-high stock-low alert')
                }

                if (selectedItemData.stock > 0) {
                    stockCol.find('.value').text(selectedItemData.formattedStock)
                    leadTimeCol.find('.value').text(selectedItemData.leadTime)
                    newSelectedProductTableBody.find('.alert').hide()
                    newSelectedProductTableBody.find('.value').show()

                    if (stockCol.hasClass('in-out')) {
                        stockCol.find('.value').addClass('stock-high alert').removeClass('stock-low')
                    }
                } else if (selectedItemData.stock <= 0 && selectedItemData.outOfStockLeadTime > 0 && selectedItemData.priceValue > 0) {
                    stockCol.find('.value').text(selectedItemData.formattedStock)
                    leadTimeCol.find('.value').text(selectedItemData.outOfStockLeadTimeFormatted)
                    leadTimeCol.find('.value').show()
                    leadTimeCol.find('.alert').hide()
                    if (stockCol.hasClass('in-out')) {
                        stockCol.find('.value').addClass('stock-low alert').removeClass('stock-high')
                    } else {
                        stockCol.find('.value').hide()
                        stockCol.find('.alert').show()
                    }
                } else {
                    if (stockCol.hasClass('in-out')) {
                        stockCol.find('.value').addClass('stock-low alert').removeClass('stock-high')
                        stockCol.find('.value').text(selectedItemData.formattedStock)
                        leadTimeCol.find('.value').hide()
                        leadTimeCol.find('.alert').show()
                    } else {
                        newSelectedProductTableBody.find('.value').hide()
                        newSelectedProductTableBody.find('.alert').show()
                    }
                }

                if (selectedItemData.unitDescription) {
                    $('#unit-description').text(selectedItemData.unitDescription)
                } else {
                    $('#unit-description').text('')
                }

                if (selectedItemData.formattedPrice) {
                    newSelectedProductTableBody.find('.price-col').text(selectedItemData.formattedPrice)
                } else {
                    newSelectedProductTableBody.find('.price-col').text('')
                }

                if (selectedItemData.packingQty && selectedItemData.packingQty > 0) {
                    packingQtyHeader.show()
                    packingQtyCol.show()
                    packingQtyCol.text(selectedItemData.packingQty)
                } else {
                    packingQtyHeader.hide()
                    packingQtyCol.hide()
                }

                selectedProductTable.css('display', 'table')
                refreshTotalPrice(selectedItemData)
            } else {
                selectedProductTable.css('display', 'none')
                addToCartNode.find('.alert-price-container').css('display', 'none')
            }
        }

        function hidePopovers() {
            addToCartForm.find('button[type="submit"]').popover('hide')
            productVariantForm.popover('hide')
        }

        function refreshSwrTotalPrice() {
            const swrTotalPrice = module.find('.swr-total-price')
            const swrTotalPriceValue = swrTotalPrice.find('.price-value')
            const productData = getProductDataObject($('#addToCartForm').find('.swr-variant.active'))
            const quantity = parseInt(addToCartForm.find('input[name="quantity"]').val(), 10) || 0
            const ropeLength = parseInt(addToCartForm.find('#rope-length').val(), 10) || 0

            if (productData) {
                if (!quantity || !ropeLength) {
                    swrTotalPriceValue.text('0.00')
                } else if (productData.priceValue && productData.stock && productData.stock >= quantity * ropeLength) {
                    let number = quantity * productData.priceValue * ropeLength

                    let money = new Intl.NumberFormat(productData.localeCode, {
                        style: 'currency',
                        currency: productData.currencyCode,
                    }).format(number)

                    swrTotalPriceValue.text(money)

                    addToCartNode.find('.price-stock-info').hide()
                    swrTotalPrice.show()
                } else {
                    hideAndClearPrice()
                    addToCartNode.find('.price-stock-info').show()
                }
            } else {
                hideAndClearPrice()
            }

            function hideAndClearPrice() {
                swrTotalPrice.hide()
                swrTotalPriceValue.text('0.00')
            }
        }

        rfqTable.on('click', '.rfq-option', function () {
            const row = $(this)
            const isSwr = row.hasClass('swr')
            const isGeneric = row.hasClass('generic')
            const isSwrVariant = row.hasClass('swr-variant')
            const isGenericVariant = row.hasClass('generic-variant')
            const isActiveRow = row.hasClass('active')
            const deactivate = (isSwr || isGeneric) && isActiveRow // SWR options can be deactivated
            const activeOptionType = isSwr | isGeneric | 0 // convert to int: false => 0, true => 1
            const previouslyActive = activeOption[activeOptionType]
            activeOption[activeOptionType] = row
            const addToQuoteNode = module.find('.add-to-quote')

            const radio = row.find('input[type=radio]')
            radio.prop('checked', !deactivate)
            if (row.attr('data-productinfo')) {
                const activeItem = JSON.parse(row.attr('data-productinfo'))
                const previousItem = previouslyActive ? JSON.parse(previouslyActive.attr('data-productinfo')) : ''
                if (activeItem.id === previousItem.id && row.hasClass('active')) {
                    row.removeClass('active')
                } else {
                    if (previouslyActive) previouslyActive.removeClass('active')
                    if (!deactivate) row.addClass('active')
                }
            } else {
                if (previouslyActive) previouslyActive.removeClass('active')
                if (!deactivate) row.addClass('active')
            }

            if (!isSwrVariant) {
                $(this).siblings('tr.swr-options').remove()
            } else {
                const productData = getProductDataObject($('#addToCartForm').find('.swr-variant.active'))
                productData && showSwrVariantConfig($(this), productData.id)
            }

            if (isSwr) {
                if (!deactivate) {
                    const diameter = radio.val()
                    showSwrVariants(row, diameter)
                }
            } else if (isGeneric) {
                if (previouslyActive) hideVariants(previouslyActive)
                if (!deactivate) {
                    const attrParam = radio.val()
                    showGenericVariants(row, attrParam)
                }
            }

            // Trace Parts Download

            const productCad = $('.product-cad')
            const mayContainCadFile = $(this).hasClass('cad-item')
            const isCadAvailable = $(this).find('.available').length > 0 ? true : false

            if (productCad.length && mayContainCadFile && isCadAvailable) {
                const tracePartsContainer = $('.trace-parts-container')
                const tracePartsEmailContainer = productCad.find('.trace-parts-email-form-container')
                const tracePartsEmailForm = tracePartsEmailContainer.find('#trace-parts-email-form')[0]
                const tracePartsEmail = tracePartsEmailContainer.find('#trace_parts_email')
                const tracePartsEmailPrompt = tracePartsEmailContainer.find('.trace-parts-email-prompt')
                const tracePartsEmailTick = tracePartsEmailContainer.find('.trace-parts-email-tick')

                const tracePartsResults = tracePartsContainer.find('.trace-parts-results')
                const tracePartsDownloads = tracePartsContainer.find('.trace-parts-downloads')
                const tracePartsFormatSelect = tracePartsContainer.find('#trace_parts_formats')
                const tracePartsStatusEl = row.find('.trace-parts-status')

                const tracePartsResultsLoaderEl = tracePartsContainer.find('.trace-parts-loading-text')
                const partNo = tracePartsStatusEl.attr('partno')
                tracePartsResults.hide()
                tracePartsDownloads.empty()
                productCad.addClass('product-cad-show')
                tracePartsEmail.closest('form').on('submit', function (e) {
                    e.preventDefault()
                })

                tracePartsEmail.on('input', function () {
                    let email = $(this).val()
                    const re =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    if (email.length > 0 && re.test(String(email).toLowerCase())) {
                        tracePartsFormatSelect.prop('disabled', false).selectric('refresh')
                        tracePartsEmailPrompt.removeClass('show')
                        tracePartsEmailTick.addClass('valid')
                    } else {
                        tracePartsFormatSelect.prop('disabled', true).selectric('refresh')
                        tracePartsEmailPrompt.addClass('show')
                        tracePartsEmailTick.removeClass('valid')
                    }
                    tracePartsEmailForm.reportValidity()
                })

                if (tracePartsEmailContainer.hasClass('show')) {
                    tracePartsContainer.on('click', () => {
                        const isEmailValid = tracePartsEmailForm.reportValidity()
                        if (isEmailValid) {
                            tracePartsEmailPrompt.removeClass('show')
                        } else {
                            tracePartsEmailPrompt.addClass('show')
                        }
                    })
                }

                tracePartsResultsLoaderEl.show()
                TracePartsApi.getFormats({ id: partNo }).then((resp) => {
                    const formats = resp.data.formats
                    tracePartsResultsLoaderEl.hide()
                    tracePartsResults.show()
                    tracePartsFormatSelect.empty()
                    tracePartsFormatSelect.append(`<option  value="" disabled selected>Choose CAD format</option>`)
                    formats.map((format) => {
                        tracePartsFormatSelect.append(`<option value="${format.id}">${format.name}</option>`)
                    })
                    if (tracePartsEmail.val().length === 0) {
                        tracePartsFormatSelect.attr('disabled', true)
                    }
                    tracePartsFormatSelect.selectric({
                        onChange: function (select, selectric) {
                            generateCADButtons(partNo, selectric)
                        },
                    })
                })

                const generateCADButtons = (id, selectric) => {
                    tracePartsResultsLoaderEl.show()
                    tracePartsFormatSelect.attr('disabled', 'disabled').selectric('refresh')
                    tracePartsDownloads.empty()
                    const index = selectric.state.selectedIdx
                    const formatId = selectric.items[index].value
                    const formatName = selectric.items[index].text
                    const email = tracePartsEmail.val()

                    TracePartsApi.getDownloadPaths({ id, formatId, email }).then((resp) => {
                        const paths = resp.data.paths
                        tracePartsResultsLoaderEl.hide()

                        paths.map((path, index) => {
                            const btn = generateDownloadButton(path, formatName, index)
                            tracePartsDownloads.append(btn)
                        })
                        if (paths.length > 1) {
                            const btnDownloadAll = generateDownloadAllButton(paths)
                            tracePartsDownloads.append(btnDownloadAll)
                        }
                        tracePartsFormatSelect.removeAttr('disabled').selectric('refresh')
                    })
                }

                const generateDownloadButton = (path, formatName, index) => {
                    const element = document.createElement('a')
                    const icon = document.createElement('i')
                    icon.className = 'icon-download-cad'
                    element.href = path
                    element.className = 'trace-parts-format'
                    element.text = `${formatName} - ${index + 1}`
                    element.append(icon)
                    return element
                }

                const generateDownloadAllButton = (paths) => {
                    const element = document.createElement('a')
                    const icon = document.createElement('i')
                    icon.className = 'icon-download-cad'
                    element.href = '#'
                    element.className = 'trace-parts-download-all'
                    element.text = `Download all`
                    element.append(icon)
                    element.addEventListener('click', (e) => {
                        e.preventDefault()
                        multiDownload(paths)
                    })
                    return element
                }
            } else {
                productCad.removeClass('product-cad-show')
            }

            hidePopovers()

            if (!(isSwr || isSwrVariant || isGeneric || isGenericVariant)) {
                const selectedItemData = getProductDataObject($(this))
                refreshRegItemTable(selectedItemData)
            } else if ($(this).hasClass('generic')) {
                selectedProductTable.css('display', 'none')
                addToCartNode.find('.alert-price-container').css('display', 'none')
            }

            if (!(isSwr || isGeneric)) {
                const selectedAddToQuote = $('#productVariantForm .add-to-quote.my-4')
                const itemAddToCartNode = $('.itemAddToCart')
                if (addToQuoteNode.length > 0 && selectedAddToQuote.length > 0) {
                    selectedAddToQuote.hide()
                    if (itemAddToCartNode.length > 0) {
                        itemAddToCartNode.remove()
                    }
                    if (!deactivate) {
                        $(`<tr class="itemAddToCart"><td colspan="100%">${selectedAddToQuote.html()}</td></tr>`).insertAfter(row).hide().show(200)
                    }
                }
            }
        })

        // regular item quantity input handler
        $('body').on('input', addToCartForm.find('input[name="quantity"]'), () => {
            if (addToCartForm.hasClass('swr-item')) {
                refreshSwrTotalPrice()
            } else {
                let selectedEl = rfqTable.find('.active')
                if (selectedEl.length) {
                    let genericVariant = $('.generic-variant.active')
                    if (genericVariant.length) {
                        selectedEl = genericVariant
                    }
                    refreshTotalPrice(getProductDataObject(selectedEl), true)
                }
            }
        })

        addToCartForm.on('input', 'input#rope-length', () => {
            refreshSwrTotalPrice()
        })

        rfqTable.on('click', '.swr-form-data, .swr-main-info', () => {
            hidePopovers()
        })

        // swr item delegated quantity input handler
        rfqTable.on('change', "input[name='quantity']", function () {
            hidePopovers()
        })

        rfqTable.on('click', '.swr-add-to-cart-table', function (e) {
            e.stopPropagation()
        })

        // Coreshop - Add to cart
        addToCartForm.submit(function (e) {
            e.preventDefault()
            const addToCartFormButton = addToCartForm.find('button[type="submit"]')
            const variantId = addToCartForm.find('input[name="product"]:checked').val()
            const quantity = addToCartForm.find('input[name="quantity"]').val()
            if ($(this).hasClass('swr-item')) {
                const endingA = addToCartForm.find('select#rope-ending-a').val()
                const endingB = addToCartForm.find('select#rope-ending-b').val()
                const ropeLength = addToCartForm.find('input#rope-length').val()
                const packagingType = addToCartForm.find('.packaging-type').find("input[type='radio']:checked").val()

                if (!variantId || !quantity || !endingA || !endingB || !ropeLength || !packagingType) {
                    if (Util.viewport() !== 'xs') {
                        addToCartFormButton.popover('show')
                    }
                    return false
                }
            } else {
                if (!variantId || !quantity) {
                    rfqTable.addClass('table-warning animated')
                    if (Util.viewport() !== 'xs') {
                        addToCartFormButton.popover('show')
                    }
                    return false
                }
            }
            addToCartFormButton.attr('disabled', true)
            $.ajax({
                type: 'POST',
                url: addToCartForm.attr('action'),
                data: addToCartForm.serialize(),
                success: (response) => {
                    CartManager.updateCoreshopCartCount(response)
                    if (window.dataLayer) {
                        window.dataLayer.push({
                            event: 'addToCart',
                            ecommerce: {
                                currencyCode: response.currencyCode || '',
                                add: {
                                    products: [
                                        {
                                            id: response.id.toString(),
                                            name: response.name,
                                            price: response.price || '0',
                                            category: response.category,
                                            quantity: parseInt(response.qty, 10),
                                        },
                                    ],
                                },
                            },
                        })
                    }
                },
                error: () => {
                    console.error('Error while adding item to the cart')
                },
                complete: () => {
                    addToCartFormButton.attr('disabled', false)
                },
            })
        })

        // RFQ - Add to cart
        let productVariantForm = module.find('#productVariantForm')
        let productVariantFormButton = productVariantForm.find('button')
        productVariantForm.submit(function (event) {
            event.preventDefault()
            let variantId = productVariantForm.find('input[name="variantId"]:checked').val()
            let quantity = productVariantForm.find('input[name="qty"]').val()
            if (!variantId || !quantity) {
                rfqTable.addClass('table-warning animated')
                if (Util.viewport() !== 'xs') {
                    productVariantFormButton.popover('show')
                }
            } else {
                CartManager.addToCart(variantId, quantity)
            }
        })

        $('body').on('hide.bs.popover', addToCartForm.find('button[type="submit"]'), function () {
            rfqTable.removeClass('table-warning animated')
        })

        productVariantFormButton.on('hide.bs.popover', function () {
            rfqTable.removeClass('table-warning animated')
        })

        /* Keep this until we know how to solve empty cell in legacy tables */
        /*
		// Legacy table
		let legacyTable = module.find(".legacy-table");

		// Remove any empty divs for easier alignment
		let emptyCellRegEx = /^&nbsp;$/;

		var emptyCells = $(".legacy-table td div").filter(function(index, ele) {
			if(emptyCellRegEx.test($(ele).html())) {
				return true;
			}
		});

		emptyCells.closest("td").remove();
		*/
    })
})(jQuery)
