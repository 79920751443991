import TimedCallback from '../utils/timed-callback'

/**
 * CartManager
 *
 * Manage cart operation such as add, remove, update cart items.
 */

const CartManager = (($) => {
    // Cart notification
    const cartNotification = $('.cart-notification')
    const coreshopNotification = $('.cart-notification.logged-in')
    const cartNotificationItemLabel = cartNotification.find('.item-label')
    const cartNotificationContinue = cartNotification.find('button')
    const cartCount = $('.site-tools .cart-count')
    const coreshopCartCount = $('.site-tools').find('.coreshop-cart-count')
    const coreshopQuoteText = $('.site-tools .nav-item.quote').find('span')
    const dynamicQuoteLabel = coreshopQuoteText.attr('dynamic-label') === ''
    const restartableTimer = new TimedCallback(cartNotificationDismiss, 5000)

    function cartNotificationDismiss() {
        restartableTimer.stop()
        cartNotification.removeClass('open')
    }

    cartNotificationContinue.click(cartNotificationDismiss)
    cartNotification.mouseover(() => {
        restartableTimer.pause()
    })
    cartNotification.mouseout(() => {
        restartableTimer.resume()
    })

    // Cart manager
    class CartManager {
        static addToCart(variantId, qty) {
            $.ajax({
                url: window.globals.setting('localeRootPath') + '/cart/add',
                data: JSON.stringify({ variantId: variantId, qty: qty }),
                type: 'POST',
                contentType: 'application/json; charset=utf-8',
            }).then(this._addToCartSuccess, this._addToCartFailure)
        }

        static addToCartSWR(data) {
            $.ajax({
                url: window.globals.setting('localeRootPath') + '/cart/add-swr',
                data: JSON.stringify(data),
                type: 'POST',
                contentType: 'application/json; charset=utf-8',
            }).then(this._addToCartSuccess, this._addToCartFailure)
        }

        static updateCoreshopCartCount(notificationObj = null) {
            this.fetchCoreshopCartCount(notificationObj)
        }

        static updateItemQuantity(itemKey, qty) {
            $.ajax({
                url: '/cart/quantity',
                data: JSON.stringify({ itemKey: itemKey, qty: qty }),
                type: 'POST',
                contentType: 'application/json; charset=utf-8',
            }).then(this._updateQuantitySuccess, this._updateQuantityFailure)
        }

        static updateItemCertificate(itemId, certificate) {
            $.ajax({
                url: '/cart/certificate',
                data: JSON.stringify({ itemId: itemId, certificate: certificate }),
                type: 'POST',
                contentType: 'application/json; charset=utf-8',
            })
        }

        static removeFromCart(itemKey, qty) {
            $.ajax({
                url: '/cart/remove',
                data: JSON.stringify({ itemKey: itemKey, qty: qty }),
                type: 'POST',
                contentType: 'application/json; charset=utf-8',
            }).then(this._removeFromCartSuccess, this._removeFromCartFailure)
        }

        static fetchCartCount() {
            $.ajax({
                url: '/cart/count',
                type: 'get',
                contentType: 'application/json; charset=utf-8',
            }).then(this._cartCountSuccess, this._cartCountFailure)
        }

        static fetchCoreshopCartCount(notificationObj) {
            $.ajax({
                url: '/cart/items-total',
                type: 'get',
                contentType: 'application/json; charset=utf-8',
                success: (data) => {
                    this._coreshopCartCountSuccess(data, notificationObj)
                },
                error: () => {
                    this._coreshopCartCountFailure()
                },
            })
        }

        // private
        static _addToCartSuccess(response) {
            CartManager.setCartCount(response.count)
            let itemString = response.partCode
                ? '<strong>' + response.name + '</strong>' + ' (' + response.partCode + ')'
                : '<strong>' + response.name + '</strong>'
            cartNotificationItemLabel.html(itemString)
            cartNotification.addClass('open')

            restartableTimer.start()

            // currency code not available since there is no price at all for quests
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'addToCart',
                    ecommerce: {
                        currencyCode: response.currencyCode || '',
                        add: {
                            products: [
                                {
                                    id: response.id,
                                    name: response.name,
                                    price: response.price || '0',
                                    category: response.category,
                                    quantity: parseInt(response.qty, 10),
                                },
                            ],
                        },
                    },
                })
            }
        }

        static _addToCartFailure() {
            alert('Error')
        }

        static _updateQuantitySuccess(response) {
            CartManager.setCartCount(response.count)
        }

        static _updateQuantityFailure() {
            alert('Error')
        }

        static _removeFromCartSuccess(response) {
            CartManager.setCartCount(response.count)

            if (response.emptyCart) {
                location.reload()
            } else {
                let cartItem = $('.cart-item[data-item-key=' + response.itemkey + ']')
                cartItem.fadeOut(function () {
                    $(this).remove()
                })
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'removeFromCart',
                        ecommerce: {
                            currencyCode: response.currencyCode || '',
                            remove: {
                                products: [
                                    {
                                        id: response.id,
                                        name: response.name,
                                        price: 0,
                                        category: response.category,
                                        quantity: parseInt(response.qty, 10),
                                    },
                                ],
                            },
                        },
                    })
                }
            }
        }

        static _removeFromCartFailure() {
            alert('Error')
        }

        static _cartCountSuccess(response) {
            CartManager.setCartCount(response.count)
        }

        static _coreshopCartCountSuccess(response, notificationObj) {
            CartManager.setCoreshopCartCount(response.total)

            if (notificationObj) {
                CartManager.setTextAndShowNotification(notificationObj)
            }
        }

        static _cartCountFailure() {
            // eslint-disable-next-line no-undef
            log('Failed to fetch cart count')
        }

        static _coreshopCartCountFailure() {
            // eslint-disable-next-line no-undef
            log('Failed to fetch coreshop cart count')
        }

        static setTextAndShowNotification(notificationObj) {
            if (notificationObj.success) {
                coreshopNotification.find('.message').text(notificationObj.success)
            } else {
                coreshopNotification.find('.message').text(notificationObj.error)
            }

            coreshopNotification.addClass('open')
            restartableTimer.start()
        }

        static setCartCount(count) {
            if (count > 0) {
                cartCount.text(count)
                cartCount.addClass('has-items')
                if (dynamicQuoteLabel) {
                    coreshopQuoteText.text(`${window.globals.trans('header.my-quotation')}`)
                }
            } else {
                cartCount.removeClass('has-items')
            }
        }

        static setCoreshopCartCount(count) {
            if (count > 0) {
                coreshopCartCount.text(count)
                coreshopCartCount.addClass('has-items')
            } else {
                coreshopCartCount.removeClass('has-items')
            }
        }
    }

    if (cartCount.length) {
        // If cart is visible i.e. RFQ is active, set cart count.
        CartManager.fetchCartCount()
    }

    if (coreshopCartCount.length) {
        // set cart count on page load
        CartManager.updateCoreshopCartCount()
    }

    return CartManager
})(jQuery)

export default CartManager
