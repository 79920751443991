import generateId from '../../../uuid/uuid'
import ClearButton from './clearButton'
import { addTag, toggleRadioTag } from './filterTags'
export default class RadioGroup {
    constructor(element) {
        this.name = element.name
        this.isCollapsed = element.collapsed
        this.text = element.text
        this.fields = element.fields
        this.id = generateId()
        this.clearButton = new ClearButton(this.id).render()
    }

    render() {
        const fields = this.fields.map((field) => {
            const formGroup = document.createElement('div')
            const formInput = document.createElement('input')
            const formLabel = document.createElement('label')

            formGroup.className = 'form-group'

            formInput.type = 'radio'
            formInput.className = 'radio'
            formInput.name = field.name
            formInput.value = field.value
            formInput.id = field.id
            if (field.disabled) formInput.disabled = 'disabled'
            if (field.checked) formInput.checked = true
            formInput.addEventListener('change', () => {
                toggleRadioTag(this.id)
            })
            if (field.checked) {
                addTag(this.name, `${this.text} - ${field.label}`, this.id, 'radio')
            }

            formLabel.className = 'radio-label'
            formLabel.setAttribute('for', field.id)
            formLabel.innerText = field.label
            formGroup.appendChild(formInput)
            formGroup.appendChild(formLabel)

            return formGroup
        })

        const containerGroup = document.createElement('div')
        const filterGroup = document.createElement('div')
        const title = document.createElement('div')
        const icon = document.createElement('i')
        const radioGroup = document.createElement('div')
        const buttonsContainer = document.createElement('div')

        containerGroup.className = 'form-container'
        filterGroup.id = this.id
        filterGroup.className = 'filter-group filter-radio'
        title.className = 'title'
        title.innerText = this.text
        title.addEventListener('click', () => {
            radioGroup.classList.toggle('collapsed')
            icon.classList.toggle('up')
        })

        icon.className = `icon icon-arrow ${!this.isCollapsed ? 'up' : ''}`
        radioGroup.className = `fields radio-group ${this.isCollapsed ? 'collapsed' : ''}`
        radioGroup.setAttribute('data-name', this.name)

        fields.forEach((element) => {
            containerGroup.appendChild(element)
        })

        if (fields.length > 6) {
            containerGroup.className += ' scrollable'
        }

        buttonsContainer.className = 'display-inline'

        title.appendChild(icon)
        filterGroup.appendChild(title)
        buttonsContainer.appendChild(this.clearButton)
        radioGroup.appendChild(containerGroup)
        radioGroup.appendChild(buttonsContainer)
        filterGroup.appendChild(radioGroup)

        return filterGroup
    }
}
