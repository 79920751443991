const getHTML = (data) => {
    const headerPrimary = generateHeaderPrimary()
    const description = generateDescription()
    const headerSecondary = generateHeaderSecondary()
    const list = generateList(data)
    return `${headerPrimary}${description}${headerSecondary}${list}`
}

const generateHeaderPrimary = () => {
    return `<h3 class="es-form__results-header">${window.globals.trans('search.no_matches_heading')}</h3>`
}

const generateDescription = () => {
    return `<p>${window.globals.trans('search.no_matches_description')}</p>`
}

const generateHeaderSecondary = () => {
    return `<h3 class="es-form__results-header">${window.globals.trans('search.other_categories_heading')}</h3>`
}

const generateList = (data) => {
    const listItems = data
        .map((item) => {
            return `<li>
            <a href="${item.url}">
                <figure class="es-form__results-block-image"><img src="/static/icons/${item.icon}.svg"/></figure>
                <div class="es-form__results-block-title">${item.name}</div>
                <button class="es-form__results-block-button">${window.globals.trans('filter.view_category')}</button>
            </a>
        </li>`
        })
        .join('')

    return `<ul class="es-form__results-list es-form__results-list--row es-form__results-list--category">${listItems}</ul>`
}

export default { getHTML }
