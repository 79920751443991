const getHTML = (data) => {
    const header = generateHeader()
    const list = generateList(data)
    const separator = generateSeparator()
    return `${header}${list}${separator}`
}

const generateSeparator = () => {
    return '<div class="es-form__results-separator"></div>'
}

const generateHeader = () => {
    return `<h3 class="es-form__results-header">${window.globals.trans('search.relevants_heading')}</h3>`
}

const generateList = (data) => {
    const listItems = data
        .map((item) => {
            return `<li class="es-form__results-link">${item}</li>`
        })
        .join('')

    return `<ul class="es-form__results-list es-form__results-list--column es-form__results--revelant">${listItems}</ul>`
}

export default { getHTML }
