import rowsFactory from '../rowsFactory/rowsFactory'
import cellsFactory from '../cellsFactory/cellsFactory'
import { maxColumnsDesktop, setColumnsClasses, changeColumnsOrder } from '../partials/columnsController'
import VARIANTS_TYPES from '../../config/variants-config'

export default class subColumnsHeader {
    constructor(props) {
        this.props = props
        this.isSwr = this.props.data.options.isSwr
        this.isSwrForm = this.props.data.options.swrOptions?.endings?.length > 0
        this.isBlueprint = this.props.data.options.isBlueprint
        this.isCadDownloadActive = this.props.data.options.isCadDownloadActive
        this.isWebshopActive = this.props.data.options.isWebshopActive
        this.thead = document.createElement('thead')
    }

    render() {
        let columnsToRender = []
        this.thead.setAttribute('class', 'v-thead subcolumns-header')
        const row = new rowsFactory(VARIANTS_TYPES.EMPTY).render()

        if (this.isSwrForm) {
            row.appendChild(new cellsFactory(VARIANTS_TYPES.HEADER, { exclude: true }).render())
        }

        this.props.data.subColumns.map((subcolumn) => {
            if (!subcolumn.key.startsWith('Measurement.')) {
                columnsToRender.push(new cellsFactory(VARIANTS_TYPES.HEADER, { label: subcolumn.label, key: subcolumn.key }).render())
            }
        })
        if (this.isBlueprint) {
            columnsToRender.push(
                new cellsFactory(VARIANTS_TYPES.HEADER, { label: `${window.globals.trans('filter.blueprint')}`, key: VARIANTS_TYPES.BLUEPRINT }).render(),
            )
        }
        if (this.isCadDownloadActive && !this.isSwr) {
            columnsToRender.push(
                new cellsFactory(VARIANTS_TYPES.HEADER, { label: `${window.globals.trans('table.heading.CAD')}`, key: VARIANTS_TYPES.CAD }).render(),
            )
        }

        const newColumnsOrder = changeColumnsOrder(columnsToRender)
        newColumnsOrder.forEach((item) => {
            row.appendChild(item)
        })

        setColumnsClasses(row)

        if (!this.isSwrForm) {
            const fastBuyCell = new cellsFactory(VARIANTS_TYPES.HEADER, { label: `${window.globals.trans('filter.fast_buy')}`, exclude: true }).render()
            row.appendChild(fastBuyCell)
        }

        const moreCell = new cellsFactory(VARIANTS_TYPES.HEADER, { label: `${window.globals.trans('filter.more_details')}`, exclude: true }).render()
        if (!(row.childNodes.length > maxColumnsDesktop)) {
            moreCell.classList.add('hide-on-desktop')
        }
        row.appendChild(moreCell)

        this.thead.appendChild(row)
        return this.thead
    }
}
