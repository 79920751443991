import axios from 'axios'
import qs from 'qs'
const devMode = false

axios.defaults.headers = {
    'content-type': 'application/x-www-form-urlencoded',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
}

const PROTOCOL = window.location.protocol
let FULL_PATH = `/shop/customer/my-documents/api`
axios.defaults.baseURL = `${PROTOCOL}//${window.location.hostname}`

if (devMode) {
    FULL_PATH = 'http://localhost:3333/mock/api/shop/customer/my-documents/api'
}

const getResults = async (query) => {
    return await axios.get(FULL_PATH, {
        params: query,
    })
}

const getDocuments = async (query) => {
    return await axios.get(FULL_PATH, qs.stringify(query))
}

export { getResults, getDocuments }
