//
// Dropdown download
// A dropdown acting as a select with download option

;(($) => {
    $(() => {
        let module = $('.dropdown-download')
        // Only continue if module is on page
        if (!module.length) return

        const manualsDropdown = module.find('.product-manuals-dropdown')
        const downloadButton = module.find('.download-button')

        manualsDropdown.selectric().on('change', function () {
            const value = $(this).val()
            downloadButton.attr('href', value)
        })
    })
})(jQuery)
