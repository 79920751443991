//
// Toggle Pass Visibility
//

;(($) => {
    $(() => {
        const module = $('.apply-form-step, .password-reset-finalization')

        // Only continue if module is on page
        if (!module.length) return

        const passwordWrapper = module.find('.password-wrapper')

        // toggle password visibility
        passwordWrapper.find('.toggle-pass-visibility').click(function () {
            $(this).toggleClass('pass-visible')
            const input = passwordWrapper.find('input')
            if (input.attr('type') === 'password') {
                input.attr('type', 'text')
            } else {
                input.attr('type', 'password')
            }
        })
    })
})(jQuery)
