import { deleteUrlParam, updateUrlParam } from '../url-params/url-params'

const setUrlParamsFromInitData = (filter) => {
    if (filter.type === 'RadioGroup') {
        filter.fields.forEach((field) => {
            if (field.checked) {
                updateUrlParam(field.name, field.value)
            }
        })
    } else if (filter.type === 'CheckboxGroup') {
        let values = []
        filter.fields.map((field) => {
            if (field.checked) {
                values.push(field.id)
            }
        })
        if (values.length > 0) {
            updateUrlParam(filter.name + '[0]', values.join(','))
        }
    } else if (filter.type === 'RangeGroup') {
        if (filter.fields.length > 0) {
            let min = filter.fields[0].initial
            let max = filter.fields[1].initial
            if (min !== null || max !== null) {
                const key = filter.name + '{}'
                const value = {
                    from: min,
                    to: max,
                }
                updateUrlParam(key, JSON.stringify(value))
            }
        }
    }
}

const setUrlParamsUsingData = (type, targetId) => {
    const elem = document.getElementById(targetId)
    if (type === 'radio') {
        const radio = elem.querySelector('input[type=radio]:checked')
        const key = radio.name
        const value = radio.value
        updateUrlParam(key, value)
    } else if (type === 'checkbox') {
        const checkboxes = elem.querySelectorAll('input[type=checkbox]:checked')
        const key = `${$(elem).find('.checkbox-group').attr('data-name')}[0]`
        let value = []
        Object.keys(checkboxes).map((checkbox) => {
            value.push(checkboxes[checkbox].id)
        })

        if (value.length > 0) {
            updateUrlParam(key, value)
        } else {
            deleteUrlParam(key)
        }
    } else if (type === 'range') {
        const key = `${$(elem).find('.range-group').attr('data-name')}{}`
        const inputFromVal = parseFloat(elem.querySelectorAll('input[type=input]')[0].value)
        const inputToVal = parseFloat(elem.querySelectorAll('input[type=input]')[1].value)

        const value = {
            from: inputFromVal,
            to: inputToVal,
        }
        updateUrlParam(key, JSON.stringify(value))
    }
}

const getDataUsingUrlParams = (data) => {
    let parsedData = {}
    Object.keys(data).map((key) => {
        let value
        if (key.includes('[0]')) {
            value = data[key].split(',')
            key = key.replace('[0]', '')
        } else if (key.includes('{}')) {
            value = JSON.parse(data[key])
            key = key.replace('{}', '[]')
        } else {
            value = data[key]
        }
        parsedData[key] = value
    })
    return parsedData
}

export { setUrlParamsUsingData, getDataUsingUrlParams, setUrlParamsFromInitData }
