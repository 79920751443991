import rowsFactory from '../rowsFactory/rowsFactory'
import VARIANTS_TYPES from '../../config/variants-config'
export default class swrBody {
    constructor(props) {
        this.props = props
        this.tbody = document.createElement('tbody')
        this.columnsData = props.data.subColumns
    }
    render() {
        let isEven = false
        this.tbody.setAttribute('class', 'v-tbody swr-body')

        Object.keys(this.props.variant.subVariants).map((key) => {
            const subvariant = this.props.variant.subVariants[key]
            const parentRow = new rowsFactory(VARIANTS_TYPES.SWR_PARENT, {
                ...this.props,
                variantData: subvariant,
                columnsData: this.columnsData,
                isEven,
            }).render()
            const moreRow = new rowsFactory(VARIANTS_TYPES.MORE_DETAILS, { ...this.props, variantsData: subvariant, columnsData: this.columnsData }).render()
            const childRow = new rowsFactory(VARIANTS_TYPES.SWR_CHILD, { ...this.props, subvariant }).render()
            this.tbody.appendChild(parentRow)
            this.tbody.appendChild(moreRow)
            this.tbody.appendChild(childRow)
            isEven = !isEven
        })

        return this.tbody
    }
}
