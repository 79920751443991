import CartManager from './cart-manager'

//
// Cart
//
;(($) => {
    $(() => {
        let module = $('.cart')

        // Only continue if module is on page
        if (!module.length) return

        const removeItemButton = module.find('.cart-item-remove button')

        removeItemButton.click(function () {
            let itemKey = $(this).closest('.cart-item').attr('data-item-key')
            let itemQty = $(this).closest('.cart-item').attr('data-item-quantity')
            CartManager.removeFromCart(itemKey, itemQty)
        })

        let quantityInput = module.find("input[name='cart-item-qty']")

        quantityInput.change(function () {
            let itemKey = $(this).closest('.cart-item').attr('data-item-key')
            CartManager.updateItemQuantity(itemKey, this.value)
        })

        let customerTypeRadio = $('input[name=customerType]:radio')
        customerTypeRadio.change(function () {
            showCustomerForm(this.value)
        })

        let businessForm = $('#businessFormWrapper')
        let privateForm = $('#privateFormWrapper')

        function showCustomerForm(type) {
            if (type === 'private') {
                businessForm.hide()
                privateForm.show()
            } else if (type === 'business') {
                businessForm.show()
                privateForm.hide()
            }
        }

        let form = $('.checkout-section form')
        form.on('submit', function () {
            CartManager.resetCartCount()
        })
    })
})(jQuery)
