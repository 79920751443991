//
// Sales Admin Company Selector
//

;(($) => {
    $(() => {
        let module = $('.sales-company-autocomplete')

        // Only continue if on product sales admin account page
        if (!module.length) return

        module.each(function () {
            let autocompleteUrl = $(this).data('autocomplete-url')

            $(this).autocomplete({
                minLength: 3,
                valueKey: 'value',
                titleKey: 'title',
                source: [
                    {
                        url: autocompleteUrl + '?query=%QUERY%',
                        type: 'remote',
                    },
                ],
                getTitle: function (item) {
                    return item.title
                },
                getValue: function (item) {
                    return item.value
                },
            })
        })
    })
})(jQuery)
