const appendCart = (node, cart) => {
    const targetElement = node
    let srcElement = document.createDocumentFragment()
    if (cart) srcElement.appendChild(cart)
    targetElement.after(srcElement)
}

const removeCart = (cart) => {
    if (cart) cart.remove()
}

export default {
    appendCart,
    removeCart,
}
