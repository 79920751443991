import generateId from '../../../uuid/uuid'
import ClearButton from './clearButton'
import { addTag, toggleCheckboxTag } from './filterTags'

export default class CheckboxGroup {
    constructor(element) {
        this.name = element.name
        this.text = element.text
        this.fieldsAttributes = element.fields
        this.isSearch = element.search
        this.id = generateId()
        this.clearButton = new ClearButton(this.id).render()
        this.tags = []
        this.fieldsHTML = []
    }

    searchGroup(e) {
        const allItems = this.fieldsHTML.length
        const fieldsMap = this.fieldsHTML.map((item) => ({
            id: item.id,
            value: item,
            label: item.getAttribute('data-search'),
        }))

        const occurs = fieldsMap.filter((current) => {
            const fieldSelector = document.getElementById(current.id)
            let label = current.label

            if (label.indexOf(e.target.value.toLowerCase()) === -1) {
                fieldSelector.style.display = 'none'
            } else {
                fieldSelector.style.display = 'flex'
            }

            return label.indexOf(e.target.value.toLowerCase()) === -1
        })

        const groupSelector = document.getElementById(this.id)
        const scrollElement = groupSelector.getElementsByClassName('form-container')
        const noResultsSelector = groupSelector.getElementsByClassName('no-results')
        const countResults = allItems - occurs.length

        if (countResults > 0 && countResults <= 6) {
            scrollElement[0].classList.remove('scrollable')
            noResultsSelector[0].classList.remove('show')
        } else if (countResults >= 7) {
            scrollElement[0].classList.add('scrollable')
            noResultsSelector[0].classList.remove('show')
        } else {
            scrollElement[0].classList.remove('scrollable')
            noResultsSelector[0].classList.add('show')
        }
    }

    renderSearch() {
        const input = document.createElement('input')
        input.type = 'text'
        input.className = 'input input-search'
        input.placeholder = 'Search'
        input.autocomplete = 'off'
        input.addEventListener('input', (e) => {
            this.searchGroup(e)
        })
        return input
    }

    renderLabel(label, field, idx) {
        this.formLabel = document.createElement('label')
        this.formLabel.className = idx === 0 ? 'checkbox-label' : 'additional-label'
        this.formLabel.setAttribute('for', field.id)
        this.formLabel.innerText = `${label}`
        return this.formLabel
    }

    getFieldsHTML() {
        this.fieldsHTML = this.fieldsAttributes.map((field) => {
            const formGroup = document.createElement('div')
            const formInput = document.createElement('input')

            formGroup.className = 'form-group flex'
            formGroup.id = `group_${field.name}`
            formGroup.setAttribute('data-search', field.label.toString().toLowerCase())

            formInput.type = 'checkbox'
            formInput.className = 'checkbox'
            formInput.name = field.name
            formInput.id = field.id
            if (field.disabled) formInput.disabled = 'disabled'
            if (field.checked) formInput.checked = true
            formInput.addEventListener('change', () => {
                toggleCheckboxTag(this.id, field.id)
            })
            if (field.checked) {
                addTag(this.name, `${this.text} - ${field.label}`, field.id, 'checkbox')
            }

            formGroup.appendChild(formInput)

            field.label.forEach((label, idx) => {
                let lbl = label === '' ? '-' : label
                formGroup.appendChild(this.renderLabel(lbl, field, idx))
            })

            return formGroup
        })
    }

    getGroupHTML() {
        const containerGroup = document.createElement('div')
        const filterGroup = document.createElement('div')
        const title = document.createElement('div')
        const icon = document.createElement('i')
        const checkboxGroup = document.createElement('div')
        const noResults = document.createElement('p')
        const buttonsContainer = document.createElement('div')

        noResults.className = 'no-results'
        noResults.innerText = 'no search results'

        containerGroup.className = 'form-container'

        filterGroup.id = this.id
        filterGroup.className = 'filter-group filter-checkbox'

        title.className = 'title'
        title.innerText = this.text

        checkboxGroup.className = 'fields checkbox-group'
        checkboxGroup.setAttribute('data-name', this.name)

        if (this.isSearch) {
            const searchLabel = document.createElement('label')
            const searchGroup = document.createElement('div')
            searchLabel.className = 'icon-label'
            searchGroup.className = 'search-group'
            searchGroup.appendChild(this.renderSearch())
            searchGroup.appendChild(searchLabel)
            checkboxGroup.appendChild(searchGroup)
        }

        this.fieldsHTML.forEach((formGroup) => {
            containerGroup.appendChild(formGroup)
        })

        if (this.fieldsHTML.length > 6) {
            containerGroup.className += ' scrollable custom-scroll thin'
        }

        buttonsContainer.className = 'display-inline'

        title.appendChild(icon)
        filterGroup.appendChild(title)
        buttonsContainer.appendChild(this.clearButton)
        containerGroup.appendChild(noResults)
        checkboxGroup.appendChild(containerGroup)
        checkboxGroup.appendChild(buttonsContainer)
        filterGroup.appendChild(checkboxGroup)

        return filterGroup
    }

    render() {
        this.getFieldsHTML()
        return this.getGroupHTML()
    }
}
