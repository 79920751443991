const onCadDownloadClick = (partCode, action) => {
    window.dataLayer = window.dataLayer || []
    const productName = document.querySelector('.npp__title--heading1').innerText
    window.dataLayer.push({
        event: 'GA Tracking',
        eventCategory: 'cad_file',
        eventAction: action,
        eventLabel: `${productName} / ${partCode}`,
    })
}

export { onCadDownloadClick }
