import axios from 'axios'
import CartManager from '../../../../cart/cart-manager'
import getDataAttributeValue from '../../../../attributes/attributes'
import VARIANTS_TYPES from '../../config/variants-config'
import quantityInput from '../cartForm/quantityInput'
const nodeName = '.new-product-view #products_table'
export default class fastBuyCell {
    constructor(props) {
        this.cell = document.createElement('td')
        this.props = props
        this.parent = props.parent
        this.showAddToCart = props.data.options.showAddToCart
        this.formElem = document.createElement('form')
        this.addButtonElem = document.createElement('button')
        this.quantityElem = document.createElement('div')
        this.id = this.parent.id
        this.quantity = 1
        this.actionURL = getDataAttributeValue(nodeName, 'data-action-url')
        this.observer = props.observer
    }

    setButtonState(state) {
        this.addButtonElem.disabled = state
    }

    blockForm(state) {
        this.observer.publish('disableFastBuyButton', state)
    }

    setCurrencyCode(code) {
        this.currencyCode = code
    }

    setButtonLabel(type) {
        this.addButtonElem.innerHTML = `<i class="icon icon-${type === VARIANTS_TYPES.BUY ? 'basket' : 'quote'}"></i>`
    }

    submitForm(e) {
        e.preventDefault()
        e.stopPropagation()
        if (this.showAddToCart) {
            this.sendGeneric()
        } else {
            this.sendQuote()
        }
    }

    sendGeneric() {
        const data = {
            quantity: this.quantity.toString(),
            product: this.id.toString(),
        }
        this.postData(data)
    }

    sendQuote() {
        const quantity = this.quantity.toString()
        const variantId = this.id.toString()
        CartManager.addToCart(variantId, quantity)
    }

    postData(data) {
        this.blockForm(true)
        axios({
            method: 'post',
            url: this.actionURL,
            data: data,
        })
            .then((response) => {
                const data = response.data
                CartManager.updateCoreshopCartCount(data)
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'addToCart',
                        ecommerce: {
                            currencyCode: data.currencyCode || '',
                            add: {
                                products: [
                                    {
                                        id: data.id.toString(),
                                        name: data.name,
                                        price: data.price || '0',
                                        category: data.category,
                                        quantity: parseInt(data.qty, 10),
                                    },
                                ],
                            },
                        },
                    })
                }
                this.blockForm(false)
            })
            .catch((err) => {
                this.blockForm(false)
                throw err
            })
    }

    resetInput() {
        const input = this.quantityElem.querySelector('input')
        input.value = 1
        this.setQuantity(1)
    }

    setQuantity(qty) {
        this.quantity = parseInt(qty)
    }

    resetCart() {
        this.resetInput()
        this.setQuantity(1)
    }

    render() {
        this.cell.className = 'v-td fast-buy-cell'

        this.formElem.id = 'fast_buy_form'
        this.formElem.method = 'POST'
        this.formElem.noValidate = true

        this.quantityElem.classList.add('quantity')

        const quantityInputElem = document.createElement('input')
        quantityInputElem.type = 'number'
        quantityInputElem.disabled = false

        this.quantityElem.appendChild(quantityInputElem)

        this.addButtonElem.disabled = false
        this.addButtonElem.classList.add('add-button')
        this.addButtonElem.addEventListener('click', (e) => this.submitForm(e))
        this.addButtonElem.title = `${window.globals.trans(this.showAddToCart ? 'filter.add_to_cart' : 'filter.add_to_quote')}`
        this.setButtonLabel(this.showAddToCart ? VARIANTS_TYPES.BUY : VARIANTS_TYPES.QUOTE)

        this.formElem.appendChild(this.quantityElem)
        this.formElem.appendChild(this.addButtonElem)
        this.cell.appendChild(this.formElem)

        this.initQuantityInput()
        this.initObservers()

        return this.cell
    }

    initQuantityInput() {
        quantityInput(
            this.formElem,
            {
                value: this.quantity,
            },
            this.observer,
        )
    }

    initObservers() {
        this.observer.subscribe({
            event: 'disableFastBuyButton',
            action: (state) => {
                this.setButtonState(state)
            },
        })

        this.observer.subscribe({
            event: 'resetCart',
            action: () => {
                this.resetCart()
            },
        })

        this.observer.subscribe({
            event: 'cartQuantity',
            action: ({ quantity }) => {
                this.setQuantity(quantity)
            },
        })

        this.observer.subscribe({
            event: 'activateCart',
            action: (data) => {
                this.resetCart()
                this.setCurrencyCode(data.currencyCode)

                if (this.showAddToCart) {
                    this.setButtonLabel(VARIANTS_TYPES.BUY)
                } else {
                    this.setButtonLabel(VARIANTS_TYPES.QUOTE)
                }
                this.blockForm(false)
            },
        })
    }
}
