;(($) => {
    $(() => {
        let module = $('#quote_form_private')

        // Only continue if module is on page
        if (!module.length) return

        // Recaptcha
        module.on('submit', function (e) {
            e.preventDefault()
            window.grecaptcha.reset()
            window.grecaptcha.execute(window.widgetPrivate)
        })
    })

    $(() => {
        let module = $('#quote_form_business')

        // Only continue if module is on page
        if (!module.length) return

        // Recaptcha
        module.on('submit', function (e) {
            e.preventDefault()
            window.grecaptcha.reset()
            window.grecaptcha.execute(window.widgetBusiness)
        })
    })
})(jQuery)
