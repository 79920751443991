import { postFilters } from '../../../api/catalog'
import debounce from 'debounce'
import { startLoader, stopLoader } from '../../../filter-common/filterLoaders'
import FilterResults from '../FilterResults'
import prepareFilterData from '../../../filter-common/prepareFilterData'

const submitFilter = debounce((sortingParams) => {
    startLoader('results-loader')

    const fData = { ...prepareFilterData(), ...sortingParams }

    postFilters(fData)
        .then((data) => {
            stopLoader('results-loader')
            const productsData = data.data.data.products
            const totalCount = data.data.data.total
            const isLastPage = data.data.data.isLastPage
            new FilterResults().render(productsData, totalCount, isLastPage)
        })
        .catch((error) => {
            stopLoader('results-loader')
            console.log(error)
        })
}, 500)

export { submitFilter }
