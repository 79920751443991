import getDataAttributeValue from '../../../../attributes/attributes'
const nodeName = '.new-product-view #products_table'

export default class stockCell {
    constructor(props) {
        this.cell = document.createElement('td')
        this.stockLevel = parseInt(props.variant.Stock)
        this.stockDisplay = props.stockDisplay
        this.url = getDataAttributeValue(nodeName, 'data-contact-url')
    }
    render() {
        this.cell.setAttribute('class', 'v-td stock-cell count')
        this.cell.setAttribute('data-key', 'Stock')
        const stock = document.createElement('div')
        stock.classList.add('stock')
        if (!isNaN(this.stockLevel)) {
            let labelText = this.stockLevel
            if (this.stockDisplay === 'in-out') {
                labelText = this.stockLevel > 0 ? `${window.globals.trans('filter.in_stock')}` : `${window.globals.trans('filter.out_of_stock')}`
            }
            const availability = this.stockLevel > 0 ? 'in' : 'out'
            stock.classList.add(availability)
            stock.innerText = labelText
        } else {
            const link = document.createElement('a')
            link.setAttribute('href', this.url)
            link.setAttribute('class', 'ask')
            link.setAttribute('target', '_blank')
            link.innerText = `${window.globals.trans('filter.ask')}`
            stock.classList.add('no')
            stock.appendChild(link)
        }
        this.cell.appendChild(stock)

        return this.cell
    }
}
