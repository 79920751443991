let collapsibleBlock

if (window.Vue) {
    collapsibleBlock = window.Vue.component('collapsible-block', {
        props: {
            headingText: String,
        },
        data() {
            return {
                isCollapsed: false,
            }
        },
        template: `
            <div>
                <h4 class="text-primary font-weight-bold text-uppercase fs-14 d-none d-md-block mb-4">
                    {{ headingText }}
                </h4>
                <button 
                    type="button"
                    class="toggle-collapse d-flex w-100 justify-content-between algn-items-center text-uppercase text-primary font-weight-bold d-md-none py-4 px-0"
                    :class="{ 'mb-3': isCollapsed }"
                    :aria-expanded="isCollapsed ? 'true' : 'false'"
                    @click="isCollapsed = !isCollapsed"
                >
                    <span>{{ headingText }}</span>
                    <svg class="icon icon-chevron"><use xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#chevron"></use></svg>
                </button>
                <div class="collapse-content mb-md-4" :class="{ 'show mb-4': isCollapsed }">
                    <slot />
                </div>
            </div>
        `,
    })
} else {
    collapsibleBlock = null
}

export default collapsibleBlock
