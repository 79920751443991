import VARIANTS_TYPES from '../../config/variants-config'

const maxColumnsDesktop = 10
const maxColumnsMobile = 2

Array.prototype.insert = function (index) {
    index = Math.min(index, this.length)
    arguments.length > 1 && this.splice.apply(this, [index, 0].concat([].pop.call(arguments))) && this.insert.apply(this, arguments)
    return this
}

const setColumnsClasses = (row, maxOnDesktop = maxColumnsDesktop, maxOnMobile = maxColumnsMobile) => {
    const headerCells = row.querySelectorAll('.count')
    return Array.from(headerCells).map((cell, index) => {
        if (index + 1 >= maxOnDesktop) {
            cell.classList.add('hide-on-desktop')
        }
        if (index + 1 >= maxOnMobile) {
            cell.classList.add('hide-on-mobile')
        }
    })
}

const hideMoreDetailsColumns = (tbody) => {
    const headerCells = tbody.querySelectorAll('.more-detail-item')
    return Array.from(headerCells).map((cell, index) => {
        if (index + 1 < maxColumnsDesktop) {
            cell.classList.add('hide-on-desktop')
        }
        if (index + 1 < maxColumnsMobile) {
            cell.classList.add('hide-on-mobile')
        }
    })
}

const isMeasurementKey = (key) => {
    return key.startsWith('Measurement.')
}

const changeColumnsOrder = (columns) => {
    let normalItems = []
    let importantItems = []

    columns.map((item) => {
        if (
            item.dataset.key === 'Stock' ||
            item.dataset.key === 'Price' ||
            item.dataset.key === 'BatchPrice' ||
            item.dataset.key === VARIANTS_TYPES.BLUEPRINT ||
            item.dataset.key === VARIANTS_TYPES.CAD
        ) {
            importantItems.push(item)
        } else {
            normalItems.push(item)
        }
    })

    let injectIndex = maxColumnsDesktop - importantItems.length - 1
    normalItems.insert(injectIndex, importantItems)

    return normalItems
}

export { maxColumnsDesktop, maxColumnsMobile, setColumnsClasses, hideMoreDetailsColumns, isMeasurementKey, changeColumnsOrder }
