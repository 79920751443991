export default class dataCell {
    constructor(props) {
        this.cell = document.createElement('td')
        this.props = props
        this.value = props.value
        this.key = props.key
    }

    render() {
        this.cell.setAttribute('class', 'v-td data-cell count')
        this.cell.setAttribute('data-key', this.key)
        this.cell.innerHTML = this.value || '-'
        return this.cell
    }
}
