//
// Swiper Gallery
//

// core version + navigation, pagination modules:
import Swiper from 'swiper'
import { Navigation, Thumbs } from 'swiper/modules'
;(($) => {
    $(() => {
        let module = $('.swiper-gallery')

        // Only continue if module is on page
        if (!module.length) return

        module.each(function () {
            const $this = $(this)
            const carouselSelector = '.swiper-carousel'
            const thumbnailsSelector = '.swiper-thumbnails'
            const zoomSelector = '.swiper-zoom'
            const imageCarousel = $this.find(carouselSelector)
            const html5VideoPlay = $this.find('.video-play-icon')
            const imageZoomButton = $this.find('.zoom-button')
            const imageCloseButton = $this.find('.close-button')
            let activeImageIndex = 0

            const thumbnailsInstance = new Swiper(thumbnailsSelector, {
                modules: [Navigation],
                spaceBetween: 10,
                slidesPerView: 6,
                freeMode: true,
                direction: 'vertical',
                watchSlidesProgress: true,
                navigation: {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                },
                on: {
                    slideChange: function (e) {
                        activeImageIndex = e.realIndex
                    },
                },
            })

            new Swiper(carouselSelector, {
                modules: [Navigation, Thumbs],
                spaceBetween: 10,
                navigation: {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                },
                thumbs: {
                    swiper: thumbnailsInstance,
                },
                on: {
                    slideChange: function (e) {
                        activeImageIndex = e.realIndex
                        const video = imageCarousel.find('.video-playing video')
                        if (video.length) stopHtml5Video(video)
                        const youtubeVideos = imageCarousel.find('.youtube-video')
                        if (youtubeVideos.length) pauseYoutubeVideos(youtubeVideos)
                    },
                },
            })

            new Swiper(zoomSelector, {
                modules: [Navigation],
                spaceBetween: 10,
                navigation: {
                    nextEl: '.swiper-next',
                    prevEl: '.swiper-prev',
                },
                on: {
                    slideChange: function () {
                        pauseVideos(zoomSelector)
                    },
                },
            })

            // Html5 video functionality
            html5VideoPlay.click(function () {
                let video = $(this).closest('.swiper-video-wrapper').find('video')
                playHtml5Video(video)
            })

            // Launch product modal when click on zoom button
            imageZoomButton.click(() => {
                pauseVideos(carouselSelector)
                showProductModal(true, activeImageIndex)
            })

            // Close product modal when click on close button
            imageCloseButton.click(() => {
                pauseVideos(zoomSelector)
                showProductModal(false)
            })
        })
    })

    function playHtml5Video(elem) {
        elem[0].play()
        elem.prop('controls', true)
        elem.closest('.swiper-slide').addClass('video-playing')
    }

    function stopHtml5Video(elem) {
        elem[0].load()
        elem.prop('controls', false)
        elem.closest('.swiper-slide').removeClass('video-playing')
    }

    function showProductModal(visible, activeImageIndex = 0) {
        $('.product-modal').modal(visible ? 'show' : 'hide')
        if (visible) {
            document.querySelector('.swiper-zoom').swiper.slideTo(activeImageIndex)
        }
    }

    function pauseVideos(carousel) {
        if (carousel) {
            const video = $(carousel).find('.video-playing video')
            if (video.length) stopHtml5Video(video)
            const youtubeVideos = $(carousel).find('.youtube-video')
            if (youtubeVideos.length) pauseYoutubeVideos(youtubeVideos)
        }
    }

    function pauseYoutubeVideos(youtubeVideos) {
        for (const iframe of youtubeVideos) {
            const iframeContent = iframe.contentWindow || iframe.contentDocument
            if (iframeContent && iframeContent.postMessage) {
                iframeContent.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
            }
        }
    }
})(jQuery)
