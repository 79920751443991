import { submitFilter } from './submit'
import { removeTag } from './filterTags'
import { deleteUrlParam } from '../../../url-params/url-params'

export default class ClearButton {
    constructor(groupId) {
        this.groupId = groupId
        this.button = document.createElement('div')
        this.button.className = 'button button-link'
        this.button.innerText = `${window.globals.trans('filter.clear')}`
    }

    init() {
        this.addAction()
    }

    addAction() {
        this.button.addEventListener('click', (e) => {
            e.preventDefault()
            const group = document.getElementById(`${this.groupId}`)
            const inputs = group.querySelectorAll('input')
            inputs.forEach((element) => {
                const elem = element
                const type = elem.type
                const disabled = elem.disabled
                if (type === 'checkbox' && !disabled) {
                    const groupName = `${$(group).find('.checkbox-group').attr('data-name')}`
                    removeTag(elem.id)
                    elem.checked = false
                    deleteUrlParam(`${groupName}[0]`)
                } else if (type === 'text' && !disabled && !element.classList.contains('input-search')) {
                    const groupName = elem.name.replace('-max', '{}')
                    elem.value = ''
                    removeTag(this.groupId)
                    deleteUrlParam(groupName)
                } else if (type === 'radio' && !disabled) {
                    removeTag(this.groupId)
                    elem.checked = false
                    deleteUrlParam(elem.name)
                }
            })
            deleteUrlParam('page')
            deleteUrlParam('productId')
            submitFilter()
        })
    }

    render() {
        this.init()
        return this.button
    }
}
