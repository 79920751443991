import Cookies from 'js-cookie'
import Utils from '../utils/utils'

//
// Cookie Notice
//
;(($) => {
    let module = $('.cookie-notice')

    // Only continue if module is on page
    if (!module.length) return

    let cookieNoticeClose = module.find('.accept')

    if (!Cookies.get('cookie_consent')) {
        module.addClass('visible')
    }

    cookieNoticeClose.click(function () {
        module.removeClass('visible')
        Cookies.set('cookie_consent', '1', { expires: 365 })
        Utils.updateContentPadding()
    })
})(jQuery)
