const next = (elem, selector) => {
    let sibling = elem.nextElementSibling
    while (sibling) {
        if (sibling.matches(selector)) return sibling
        sibling = sibling.nextElementSibling
    }
}

const previous = (elem, selector) => {
    let sibling = elem.previousElementSibling
    if (!selector) return sibling
    while (sibling) {
        if (sibling.matches(selector)) return sibling
        sibling = sibling.previousElementSibling
    }
}

export default {
    next,
    previous,
}
