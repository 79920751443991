;(($) => {
    $(() => {
        const moduleSelector = '.hubspot-widget'
        const module = $(moduleSelector)

        // Only continue if module is on page
        if (!module.length) {
            return
        }
        module.empty()
        const region = module.attr('data-region')
        const portalId = module.attr('data-portalid')
        const formId = module.attr('data-formid')
        const scriptUrl = `//js-${region}.hsforms.net/forms/v2.js`

        const loadScript = function (scriptUrl) {
            const script = document.createElement('script')
            script.src = scriptUrl
            document.body.appendChild(script)

            return new Promise((res, rej) => {
                script.onload = function () {
                    res()
                }
                script.onerror = function () {
                    rej()
                }
            })
        }

        const renderForm = function () {
            // eslint-disable-next-line no-undef
            hbspt.forms.create({ region: region, portalId: portalId, formId: formId, target: moduleSelector })
        }
        // use
        loadScript(scriptUrl)
            .then(() => {
                renderForm()
            })
            .catch(() => {})
    })
})(jQuery)
