//
// Select multiple
//

;(($) => {
    $(() => {
        let module = $('.select-multiple')

        // Only continue if module is on page
        if (!module.length) return

        module.each(function () {
            let locale = JSON.parse($(this).attr('data-lang'))
            $(this).multipleSelect({
                width: '100%',
                ellipsis: true,
                minimalCountSelected: 4,
                formatSelectAll() {
                    return locale.formatSelectAll
                },
                formatAllSelected() {
                    return locale.formatAllSelected
                },
                formatCountSelected(count, total) {
                    return count + locale.formatCountSelectedOf + total + locale.formatCountSelected
                },
                formatNoMatchesFound() {
                    return locale.formatNoMatchesFound
                },
            })
        })
    })
})(jQuery)
