import wNumb from 'wnumb'
import noUiSlider from 'nouislider'

//
// Category view
//
;(($) => {
    $(() => {
        let module = $('.category-view')

        // Only continue if module is on page
        if (!module.length) return

        let filters = module.find('.category-filters')
        let categoriesMobile = module.find('.categories-mobile')
        let filterLabel = filters.find('.filter > .label')
        let filterGroup = filters.find('.filter .filter-group')

        //Infinite scroll function
        let slock = false
        let nextpage = $('.pagination .page-next').attr('href')
        const grid = $('div.products-grid')

        if (grid.length > 0) {
            $(window).scroll(function () {
                const domGrid = grid[0]
                const gridBounds = domGrid.getBoundingClientRect()
                const itemHeight = $('div.products-grid').find('li:first').height()
                if (gridBounds.bottom - itemHeight * 2 < $(window).height() && !slock) {
                    slock = true
                    if (nextpage && nextpage !== '') {
                        $('.pagination').before('<div class="product-loader"></div>')
                        $.get(nextpage, function (data) {
                            let scrollData = $(data).find('.products-grid ol').html()
                            nextpage = $(data).find('.pagination .page-next').attr('href')
                            $('.products-grid ol').append(scrollData)
                            $('.product-loader').remove()
                            slock = false
                        })
                    }
                }
            })
        }

        // Category and Filter toggle listeners
        filters.on('show.bs.collapse', function () {
            categoriesMobile.collapse('hide')
        })

        categoriesMobile.on('show.bs.collapse', function () {
            filters.collapse('hide')
        })

        // Filter collapse functionality
        filterLabel.click(function () {
            $(this).next('.filter-group').collapse('toggle')
        })

        filterGroup.on('hide.bs.collapse', function () {
            $(this).prev('.label').addClass('collapsed')
        })

        filterGroup.on('show.bs.collapse', function () {
            $(this).prev('.label').removeClass('collapsed')
        })

        // Range filter
        let rangeFilter = filters.find('.range-filter')
        let slider = rangeFilter.find('.slider')

        slider.each(function () {
            let $this = $(this)

            let minValueInput = $this.closest('.range-filter').find('input[data-range-min]')
            let maxValueInput = $this.closest('.range-filter').find('input[data-range-max]')
            let inputs = [minValueInput, maxValueInput]
            let minValueLabel = $this.closest('.slider-wrapper').find('.min-value')
            let maxValueLabel = $this.closest('.slider-wrapper').find('.max-value')
            let labels = [minValueLabel, maxValueLabel]
            let minValue = $this.data('range-value-min')
            let maxValue = $this.data('range-value-max')
            let currentMinValue = parseFloat(minValueInput.val())
            let currentMaxValue = parseFloat(maxValueInput.val())

            noUiSlider.create($this[0], {
                start: [currentMinValue, currentMaxValue],
                step: 1,
                range: {
                    min: [minValue],
                    max: [maxValue],
                },
                connect: true,
                format: wNumb({
                    decimals: 2,
                }),
            })

            $this[0].noUiSlider.on('update', function (values, handle) {
                inputs[handle].val(values[handle])
                labels[handle].text(values[handle])
            })
        })
    })
})(jQuery)
