import checkCadForRow from '../partials/cadController'
import cellsFactory from '../cellsFactory/cellsFactory'
import { setColumnsClasses, isMeasurementKey } from '../partials/columnsController'
import getSibling from '../../../../utils/getSibling'
import { getFreshPrice, getDiscountProductPrice } from '../../../../api/variants'
import VARIANTS_TYPES from '../../config/variants-config'

export default class subvariantsParentRow {
    constructor(props) {
        this.props = props
        this.isSwr = props.data.options.isSwr
        this.observer = props.observer
        this.cart = props.cart
        this.row = document.createElement('tr')
        this.priceRefreshed = false
        this.variantsForUpdatePrice = []
        this.options = props.data.options
        this.updatePrice = this.options.isCustomerUniquePriceActive
    }

    deactivateCurrentRow(currentActiveParentRow) {
        const previousActiveParentRow = this.props.table.querySelector('.subvariants-parent-row.active')
        const previousOpenedChildeRow = this.props.table.querySelector('.subvariants-child-row.open')
        if (previousOpenedChildeRow) {
            const previousActiveVariant = previousOpenedChildeRow.querySelector('.v-tr.active')
            const previousActiveSwr = previousOpenedChildeRow.querySelector('.v-tr.open')
            if (previousActiveSwr) {
                previousActiveSwr.classList.remove('open')
                this.observer.publish('resetCart')
            }
            if (previousActiveVariant) {
                previousActiveVariant.classList.remove('active')
                this.observer.publish('resetCart')
            }
        }
        if (previousActiveParentRow && previousOpenedChildeRow && currentActiveParentRow !== previousActiveParentRow) {
            previousActiveParentRow.classList.remove('active')
            previousOpenedChildeRow.classList.remove('open')
        }
    }

    handleClick() {
        const childRowToActivate = getSibling.next(this.row, '.subvariants-child-row')
        this.deactivateCurrentRow(this.row)
        this.row.classList.toggle('active')
        childRowToActivate.classList.toggle('open')
        if (this.options.isCadDownloadActive) {
            const childRows = childRowToActivate.querySelectorAll('.generic-subvariant-row')
            const isCadChecked = this.row.classList.contains('cad-checked')
            if (!isCadChecked) {
                for (const row of childRows) {
                    checkCadForRow(row, this.observer)
                }
                this.row.classList.add('cad-checked')
            }
        }
        if (!this.priceRefreshed && this.updatePrice) {
            this.variantsForUpdatePrice.forEach((item) => {
                const variantId = item.id
                const partCode = item.partCode

                this.refreshPrice(variantId, partCode)
            })
        }
    }

    refreshPrice(variantId, partCode) {
        this.observer.publish('deactivateCart')
        if (this.options.isCustomerUniquePriceActive && !this.options.erpPrice) {
            getDiscountProductPrice(variantId)
                .then((res) => {
                    const freshPriceData = res.data
                    this.observer.publish('updatePrice', { variantId, freshPriceData, success: true, options: this.options })
                })
                .catch((err) => {
                    this.observer.publish('updatePrice', { variantId, success: false })
                    console.error(err)
                })
                .finally(() => {
                    this.priceRefreshed = true
                })
        } else if (this.options.isCustomerUniquePriceActive && this.options.erpPrice) {
            getFreshPrice(partCode)
                .then((res) => {
                    const freshPriceData = res.data
                    this.observer.publish('updatePrice', { variantId, freshPriceData, success: true, options: this.options })
                })
                .catch((err) => {
                    this.observer.publish('updatePrice', { variantId, success: false })
                    console.error(err)
                })
                .finally(() => {
                    this.priceRefreshed = true
                })
        }
    }

    render() {
        this.row.className = `v-tr subvariants-parent-row ${this.props.isEven ? 'even' : 'odd'}`
        this.row.appendChild(new cellsFactory(VARIANTS_TYPES.ARROW).render())
        this.props.data.columns.map((column) => {
            const cellValue = this.props.variant[column.key]

            this.variantsForUpdatePrice = this.props.variant.subVariants.map((item) => {
                return {
                    partCode: item.PartCode,
                    id: item.id,
                }
            })

            if (!isMeasurementKey(column.key)) {
                this.row.appendChild(new cellsFactory('data', { value: cellValue, id: this.row.id, key: column.key }).render())
            }
        })
        setColumnsClasses(this.row, 99, 4)

        this.row.addEventListener('click', (e) => {
            this.handleClick(e)
        })
        return this.row
    }
}
