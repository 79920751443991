import generateResults from './FilterResultsGenerator'
import ElementsFactory from './factories/ElementsFactory'
import { getFilters, postFilters } from '../../api/variants'
import { getUrlParams } from '../../url-params/url-params'
import { getDataUsingUrlParams, setUrlParamsFromInitData } from '../../filter-common/urlDataParser'
import { stopLoader } from '../../filter-common/filterLoaders'
import scrollTo from '../../components/scrollTo/scrollTo'

class FilterColumn {
    initialize() {
        this.module = document.getElementById('filters')
        if (!this.module) return
        this.loadInitData()
    }

    loadInitData() {
        getFilters()
            .then((data) => {
                const filtersData = data.data.data.filters
                this.initFiltersData = filtersData
                const typesData = data.data.data.filters.types || []

                let generateUrlParams = location.href.indexOf('#') == -1
                if (generateUrlParams && filtersData !== [] && typesData.length > 0) {
                    this.generateFilterPreselectParam(filtersData)
                }

                this.loadProducts()
            })
            .catch((err) => {
                stopLoader('column-loader')
                stopLoader('results-loader')
                console.log(err)
            })
    }

    getCleanFilterData() {
        let result = this.initFiltersData
        result.types.forEach((filter) => {
            filter.fields.forEach((field) => {
                field.checked = false
                field.initial = null
            })
        })
        return result
    }

    loadProducts() {
        const dataFromUrl = getUrlParams()
        const dataFromUrlParsed = getDataUsingUrlParams(dataFromUrl)
        postFilters(dataFromUrlParsed)
            .then((data) => {
                stopLoader('column-loader')
                stopLoader('results-loader')
                const resData = data.data.data
                const filtersData = this.getCleanFilterData()
                const typesData = filtersData.types || []

                const hideVariantsSectionOnProductPage = data.data.data.variants.length === 0
                if (hideVariantsSectionOnProductPage) {
                    const variantsWrapper = document.querySelector('.npp__variants')
                    const rfqButton = document.querySelector('.npp__rfq')
                    const hubSpotWidget = variantsWrapper.querySelector('.hubspot-widget')
                    if (hubSpotWidget) variantsWrapper.after(hubSpotWidget)
                    if (rfqButton) rfqButton.style.display = 'none'
                    if (variantsWrapper) variantsWrapper.style.display = 'none'
                }

                Object.keys(dataFromUrlParsed).map((item) => {
                    const groupType = getGroupType(dataFromUrlParsed[item])

                    if (groupType === 'CheckboxGroup') {
                        const itemName = item.replace('[]', '')
                        const itemValues = dataFromUrlParsed[item]
                        const groupToUpdate = getGroup(itemName)
                        if (groupToUpdate.length > 0) {
                            const fields = groupToUpdate[0].fields
                            groupToUpdate[0].collapsed = false

                            itemValues.map((value) => {
                                fields.map((field) => {
                                    if (field.id === value) {
                                        field.checked = true
                                    }
                                })
                            })
                        }
                    } else if (groupType === 'RadioGroup') {
                        const itemName = item
                        const itemValue = dataFromUrlParsed[item] === '1' ? 1 : 0

                        const groupToUpdate = getGroup(itemName)
                        if (groupToUpdate.length > 0) {
                            const fields = groupToUpdate[0].fields
                            groupToUpdate[0].collapsed = false

                            fields.map((field) => {
                                if (field.value === itemValue) {
                                    field.checked = true
                                }
                            })
                        }
                    } else if (groupType === 'RangeGroup') {
                        const itemName = item.replace('[]', '')
                        const rangeMinValue = dataFromUrlParsed[item].from
                        const rangeMaxValue = dataFromUrlParsed[item].to

                        const groupToUpdate = getGroup(itemName)
                        if (groupToUpdate.length > 0) {
                            const fields = groupToUpdate[0].fields
                            groupToUpdate[0].collapsed = false
                            if (fields.length > 0) {
                                fields[0].initial = rangeMinValue
                                fields[1].initial = rangeMaxValue
                            }
                        }
                    }
                })

                function getGroup(itemName) {
                    return typesData.filter((group) => {
                        return group.name === itemName
                    })
                }

                function getGroupType(field) {
                    if (typeof field === 'object') {
                        if (Array.isArray(field)) {
                            return 'CheckboxGroup'
                        } else {
                            return 'RangeGroup'
                        }
                    } else if (typeof field === 'string') {
                        return 'RadioGroup'
                    }
                }

                if (filtersData !== []) {
                    if (typesData.length > 0) {
                        this.prepareFilterLayout()
                        this.generateFilterColumn(filtersData)
                    } else {
                        const filterWrapper = document.querySelector('.filter')
                        const filterButton = document.querySelector('.npp__variants .variants-table-toggle')
                        const filterButtonMobile = document.querySelector('.npp__variants .toggle-button-container')

                        if (filterWrapper !== null) filterWrapper.style.display = 'none'
                        if (filterButton !== null) filterButton.style.display = 'none'
                        if (filterButtonMobile !== null) filterButtonMobile.style.display = 'none'
                    }
                }

                generateResults(resData)
            })
            .catch((error) => {
                stopLoader('column-loader')
                stopLoader('results-loader')
                console.log(error)
            })
    }

    prepareFilterLayout() {
        const filterHeader = document.querySelector('.filter-header')
        const filterScroll = document.querySelector('.scroll-disabled')
        filterHeader.classList.add('show')
        if (filterScroll.length > 0) filterScroll.classList.remove('scroll-disabled')
    }

    initShowMoreFilters(showMoreButton) {
        const filterHeader = document.querySelector('.filter-header')
        const rowPadding = 30
        const firstFilter = this.module.querySelector('.filter-group')
        const rowHeight = `${firstFilter.offsetHeight + rowPadding}px`
        this.module.style.height = rowHeight
        showMoreButton.addEventListener('click', (e) => {
            this.module.classList.toggle('open')
            if (this.module.classList.contains('open')) {
                this.module.style.height = 'auto'
                e.target.innerText = `${window.globals.trans('filter.hide_more_filters')}`
            } else {
                this.module.style.height = rowHeight
                e.target.innerText = `${window.globals.trans('filter.show_more_filters')}`
            }
            scrollTo(filterHeader, -160)
        })
    }

    generateFilterColumn(filtersData) {
        let filtersToShow = 0
        const showMoreButton = document.querySelector('.show-more-filters')

        filtersData.types
            .map((type) => {
                return ElementsFactory.createInstance(type)
            })
            .forEach((filter) => {
                if (filter) {
                    this.module.appendChild(filter.render())
                    filtersToShow++
                }
            })
        if (filtersToShow > 3) {
            this.initShowMoreFilters(showMoreButton)
        } else {
            showMoreButton.style.display = 'none'
        }
    }

    generateFilterPreselectParam(filtersData) {
        filtersData.types.forEach((filter) => {
            setUrlParamsFromInitData(filter)
        })
    }
}

export { FilterColumn }
