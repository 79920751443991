const onCategoryBannerClick = (e, name) => {
    window.dataLayer = window.dataLayer || []
    const eventAction = `${name} / ${window.location.href}`
    const eventLabel = e.currentTarget.href.toLowerCase()

    window.dataLayer.push({
        event: 'GA Tracking',
        eventCategory: 'banner',
        eventAction: eventAction,
        eventLabel: eventLabel,
    })
}

export { onCategoryBannerClick }
