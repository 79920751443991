export default class emptyCell {
    constructor(props) {
        this.cell = document.createElement('td')
        this.props = props
    }
    render() {
        this.cell.className = 'v-td empty-cell'
        if (this.props.colspan) {
            this.cell.setAttribute('colspan', this.props.colspan)
        }
        return this.cell
    }
}
