import rowsFactory from '../rowsFactory/rowsFactory'
import { selectRow } from '../partials/rowController'
import VARIANTS_TYPES from '../../config/variants-config'
export default class swrBody {
    constructor(props) {
        this.props = props
        this.type = props.type
        this.tbody = document.createElement('tbody')
        this.columnsData = props.data.columns
        this.variants = props.data.variants
        this.productVariantPreselection = props.data.options.productVariantPreselection
    }
    render() {
        let isEven = false
        this.tbody.setAttribute('class', 'v-tbody swr-body')

        this.variants.map((variant) => {
            const parentRow = new rowsFactory(VARIANTS_TYPES.SWR_PARENT, {
                ...this.props,
                variantData: variant,
                columnsData: this.columnsData,
                isEven,
            }).render()
            const moreRow = new rowsFactory(VARIANTS_TYPES.MORE_DETAILS, { ...this.props, variantsData: variant, columnsData: this.columnsData }).render()
            const childRow = new rowsFactory(VARIANTS_TYPES.SWR_CHILD, { ...this.props, variant }).render()
            this.tbody.appendChild(parentRow)
            this.tbody.appendChild(moreRow)
            this.tbody.appendChild(childRow)
            isEven = !isEven
        })
        if (this.productVariantPreselection) selectRow.first(this.type, this.tbody)

        return this.tbody
    }
}
