//
// Select multiple
//

;(($) => {
    $(() => {
        let module = $("input[type='file']")

        // Only continue if module is on page
        if (!module.length) return

        const chooseFileTranslation = module.siblings('.file-input-label').find('span').text()
        $(module).on('change', function () {
            if ($(this)[0].files[0]) {
                $(this).siblings('.file-input-label').find('span').text($(this)[0].files[0].name)
            } else {
                $(this).siblings('.file-input-label').find('span').text(chooseFileTranslation)
            }
        })
    })
})(jQuery)
