//
// Image carousel
//

;(($) => {
    $(() => {
        let module = $('.image-carousel')

        // Only continue if module is on page
        if (!module.length) return

        module.each(function () {
            let $this = $(this)
            let imageZoomButton = $this.find('.zoom-button')
            let imageCarousel = $this.find('.owl-carousel')
            let html5VideoPlay = imageCarousel.find('.video-play-icon')
            let imageCloseButton = $this.find('.close-button')
            let productModal = $('.product-modal')

            // Call owl carousel on image carousels
            setTimeout(() => {
                initCarousel(imageCarousel)
            }, 0)

            // Launch product modal when click on zoom button
            imageZoomButton.click((e) => {
                showProductModal(true, $(e.target))
                // Trigger change to stop youtube & vimeo video
                imageCarousel.data('owl.carousel').trigger('changed', { property: { name: 'position' } })
            })

            // Close product modal when click on close button
            imageCloseButton.click((e) => {
                showProductModal(false, $(e.target))
                // Trigger change to stop youtube & vimeo video
                imageCarousel.data('owl.carousel').trigger('changed', { property: { name: 'position' } })
            })

            // Html5 video functionality
            html5VideoPlay.click(function () {
                let video = $(this).closest('.owl-video-wrapper').find('video')
                playHtml5Video(video)
            })

            // Handle html5 video on carousel change
            imageCarousel.on('changed.owl.carousel', function () {
                let video = $(this).find('.video-playing video')
                if (video.length) {
                    stopHtml5Video(video)
                }
            })

            productModal.on('hide.bs.modal', function () {
                // Trigger change to stop youtube & vimeo video
                let videos = $(this).find('video')
                if (videos.length > 0) {
                    pauseVideo(videos)
                }
                $(this)
                    .find('.owl-carousel')
                    .data('owl.carousel')
                    .trigger('changed', { property: { name: 'position' } })
            })

            productModal.on('changed.owl.carousel', function () {
                let videos = $(this).find('video')
                if (videos.length > 0) {
                    pauseVideo(videos)
                }
            })

            imageCarousel.on('changed.owl.carousel', function () {
                let videos = $(this).find('video')
                if (videos.length > 0) {
                    pauseVideo(videos)
                }
            })
        })
    })

    function initCarousel(imageCarousel) {
        imageCarousel.owlCarousel({
            items: 1,
            loop: true,
            dots: false,
            video: true,
            lazyLoad: true,
            nav: true,
            navText: [
                '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#previous"></use></svg>',
                '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#next"></use></svg>',
            ],
            thumbs: true,
            thumbsPrerendered: true,
        })
    }

    function pauseVideo(videos) {
        $.each(videos, function () {
            this.pause()
        })
    }

    function showProductModal(visible, elem) {
        let carousel = elem.closest('.image-carousel').find('.owl-carousel')
        let brickIndex = carousel.data('slider-id')
        let productModal = $('.product-modal')
        let activeCarouselItemIndex = carousel.find('.owl-item.active > div').data('carousel-index')
        if ($('#imageModal-' + brickIndex).length) {
            $('#imageModal-' + brickIndex).modal(visible ? 'show' : 'hide')
            if (visible) {
                $('#imageModal-' + brickIndex + ' .owl-carousel').trigger('to.owl.carousel', activeCarouselItemIndex, 0)
            } else {
                elem.parents('.product-modal').modal('hide')
            }
        } else {
            productModal.modal(visible ? 'show' : 'hide')
            if (visible) {
                $('.product-modal .owl-carousel').trigger('to.owl.carousel', activeCarouselItemIndex, 0)
            }
        }
    }

    function playHtml5Video(elem) {
        elem[0].play()
        elem.prop('controls', true)
        elem.closest('.owl-item').addClass('video-playing')
    }

    function stopHtml5Video(elem) {
        elem[0].load()
        elem.prop('controls', false)
        elem.closest('.owl-item').removeClass('video-playing')
    }
})(jQuery)
