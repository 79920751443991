const getHTML = (data) => {
    const header = generateHeader(data)
    const list = generateList(data)
    return `${header}${list}`
}

const generateHeader = (data) => {
    return `<h3 class="es-form__results-header">${window.globals.trans('search.categories_heading')} (${data.total})</h3>`
}

const generateList = (data) => {
    const listItems = data.entries
        .map((item) => {
            return `<li class="es-form__results-link"><a href="${item.url}">${item.name} (${item.total})</a></li>`
        })
        .join('')

    return `<ul class="es-form__results-list es-form__results-list--column es-form__results--categories">${listItems}</ul>`
}

export default { getHTML }
