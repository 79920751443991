import OrderItemModuleLoader from '../common/orderItemModuleLoader'
import ItemDetailsTablet from '../common/item-details-tablet'
import CollapsibleBlock from '../common/collapsible-block'
import ReorderButton from '../common/reorder-button'

let OrderItem
let orderRow

if (window.Vue) {
    OrderItem = window.Vue.component('order-item', OrderItemModuleLoader)
} else {
    OrderItem = null
}

if (window.Vue) {
    orderRow = window.Vue.component('order-row', {
        components: {
            OrderItem,
            ItemDetailsTablet,
            CollapsibleBlock,
            ReorderButton,
        },
        props: {
            orderData: Object,
            translations: Object,
        },
        data() {
            return {
                isExpanded: false,
            }
        },
        methods: {
            toggleExpand() {
                this.isExpanded = !this.isExpanded
            },
            emitReorderModal(orderId) {
                this.$emit('reorder-comment-modal', orderId)
            },
        },
        template: `
            <div class="order-row row d-flex flex-column flex-md-row flex-md-wrap pt-4 pt-md-2 mx-md-0 align-items-center" :class="{ 'collapsed': isExpanded }">
                <div class="col-md-3 col-xxl-3 text-md-center mb-2 mb-md-0 text-primary order-num-col">
                    <div class="py-2 py-md-0">
                        <span class="mobile-label d-md-none w-50">{{ translations["orders_history.heading.order_nr_title"] }}</span>
                        {{ orderData.deliveryId }}
                    </div>
                </div>
                <div class="col-md-3 col-xxl-2 text-md-center no-wrap py-2 py-md-0">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.heading.date_title"] }}</span>
                    {{ orderData.date }}
                </div>
                <div class="col-xxl-2 d-md-none d-xxl-block text-md-center py-2 py-md-0">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.heading.customer_po_title"] }}</span>
    
                    <span v-if="orderData.customerPoNumber">{{ orderData.customerPoNumber }}</span>
                    <div v-else class="alert alert-info d-inline-block p-1 px-lg-2 m-0">
                        {{ translations["orders_history.customer_po.no_number"] }}
                    </div>
                </div>
                <div class="col-md-3 col-xxl-3 text-md-center no-wrap py-2 py-md-0">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.heading.order_nr_erp"] }}</span>
                    {{ orderData.orderNumberErp }}
                </div>
                <div class="col-md-3 col-xxl-2 text-md-center py-2 px-md-0 details-col">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.heading.details_title"] }}</span>
                    <button
                        type="button"
                        class="chevron-circle position-relative toggle-details"
                        :aria-expanded="isExpanded ? 'true' : 'false'"
                        @click="toggleExpand"
                    >
                        <svg class="icon icon-chevron"><use xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#chevron"></use></svg>
                    </button>
                </div>
        
                <div v-if="isExpanded" class="order-items-details-container w-100 p-0">
                    <div class="d-none d-md-block d-xxl-none px-md-4 px-lg-6 py-4">
                        <!-- <div>{{ translations["orders_history.customer_reference_title"] }}: {{ orderData.customerReference }}</div> -->
                        <div>{{ translations["orders_history.heading.customer_po_title"] }}: {{ orderData.customerPoNumber || translations["orders_history.customer_po.no_number"] }}</div>
                    </div>
                    <div class="px-md-4 px-lg-6 d-none d-lg-block">
                        <hr class="mb-0 mt-1">
                    </div>
                    <div class="heading-row d-none d-lg-flex align-items-center px-md-4 px-lg-6 py-2">
                        <div class="col-5">{{ translations["orders_history.row.order_details_title"] }}</div>
                        <div class="col-2 text-center">{{ translations["orders_history.row.ot_number"] }}</div>
                        <div class="col-2 text-center">{{ translations["orders_history.row.quantity_title"] }}</div>
                        <div class="col-3 text-center"></div>
                    </div>
    
                    <div class="order-items-container w-100 d-flex flex-column px-4 px-lg-6">
                        <order-item v-for="item in orderData.items" :translations="translations" :item-data="item" :key="" />
                    </div>
    
                </div>
            </div>
        `,
    })
} else {
    orderRow = null
}

export default orderRow
