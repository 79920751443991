//
// Async tokens
//

;(($) => {
    $(() => {
        let module = $('form')

        // Only continue if module is on page
        if (!module.length) return

        module.each(function () {
            if ($(this).attr('name')) {
                let tokenInput = $(this).find("input[name*='token']")
                if (tokenInput.length) {
                    fetchToken($(this).attr('name'), tokenInput)
                }
            }
        })

        function fetchToken(formName, inputNode) {
            $.ajax({
                type: 'GET',
                url: '/getCsrfToken',
                data: {
                    form: formName,
                },
                success: (response) => {
                    inputNode.val(response.token)
                },
                error: (error) => {
                    console.error('Error while fetching CSRF token', error)
                },
            })
        }
    })
})(jQuery)
