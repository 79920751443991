const getHTML = (data) => {
    const header = generateHeader(data)
    const content = generateList(data)
    const footer = generateFooter(data)
    const separator = generateSeparator()
    return `${header}${content}${footer}${separator}`
}

const generateSeparator = () => {
    return '<div class="es-form__results-separator"></div>'
}

const generateHeader = (data) => {
    return `<h3 class="es-form__results-header">${window.globals.trans('search.products_heading')} (${data.total})</h3>`
}

const generateList = (data) => {
    const listItems = data.entries
        .map((item) => {
            return `<li>
                <a href="${item.url}">
                    <figure class="es-form__results-block-image h100"><img src="${item.image}" loading="lazy"/></figure>
                    <div class="es-form__results-block-title">${item.name}</div>
                </a>
            </li>`
        })
        .join('')

    return `<ul class="es-form__results-list es-form__results-list--row es-form__results-list--product">${listItems}</ul>`
}

const generateFooter = (data) => {
    return `<a href="${data.url}&tab=products" data-tab="products" class="es-form__results-show-all">${window.globals.trans('search.show_all_products')} ></a>`
}

export default { getHTML }
