//
// My documents
//

import { easepick } from '@easepick/core'
import { RangePlugin } from '@easepick/range-plugin'
import { AmpPlugin } from '@easepick/amp-plugin'
import { debounce } from 'debounce'
import { getResults } from '../api/documents'
import { startLoader, stopLoader } from '../filter-common/filterLoaders'
import 'twbs-pagination'
;(($) => {
    $(() => {
        let module = $('.my-documents')

        if (!module.length) return

        const filterButtonSelector = $('.my-documents__filter-button')
        const filterClearButtonSelector = $('.my-documents__clear-button')
        const filterSelector = $('.my-documents__filters-advanced')
        const filterFormSelector = $('.my-documents__form')[0]
        const filterInputSelectors = $('.my-documents__form input')
        const allSortableHeaders = $('.my-documents__results-table-header.sortable')
        const resultsTableSelector = $('.my-documents__results-table tbody')
        const resultsPaginationSelector = $('.my-documents__results-pagination')
        const selectAllCheckboxesSelector = $('.my-documents__select-all')
        const downloadButtonSelector = $('.my-documents__download-button')
        const messageSelector = $('.my-documents__message')

        for (let selectAllCheckbox of selectAllCheckboxesSelector) {
            selectAllCheckbox.addEventListener('change', (event) => {
                const checkboxStatus = event.target.checked
                const targetId = event.target.id.split('_')[0]
                const tableItems = resultsTableSelector.find(`[id*="${targetId}"]`)
                for (let singleCheckbox of tableItems) {
                    $(singleCheckbox).prop('checked', checkboxStatus)
                }
            })
        }

        const resetAllCheckboxes = () => {
            for (let selectAllCheckbox of selectAllCheckboxesSelector) {
                $(selectAllCheckbox).prop('checked', false)
            }
        }

        const filterDatepicker = new easepick.create({
            element: document.getElementById('my_documents_datepicker'),
            css: [
                'https://cdn.jsdelivr.net/npm/@easepick/core@1.2.0/dist/index.css',
                'https://cdn.jsdelivr.net/npm/@easepick/range-plugin@1.2.0/dist/index.css',
                '/',
            ],
            setup(picker) {
                picker.on('select', () => {
                    sendForm()
                })
            },
            AmpPlugin: {
                dropdown: {
                    months: true,
                    years: true,
                },
                resetButton: true,
                darkMode: false,
            },
            RangePlugin: {
                tooltip: false,
            },
            zIndex: 9999,
            grid: 2,
            calendars: 2,
            lang: document.querySelector('html').getAttribute('data-locale').replace('_', '-'),
            plugins: [RangePlugin, AmpPlugin],
        })

        filterButtonSelector.click(() => {
            filterSelector.slideToggle()
        })

        filterClearButtonSelector.click(() => {
            for (let input of filterInputSelectors) {
                input.value = ''
                filterDatepicker.clear()
                resultsTableSorting.resetHeaders(allSortableHeaders)
            }
            sendForm()
        })

        const getFilterDate = () => {
            const rawFrom = filterDatepicker.getStartDate()
            const rawTo = filterDatepicker.getEndDate()
            return {
                from: rawFrom ? rawFrom.format('YYYY-MM-DD') : '',
                to: rawTo ? rawTo.format('YYYY-MM-DD') : '',
            }
        }

        for (let input of filterInputSelectors) {
            input.addEventListener(
                'keyup',
                debounce(() => {
                    sendForm()
                }, 500),
            )
        }

        const resultsTableSorting = {
            config: {
                sortBy: 'default',
                direction: 'default',
            },
            resetHeaders: (headers) => {
                for (let header of headers) {
                    const selector = $(header)
                    selector.removeClass('asc desc default')
                    selector.addClass('default')
                    selector.attr('data-direction', 'default')
                    resultsTableSorting.setSortBy('default')
                    resultsTableSorting.setDirection('default')
                }
            },
            setActiveHeader: (header) => {
                const directionTypes = ['asc', 'desc', 'default']
                const direction = directionTypes[($.inArray(header.attr('data-direction'), directionTypes) + 1) % directionTypes.length]
                header.attr('data-direction', direction)
                const sortBy = header.attr('data-sortby')
                const allOthersSortableHeaders = allSortableHeaders.filter((item) => $(allSortableHeaders[item]).data('sortby') !== sortBy)
                resultsTableSorting.resetHeaders(allOthersSortableHeaders)
                resultsTableSorting.setSortBy(sortBy)
                resultsTableSorting.setDirection(direction)
                sendForm()
            },
            setSortBy: (sortBy) => {
                resultsTableSorting.config.sortBy = sortBy
            },
            setDirection: (direction) => {
                return (resultsTableSorting.config.direction = direction)
            },
            getSortBy: () => {
                return resultsTableSorting.config.sortBy
            },
            getDirection: () => {
                return resultsTableSorting.config.direction
            },
        }

        allSortableHeaders.on('click', function () {
            const element = $(this)
            resultsTableSorting.setActiveHeader(element)
        })

        const tablePagination = {
            config: {
                page: 1,
                limit: 10,
                total: 20,
            },
            setPage: (page) => (tablePagination.config.page = page),
            setTotal: (total) => (tablePagination.config.total = total),
            getPage: () => tablePagination.config.page,
            getTotal: () => tablePagination.config.total,
            getLimit: () => tablePagination.config.limit,
            redraw: () => {
                resultsPaginationSelector.twbsPagination({
                    totalPages: Math.ceil(tablePagination.getTotal() / tablePagination.getLimit()),
                    startPage: tablePagination.getPage(),
                    initiateStartPageClick: false,
                    first: `${window.globals.trans('account.my_documents.first')}`,
                    last: `${window.globals.trans('account.my_documents.last')}`,
                    prev: `${window.globals.trans('account.my_documents.prev')}`,
                    next: `${window.globals.trans('account.my_documents.next')}`,
                    onPageClick: function (event, page) {
                        tablePagination.setPage(page)
                        sendForm()
                    },
                })
            },
        }

        const generateResults = (items) => {
            if (items.length > 0) {
                let tableRow = ''
                items.forEach((item) => {
                    tableRow += `
                            <tr class="my-documents__results-table-row">
                                <td class="my-documents__results-table-cell my-documents__results-table-product-name">
                                    ${
                                        item.productUrl
                                            ? `<a href="${item.productUrl}">${item.name}</a>`
                                            : `<p class="my-documents__results-table-product-name--no-link">${item.name}</p>`
                                    }
                                    <p class="my-documents__results-table-part-code">${item.partCode}</p>
                                    <p class="my-documents__results-table-group">${item.description1 ? item.description1 : ''}</p>
                                </td>
                                <td class="my-documents__results-table-cell my-documents__results-table-batch-number">
                                    ${item.batchNumber ? item.batchNumber : '-'}
                                </td>
                                <td class="my-documents__results-table-cell my-documents__results-table-serial-number">
                                    ${item.serialNumber ? item.serialNumber : '-'}
                                </td>
                                <td class="my-documents__results-table-cell">
                                    <p class="my-documents__results-table-reference">${item.customerReference}</p>
                                    <p class="my-documents__results-table-customer-order-number">${window.globals.trans(
                                        'account.my_documents.customer_order_number',
                                    )}</p>
                                    <p class="my-documents__results-table-customer-order-number">${item.customerPoNumber ? item.customerPoNumber : '-'}</p>
                                </td>
                                <td class="my-documents__results-table-cell my-documents__results-table-order-date">
                                    ${item.date}
                                </td>
                                <td class="my-documents__results-table-cell my-documents__results-table-order-number checkbox-group">
                                    <div class="form-group flex justify-content-center">
                                        <a href="/static/images/samples/order_document.pdf" target="_blank">${window.globals.trans(
                                            'account.my_documents.view',
                                        )}</a> <input type="checkbox" class="checkbox" name="orderNumber_${item.id}" id="orderNumber_${item.id}">
                                        <label class="checkbox-label" for="orderNumber_${item.id}"></label>
                                    </div>
                                    <p>${item.orderId}</p>
                                </td>
                                <td class="my-documents__results-table-cell my-documents__results-table-invoice-number checkbox-group">
                                    <div class="form-group flex justify-content-center">
                                        <a href="/static/images/samples/invoice_document.pdf" target="_blank">${window.globals.trans(
                                            'account.my_documents.view',
                                        )}</a> <input type="checkbox" class="checkbox" name="invoiceNumber_${item.id}" id="invoiceNumber_${item.id}">
                                        <label class="checkbox-label" for="invoiceNumber_${item.id}"></label>
                                    </div>
                                    <p>${item.invoiceNumber ? item.invoiceNumber : '-'}</p>
                                </td>
                                <td class="my-documents__results-table-cell my-documents__results-table-part-document checkbox-group">
                                    <div class="form-group flex justify-content-center">
                                        <a href="/static/images/samples/part_document.pdf" target="_blank">${window.globals.trans(
                                            'account.my_documents.view',
                                        )}</a> <input type="checkbox" class="checkbox" name="partDocument_${item.id}" id="partDocument_${item.id}">
                                        <label class="checkbox-label" for="partDocument_${item.id}"></label>
                                    </div>
                                </td>
                                <td class="my-documents__results-table-cell my-documents__results-table-user-manual checkbox-group">
                                    <div class="form-group flex justify-content-center">
                                        <a href="/static/images/samples/user_manual_document.pdf" target="_blank">${window.globals.trans(
                                            'account.my_documents.view',
                                        )}</a> <input type="checkbox" class="checkbox" name="userManual_${item.id}" id="userManual_${item.id}">
                                        <label class="checkbox-label" for="userManual_${item.id}"></label>
                                    </div>
                                </td>
                            </tr>
                `
                })
                resultsTableSelector.html('')
                resultsTableSelector.append(tableRow)
                tablePagination.redraw()
            } else {
                resultsTableSelector.html(
                    `<tr><td colspan="9"><p class="text-center p-5 mb-0">${window.globals.trans('account.my_documents.no_results')}</p></td></tr>`,
                )
            }
        }

        downloadButtonSelector.on('click', (event) => {
            event.preventDefault()
            let payload = {}
            resultsTableSelector.find('.checkbox:checked').each(function () {
                const key = $(this).attr('id').split('_')[0]
                const val = $(this).attr('id').split('_')[1]
                if (payload[key]) {
                    payload[key] = [...payload[key], val]
                } else {
                    payload[key] = [val]
                }
            })
            if (!$.isEmptyObject(payload)) {
                messageController.hide()

                // getDocuments(payload)
                //     .then((res) => {
                //         console.log(res)
                //     })
                //     .catch((err) => {
                //         console.log(err)
                //     })

                // Temporary hardcoded solution for download documents .zip file
                download(event.target.dataset.url)
            } else {
                messageController.show('Please select at least one document')
            }
        })

        const download = (url) => {
            const a = document.createElement('a')
            a.href = url
            a.download = url.split('/').pop()
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
        }

        const messageController = {
            show: (text) => {
                messageSelector.show()
                messageSelector.find('.my-documents__message-wrapper').html(`<i class="icon icon-info"></i>${text}`)
            },
            hide: () => {
                messageSelector.hide()
                messageSelector.find('.my-documents__message-wrapper').html('')
            },
        }

        const sendForm = () => {
            const data = {}
            const formData = new FormData(filterFormSelector)
            const dateRange = getFilterDate()
            formData.append('dateFrom', dateRange.from)
            formData.append('dateTo', dateRange.to)
            formData.append('sortBy', resultsTableSorting.getSortBy())
            formData.append('direction', resultsTableSorting.getDirection())
            formData.append('page', tablePagination.getPage().toString())
            formData.append('limit', tablePagination.getLimit().toString())

            formData.forEach((value, key) => (data[key] = value))
            startLoader('my-documents-loader')
            getResults(data)
                .then((res) => {
                    stopLoader('my-documents-loader')
                    const results = res.data.items
                    tablePagination.setPage(res.data.page)
                    tablePagination.setTotal(res.data.total)
                    generateResults(results)
                    resetAllCheckboxes()
                })
                .catch((err) => {
                    stopLoader('my-documents-loader')
                    console.log(err)
                })
        }

        sendForm()
    })
})(jQuery)
