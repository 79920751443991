import cellsFactory from '../cellsFactory/cellsFactory'
import { maxColumnsDesktop, setColumnsClasses, isMeasurementKey, changeColumnsOrder } from '../partials/columnsController'
import VARIANTS_TYPES from '../../config/variants-config'

export default class genericSubVariantsRow {
    constructor(props) {
        this.props = props
        this.isBatchPrice = !!props.subvariant.BatchPrice
        this.piecePrice = props.subvariant.Price ? this.props.subvariant.Price : null
        this.batchPrice = props.subvariant.BatchPrice ? this.props.subvariant.BatchPrice : null
        this.options = props.data.options
        this.observer = props.observer
        this.productName = props.data.productName
        this.cart = props.cart
        this.row = document.createElement('tr')
        this.updatePrice = this.options.isCustomerUniquePriceActive
        this.activeRow = null
    }

    publishActivateCart() {
        this.observer.publish('activateCart', {
            id: this.props.subvariant.id,
            price: this.isBatchPrice ? this.batchPrice : this.piecePrice,
            currencyCode: this.props.subvariant.currencyCode,
        })
    }

    render() {
        let columnsToRender = []
        this.row.className = `v-tr generic-subvariant-row ${this.props.isEven ? 'even' : 'odd'}`
        if (this.props.subvariant.id) {
            this.row.setAttribute('id', this.props.subvariant.id)
        }

        this.props.data.subColumns.map((subColumn) => {
            const cellValue = this.props.subvariant[subColumn.key]
            if (subColumn.key === 'Stock') {
                columnsToRender.push(
                    new cellsFactory(VARIANTS_TYPES.STOCK, {
                        key: subColumn.key,
                        variant: this.props.subvariant,
                        stockDisplay: this.options.stockDisplay,
                    }).render(),
                )
            } else if (subColumn.key === 'Price') {
                columnsToRender.push(
                    new cellsFactory(VARIANTS_TYPES.PRICE, {
                        key: subColumn.key,
                        variant: this.props.subvariant,
                        priceType: 'piece',
                        observer: this.observer,
                        updatePrice: this.updatePrice,
                    }).render(),
                )
            } else if (subColumn.key === 'BatchPrice') {
                columnsToRender.push(
                    new cellsFactory(VARIANTS_TYPES.PRICE, {
                        key: subColumn.key,
                        variant: this.props.subvariant,
                        priceType: 'batch',
                        observer: this.observer,
                        updatePrice: this.updatePrice,
                    }).render(),
                )
            } else {
                if (!isMeasurementKey(subColumn.key)) {
                    columnsToRender.push(new cellsFactory(VARIANTS_TYPES.DATA, { value: cellValue, key: subColumn.key }).render())
                }
            }
        })

        if (this.props.data.options.isBlueprint) {
            columnsToRender.push(
                new cellsFactory(VARIANTS_TYPES.BLUEPRINT, {
                    variant: this.props.subvariant,
                    columns: this.props.data.subColumns,
                    productName: this.productName,
                }).render(),
            )
        }
        if (this.props.data.options.isCadDownloadActive) {
            columnsToRender.push(
                new cellsFactory(VARIANTS_TYPES.CAD, {
                    variant: this.props.subvariant,
                    columns: this.props.data.subColumns,
                    observer: this.observer,
                    productName: this.productName,
                }).render(),
            )
        }

        const newColumnsOrder = changeColumnsOrder(columnsToRender)
        newColumnsOrder.forEach((item) => {
            this.row.appendChild(item)
        })

        setColumnsClasses(this.row)

        const fastBuyCell = new cellsFactory(VARIANTS_TYPES.FAST_BUY, { ...this.props, parent: this.row, exclude: true }).render()
        this.row.appendChild(fastBuyCell)

        const moreCell = new cellsFactory(VARIANTS_TYPES.MORE, { ...this.props, parent: this.row, exclude: true }).render()
        if (this.row.childNodes.length > maxColumnsDesktop) {
            moreCell.classList.remove('hide-on-desktop')
        }
        this.row.appendChild(moreCell)
        // this.row.addEventListener('click', (e) => this.handleClick(e))

        this.observer.subscribe({
            event: 'updatePrice',
            action: (data) => {
                const { variantId, freshPriceData, success } = data
                const targetVariant = () => {
                    return variantId === this.props.subvariant.id
                }
                if (targetVariant()) {
                    if (success) {
                        // update price
                        this.piecePrice = freshPriceData.Price
                        this.row.setAttribute('data-fresh-price', freshPriceData.Price)
                    }
                    if (this.activeRow && this.activeRow.id === variantId && this.activeRow.getAttribute('data-fresh-price') !== null) {
                        this.piecePrice = this.activeRow.getAttribute('data-fresh-price')
                        this.publishActivateCart()
                    }
                }
            },
        })

        return this.row
    }
}
