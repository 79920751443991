//
// My orders
//
import orderModuleConfig from './common/orderModuleLoader'
;(() => {
    $(() => {
        let module = $('.orders-container')

        // Only continue if on product view page
        if (!module.length) return

        window.Vue.component('pagination-block', window.VuejsPaginate)
        window.Vue.component('my-orders', orderModuleConfig)

        // eslint-disable-next-line no-undef
        new Vue({
            el: '#my-orders',
        })
    })
})()
