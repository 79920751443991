import './modal-block'
import axios from 'axios'

let reorderModal

if (window.Vue) {
    reorderModal = window.Vue.component('reorder-modal', {
        props: {
            translations: Object,
            orderId: Number,
        },
        data() {
            return {
                comment: '',
                requestInProgress: false,
            }
        },
        methods: {
            submitReorder() {
                this.requestInProgress = true
                axios
                    .post(`/shop/order/${this.orderId}/quote/information`, {
                        comment: this.comment,
                    })
                    .then(() => {
                        window.location.reload()
                    })
                    .catch((error) => {
                        console.error('Error while requesting reorder with comment', error)
                    })

                setTimeout(() => {
                    this.requestInProgress = false
                }, 1000)
            },
        },
        template: `
            <modal-block @close-modal="$emit('close-modal')" @modal-click-outside="$emit('close-modal')">
                <div class="modal-heading pt-6 pb-4">
                    <h3 class="text-primary font-weight-bold text-center fs-24">{{ translations["orders_history.reorder_modal_heading"] }}</h3>
                </div>
    
                <div class="modal-body px-3">
                    <form @submit.prevent="submitReorder">
                        <div class="modal-body-content">
                            <p class="mb-6">{{ translations["orders_history.reorder_modal_descr"] }}</p>
                            <div class="form-group">
                                <label class="form-control-label" for="reorder-comment">{{ translations["orders_history.reorder_modal_label"] }}</label>
                                <input v-model="comment" type="text" id="reorder-comment" name="reorder-comment" class="form-control">
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary" :disabled="requestInProgress">{{ translations["orders_history.reorder_modal_submit_button"] }}</button>
                    </form>
                </div>
            </modal-block>
        `,
    })
} else {
    reorderModal = null
}

export default reorderModal
