import { getPercentage } from '../helpers/helpers'

window.dataLayer = window.dataLayer || []

const onPlayerStateChange = (event) => {
    let state = event.data
    let eventLabel = ''
    const videoUrl = event.target.v.videoUrl
    const currentTime = event.target.getCurrentTime()
    const totalTime = event.target.getDuration()
    const percentage = Math.round(getPercentage(currentTime, totalTime))

    // Video Watched to the end:
    if (state === 0) {
        eventLabel = 'ENDED'
    }

    // Video Playing
    if (state === 1) {
        eventLabel = `PLAYING FROM ${percentage}%`
    }

    // Video Paused
    if (state === 2) {
        eventLabel = `PAUSED ON ${percentage}%`
    }

    if (state === 0 || state === 1 || state === 2) pushData(videoUrl, eventLabel)
}

const onPlayerError = (event) => {
    const videoUrl = event.target.playerInfo.videoUrl
    pushData(videoUrl, 'ERROR')
}

const pushData = (videoUrl, eventLabel) => {
    window.dataLayer.push({
        event: 'GA Tracking',
        eventCategory: 'Video',
        eventAction: videoUrl,
        eventLabel: eventLabel,
    })
}

export { onPlayerStateChange, onPlayerError }
