export default class Observer {
    constructor() {
        this.subscribers = []
    }

    subscribe(subscriber) {
        this.subscribers.push(subscriber)
    }

    unsubscribe(subscriber) {
        this.subscribers = this.subscribers.filter((item) => item !== subscriber)
    }

    publish(event, data) {
        this.subscribers.filter((subscriber) => subscriber.event === event).forEach((subscriber) => subscriber.action(data))
    }
}
