//
// Search
//
import { debounce } from 'debounce'
import { getResults } from '../api/search'
import { getUrlParams, updateUrlParam } from '../url-params/url-params'
import * as Blocks from './blocks/blocksFactory'
;(($) => {
    $(() => {
        let module = $('.header-search')

        // Only continue if module is on page
        if (!module.length) return

        const esInputWrapper = module.find('.es-search')
        const esInput = module.find('.es-form__input')
        const esButton = module.find('.es-form__button')
        const esClear = module.find('.es-form__clear')
        const esResultsWrapper = module.find('.es-form__results-row--matches-found')
        const esNoResultsWrapper = module.find('.es-form__results-row--no-matches')
        const esResults = module.find('.es-form__results')
        const esCloseResults = module.find('.es-form__results-close')
        const esForm = module.find('#es-form')
        const headerSearchToggle = $('.header-search-toggle')
        const mainNavigation = $('.main-navigation')
        const relevantsWrapper = module.find('.es-form__results-group--revelant')
        const categoriesWrapper = module.find('.es-form__results-group--categories')
        const productsWrapper = module.find('.es-form__results-group--products')
        const articlesWrapper = module.find('.es-form__results-group--articles')
        const otherCategoriesWrapper = module.find('.es-form__results-group--other-categories')
        const windowWidth = window.innerWidth
        const dataFromUrl = getUrlParams()
        const query = dataFromUrl.query || ''

        let hasResults = false
        let resultsArray = {
            relevants: { parent: relevantsWrapper, html: '' },
            categories: { parent: categoriesWrapper, html: '' },
            products: { parent: productsWrapper, html: '' },
            articles: { parent: articlesWrapper, html: '' },
            otherCategories: { parent: otherCategoriesWrapper, html: '' },
        }

        esInput
            .on('focus', () => {
                enableFocus()
            })
            .on('blur', () => {
                if (!esForm.hasClass('dirty')) {
                    disableFocus()
                }
            })
            .on('input', (e) => {
                hasResults = false
                const value = e.target.value
                const valueLength = value.length
                if (valueLength > 0) {
                    enableClearButton()
                    enableDirty()
                    getData()
                } else {
                    disableClearButton()
                    disableDirty()
                }
            })

        esButton.on('click', (e) => {
            e.preventDefault()
            submitForm()
        })

        esClear.on('click', () => {
            esForm[0].reset()
            clearForm()
        })

        esCloseResults.on('click', () => {
            disableResults()
            if (windowWidth < 768) {
                disableSearch()
            }
        })

        headerSearchToggle.on('click', () => {
            module.toggleClass('open')
            esResults.toggleClass('visible')
        })

        const clearResults = () => {
            Object.values(resultsArray).map((block) => {
                block.parent.html('')
                block.html = ''
            })
        }

        const clearForm = () => {
            hasResults = false
            disableClearButton()
            disableDirty()
            disableFocus()
        }

        const disableSearch = () => {
            module.removeClass('open')
        }

        const enableResults = () => {
            esResults.addClass('visible')
            mainNavigation.css('position', 'static')
        }

        const disableResults = () => {
            esResults.removeClass('visible')
            mainNavigation.css('position', 'relative')
            clearResults()
        }

        const enableClearButton = () => {
            esClear.addClass('visible')
        }

        const disableClearButton = () => {
            esClear.removeClass('visible')
        }

        const enableDirty = () => {
            esForm.addClass('dirty')
        }

        const disableDirty = () => {
            esForm.removeClass('dirty')
        }

        const enableFocus = () => {
            esForm.addClass('focused')
        }

        const disableFocus = () => {
            esForm.removeClass('focused')
        }

        const disableLoader = () => {
            esInputWrapper.removeClass('es-loader')
        }

        const enableLoader = () => {
            esInputWrapper.addClass('es-loader')
        }

        const setSearchInput = (value) => {
            if (value !== '') {
                esInput.val(value)
                enableClearButton()
            }
        }

        const submitForm = () => {
            const value = esInput.val()
            if (value && hasResults) {
                const href = $('.es-form__results-show-all').prop('href')
                if (!window.location.hash) {
                    window.location.href = href
                } else {
                    window.history.pushState(`{query: ${value}, tab: 'products'}`, '', href)
                    window.location.reload(true)
                }
            }
        }

        const toggleResultsWrapper = (hasResults) => {
            if (hasResults) {
                esResultsWrapper.show()
                esNoResultsWrapper.hide()
            } else {
                esResultsWrapper.hide()
                esNoResultsWrapper.show()
            }
        }

        const getData = debounce(() => {
            const value = esInput.val()
            if (value.length > 2) {
                enableLoader()
                getResults(value)
                    .then(({ data }) => {
                        if (data.data) {
                            clearResults()
                            enableResults()
                            disableLoader()
                            hasResults = data.results
                            toggleResultsWrapper(hasResults)
                            generateResultsHTML(data.data)
                        } else {
                            console.info('Something goes wrong. You probably need to reindex the data in the Elastic Search App')
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }, 500)

        const initEvents = () => {
            $('.es-form__results-show-all').on('click', (e) => {
                updateUrlParam('query', esInput.val())
                updateUrlParam('tab', e.target.dataset.tab)
                window.location.reload()
            })
            $('.es-form__results--revelant').on('click', '.es-form__results-link', (e) => {
                const phrase = e.target.innerText
                esInput.val(phrase).trigger('input')
            })
        }

        const generateResultsHTML = (data) => {
            Object.keys(data).map((key) => {
                const block = resultsArray[key]
                if (data[key].length || data[key].entries.length) {
                    block.html = Blocks[key].getHTML(data[key])
                    block.parent.html(block.html)
                }
            })
            initEvents()
        }

        setSearchInput(query)

        window.onpopstate = () => {
            window.location.reload(true)
        }
    })
})(jQuery)
