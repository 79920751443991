//
// Product view for slings
//
import scrollTo from '../../components/scrollTo/scrollTo'
import CartManager from '../../cart/cart-manager'
import { initVariantsTableSearch, initVariantsTableToggle } from '../../filter-common/filterExtended'
import axios from 'axios'
import { getFreshPrice } from '../../api/variants'
import { getDiscountProductPrice } from '../../api/variants'
;(($) => {
    $(() => {
        const module = $('.new-product-view.slings')
        // Only continue if on product view slings page
        if (!module.length) return

        const numberOfAttrs = 2
        const slingsForm = $('#slings-form')
        const slingsFormLoader = $('.slings-form-loader')
        const showAllVariantsLink = $('.npp__show-all-variants')
        const showContactUsTab = $('.npp__show-contact-us')
        const showMoreButton = $('.show-more__button')
        const increaseButton = $('.button-increase')
        const decreaseButton = $('.button-decrease')
        const quantityInput = $('.input-quantity')
        const firstSelect = $('.slings-select-1')
        const secondSelect = $('.slings-select-2')
        const isSecondSelect = secondSelect.length > 0
        const priceDiv = $('.npp__price')
        const stockDiv = $('.npp__stock--info')
        const partCodeDiv = $('.npp__title--heading2')
        const stockModal = $('#stockModal')
        const data = JSON.parse(slingsForm.attr('data-variants'))
        const variantsTab = document.querySelector('#variants')
        const contactUsTab = document.querySelector('#contact-us')
        const productInformationTab = document.querySelector('#product-information')
        const variants = data.variants
        const options = data.options
        const cartWidgetAttributes = options.cartWidgetAttributes.slice(0, numberOfAttrs) || ['WLL', 'Length']
        let parsedVariants = []
        let selectedVariant

        showMoreButton.click(() => goToAndOpenTab(productInformationTab))
        showAllVariantsLink.click(() => goToAndOpenTab(variantsTab))
        showContactUsTab.click(() => goToAndOpenTab(contactUsTab))

        const goToAndOpenTab = (target) => {
            if (target) {
                target.click()
                scrollTo(target, -160)
            }
        }

        quantityInput.on('change', (e) => {
            let currentQuantity = parseInt(e.currentTarget.value)
            if (currentQuantity <= 0 || isNaN(currentQuantity)) {
                quantityInput.val(1)
            }
            refreshPrice()
        })

        increaseButton.on('click', (e) => {
            incrementQuantity(e)
            refreshPrice()
        })

        decreaseButton.on('click', (e) => {
            decrementQuantity(e)
            refreshPrice()
        })

        slingsForm.on('submit', (e) => {
            e.preventDefault()
            if (options.showAddToCart) {
                sendGeneric(slingsForm)
            } else {
                sendQuote()
            }
        })

        const incrementQuantity = (e) => {
            e.preventDefault()
            let currentQuantity = parseInt(quantityInput.val(), 10)

            if (!isNaN(currentQuantity)) {
                quantityInput.val(currentQuantity + 1)
            } else {
                quantityInput.val(0)
            }
        }

        const decrementQuantity = (e) => {
            e.preventDefault()
            const currentQty = getQuantity()
            if (!isNaN(currentQty) && currentQty > 1) {
                quantityInput.val(currentQty - 1)
            } else {
                quantityInput.val(1)
            }
        }

        const getQuantity = () => parseInt(quantityInput.val(), 10)

        const getLocaleCode = () => $('html').attr('data-locale').replace('_', '-')

        const getPriceFormatted = (price) => {
            return new Intl.NumberFormat(getLocaleCode(), {
                style: 'currency',
                currency: selectedVariant.currencyCode,
            }).format(price)
        }

        const getIndexByKeys = (obj, primaryKey, secondaryKey) => {
            let idx = obj.findIndex((variant) => variant[primaryKey] && variant[secondaryKey])
            if (idx === -1) {
                idx = obj.findIndex((variant) => variant[primaryKey])
            }
            if (idx === -1) {
                idx = obj.findIndex((variant) => variant[secondaryKey] > 0)
            }
            if (idx === -1) {
                idx = 0
            }
            return idx
        }

        const setFirstAvailableVariant = (variants) => {
            let firstAvailableVariantIdx = getIndexByKeys(variants, 'Price', 'Stock')
            if (firstAvailableVariantIdx !== -1 && firstAvailableVariantIdx < variants.length) {
                variants[firstAvailableVariantIdx].selected = true
            } else {
                console.error('No valid variant found to set as selected')
            }
            return variants
        }

        const setSelectedVariant = () => {
            const firstValue = firstSelect.val()
            const firstName = firstSelect.attr('name')
            if (isSecondSelect) {
                const secondValue = secondSelect.val()
                const secondName = 'id'
                selectedVariant = variants.find((variant) => variant[firstName] === firstValue && variant[secondName] === parseInt(secondValue))
            } else {
                selectedVariant = variants.find((variant) => variant[firstName] === firstValue)
            }
        }

        const updateVariantsObject = (newData) => {
            variants.find((variant) => {
                if (variant === selectedVariant) {
                    Object.keys(newData).forEach((item) => {
                        variant[item] = newData[item]
                    })
                }
            })
        }

        const shouldDisplayDualPrices = (normalPrice, discountPrice) => {
            return (
                options.isCustomerUniquePriceActive &&
                options.displayDualPrices &&
                discountPrice !== undefined &&
                discountPrice !== '-' &&
                normalPrice !== String(discountPrice)
            )
        }

        const displayDualPrices = (normalPrice, discountPrice) => {
            const normalPriceElement = $('<span></span>')
                .addClass('price-text line-through')
                .css('opacity', '0.5')
                .css('margin-right', '10px')
                .html(getPriceFormatted(normalPrice))

            const discountPriceElement = $('<span></span>').addClass('fresh-price-text').html(getPriceFormatted(discountPrice))

            priceDiv.append(normalPriceElement)
            priceDiv.append(discountPriceElement)
        }

        const displayPrice = async (normalPrice) => {
            let priceToDisplay

            if (options.isCustomerUniquePriceActive) {
                try {
                    if (options.erpPrice) {
                        priceToDisplay = await fetchUniquePrice()
                    } else {
                        priceToDisplay = await fetchDiscountPrice()
                    }
                } catch (error) {
                    console.error('Error fetching price:', error)
                    priceToDisplay = normalPrice
                }
            }

            if (!priceToDisplay) {
                priceToDisplay = normalPrice
            }

            priceDiv.empty()

            if (normalPrice !== null) {
                if (shouldDisplayDualPrices(normalPrice, priceToDisplay)) {
                    displayDualPrices(normalPrice, priceToDisplay)
                } else {
                    displaySinglePrice(priceToDisplay === '-' ? normalPrice : priceToDisplay)
                }
            } else {
                priceDiv.empty()
            }
        }

        const displaySinglePrice = (priceToDisplay) => {
            if (priceToDisplay !== undefined) {
                const multiplication = getQuantity() * priceToDisplay
                priceDiv.html(getPriceFormatted(multiplication))
            } else {
                priceDiv.empty()
            }
        }

        const displayBatchPrice = (selectedVariant) => {
            priceDiv.html(selectedVariant.BatchPriceFormattedWithPacking ?? selectedVariant.PriceFormatted)
        }

        const fetchUniquePrice = () => {
            return new Promise((resolve, reject) => {
                slingsFormLoader.show()
                priceDiv.append('<div class="loader"></div>')
                getFreshPrice(selectedVariant.PartCode)
                    .then((res) => {
                        const freshPriceData = res.data
                        updateVariantsObject(freshPriceData)
                        resolve(freshPriceData.Price)
                    })
                    .catch((err) => {
                        console.error(err)
                        reject(err)
                    })
                    .finally(() => {
                        slingsFormLoader.hide()
                        selectedVariant.priceRefreshed = true
                        priceDiv.find('.loader').remove()
                    })
            })
        }

        const fetchDiscountPrice = () => {
            return new Promise((resolve, reject) => {
                slingsFormLoader.show()
                priceDiv.append('<div class="loader"></div>')
                getDiscountProductPrice(selectedVariant.id)
                    .then((res) => {
                        const freshPriceData = res.data
                        updateVariantsObject(freshPriceData)
                        resolve(freshPriceData.Price)
                    })
                    .catch((err) => {
                        console.error(err)
                        reject(err)
                    })
                    .finally(() => {
                        slingsFormLoader.hide()
                        selectedVariant.priceRefreshed = true
                        priceDiv.find('.loader').remove()
                    })
            })
        }

        const refreshPrice = async () => {
            setSelectedVariant()

            if (options.isBatchPrice) {
                await displayBatchPrice(selectedVariant)
                return
            }

            await displayPrice(selectedVariant.Price)
        }

        const getStockLevel = () => parseInt(selectedVariant.Stock)
        const getAvailabilityLabel = (stockLevel, showUnits = false) => {
            let stockText = stockLevel > 0 ? `${window.globals.trans('filter.in_stock')}` : `${window.globals.trans('filter.out_of_stock')}`
            if (options.stockDisplay === 'absolute' && stockLevel > 0) {
                stockText += ` ${stockLevel}${showUnits ? ` ${window.globals.trans('filter.pieces')}` : ''}`
            }
            return stockText
        }
        const getAvailabilityIndicatorClass = (stockLevel) => (stockLevel > 0 ? 'stock in' : 'stock out')

        const generateStockInfo = (stockLevel) =>
            `<span class="stock-text">${getAvailabilityLabel(stockLevel)}</span> <span class="${getAvailabilityIndicatorClass(stockLevel)}"></span>`
        const generateStockWarehouseInfo = (stockLevel) =>
            `<span class="${getAvailabilityIndicatorClass(stockLevel)}"></span> <span class="stock-text">${getAvailabilityLabel(stockLevel, true)}</span>`

        const refreshStock = () => {
            const stockLevel = getStockLevel()

            if (!isNaN(stockLevel)) {
                stockDiv.html(generateStockInfo(stockLevel))
            } else {
                stockDiv.html(
                    `<span class="stock-text"><a href="${slingsForm.attr('data-contact-url')}" class="ask" target="_blank">${window.globals.trans(
                        'filter.ask',
                    )}</a></span> <span class="stock no"></span>`,
                )
            }
        }
        const generateWarehousesTable = (warehouses) => {
            const tableBody = document.querySelector('#warehousesTable tbody')

            if (warehouses && warehouses.length > 0) {
                const populateTable = (warehouses) => {
                    tableBody.innerHTML = warehouses
                        .map(
                            ({ name, stock }) => `
                <tr>
                    <td class="stock-modal__name">${name}</td>
                    <td class="stock-modal__stock">${generateStockWarehouseInfo(stock)}</td>
                </tr>
            `,
                        )
                        .join('')
                }
                populateTable(warehouses)
            } else {
                tableBody.innerHTML = `<tr><td colspan="2" class="text-center font-italic">${window.globals.trans(
                    'product-page.no-warehouses-message',
                )}</td></tr>`
            }
        }

        const refreshStockModalData = () => {
            const partCode = selectedVariant.PartCode
            const warehouses = selectedVariant.warehouses
            stockModal.find('.stock-modal__partcode').html(`<span class="bold">${window.globals.trans('product-page.product-number')}</span>: ${partCode}`)
            generateWarehousesTable(warehouses)
        }

        const refreshPartCode = () => {
            const partCode = selectedVariant.PartCode
            partCodeDiv.text(partCode)
        }

        const refreshSelectric = (elem) => {
            elem.selectric('refresh')
        }

        const removeHTMLContent = (elem) => {
            elem.html('')
        }

        const generateOption = (value, label, isSelected) => {
            const optionElem = document.createElement('option')
            optionElem.setAttribute('value', value)
            if (isSelected) {
                optionElem.setAttribute('selected', true)
            }
            optionElem.innerText = label
            return optionElem
        }

        const checkVariantsData = (variants, cartWidgetAttributes) => {
            let checkedVariants = variants

            cartWidgetAttributes.forEach((attribute) => {
                variants.map((variant, key) => {
                    if (!variant[attribute]) {
                        checkedVariants[key][attribute] = `${window.globals.trans('product-page.not-specified')}`
                    }
                })
            })

            return checkedVariants
        }

        const parseVariants = (variants, cartWidgetAttributes) => {
            const firstAttr = cartWidgetAttributes[0]
            const secondAttr = cartWidgetAttributes[1]

            variants = setFirstAvailableVariant(variants)
            variants = checkVariantsData(variants, cartWidgetAttributes)

            const results = variants.reduce((groupedVariants, variant) => {
                let idx = groupedVariants.findIndex((item) => item[firstAttr] === variant[firstAttr])

                if (idx > -1) {
                    groupedVariants[idx].attributes.push({ id: variant.id, [secondAttr]: variant[secondAttr], selected: variant.selected || false })
                    if (variant.selected) {
                        variant.selected = true
                    }
                } else {
                    groupedVariants.push({
                        [firstAttr]: variant[firstAttr],
                        selected: variant.selected || false,
                        attributes: [{ id: variant.id, [secondAttr]: variant[secondAttr], selected: variant.selected || false }],
                    })
                }

                return groupedVariants
            }, [])

            if (isSecondSelect) {
                results.map((item) => {
                    sortObjectByKey(item.attributes, secondAttr)
                })
            }

            parsedVariants = results
        }

        const sortObjectByKey = (obj, key) => {
            obj.sort(function (a, b) {
                const valA = a[key].toString().toLowerCase(),
                    valB = b[key].toString().toLowerCase()
                if (valA < valB) return -1
                if (valA > valB) return 1
                return 0
            })
        }

        const generateFirstSelectOptions = (variants, attr) => {
            variants.forEach((item) => {
                firstSelect.append(generateOption(item[attr[0]], item[attr[0]], item.selected))
            })
        }

        const generateSecondSelectOptions = (variants, attr) => {
            const firstSelectValue = firstSelect.val()
            const results = variants.find((item) => item[attr[0]] === firstSelectValue).attributes
            results.forEach((item) => {
                secondSelect.append(generateOption(item.id, item[attr[1]], item.selected))
            })
        }

        const initSelectric = () => {
            firstSelect.selectric().on('change', function () {
                if (isSecondSelect) {
                    removeHTMLContent(secondSelect)
                    generateSecondSelectOptions(parsedVariants, cartWidgetAttributes)
                    refreshSelectric(secondSelect)
                }
                refreshPrice()
                refreshStock()
                refreshPartCode()
                refreshStockModalData()
            })
            if (isSecondSelect) {
                secondSelect.selectric().on('change', function () {
                    refreshPrice()
                    refreshStock()
                    refreshPartCode()
                    refreshStockModalData()
                })
            }
        }

        const init = () => {
            parseVariants(variants, cartWidgetAttributes)
            generateFirstSelectOptions(parsedVariants, cartWidgetAttributes)
            if (isSecondSelect) {
                generateSecondSelectOptions(parsedVariants, cartWidgetAttributes)
            }
            initSelectric()
            refreshPrice()
            refreshStock()
            refreshPartCode()
            refreshStockModalData()
            initVariantsTableSearch()
            initVariantsTableToggle()
        }

        const postData = (data) => {
            axios({
                method: 'post',
                url: slingsForm.attr('data-action-url'),
                data: data,
            })
                .then((response) => {
                    const data = response.data
                    CartManager.updateCoreshopCartCount(data)
                    if (window.dataLayer) {
                        window.dataLayer.push({
                            event: 'addToCart',
                            ecommerce: {
                                currencyCode: data.currencyCode || '',
                                add: {
                                    products: [
                                        {
                                            id: data.id.toString(),
                                            name: data.name,
                                            price: data.price || '0',
                                            category: data.category,
                                            quantity: parseInt(data.qty, 10),
                                        },
                                    ],
                                },
                            },
                        })
                    }
                })
                .catch((err) => {
                    throw err
                })
        }

        const sendGeneric = () => {
            const data = {
                quantity: getQuantity().toString(),
                product: selectedVariant.id.toString(),
            }
            postData(data)
        }

        const sendQuote = () => {
            const quantity = getQuantity().toString()
            const variantId = selectedVariant.id.toString()
            CartManager.addToCart(variantId, quantity)
        }

        if (variants.length > 0) init()
    })
})(jQuery)
