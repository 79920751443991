let DefaultOrderHeadingRowConfig = {
    props: {
        translations: Object,
    },
    template: `
        <div class="align-items-center heading-row d-none d-md-flex py-2">
            <div class="col-md-2 text-center">{{ translations["orders_history.heading.order_nr_title"] }}</div>
            <div class="col-md-2 col-xxl-1 text-center">{{ translations["orders_history.heading.date_title"] }}</div>
            <div class="col-md-2 text-center">{{ translations["orders_history.order_status_title"] }}</div> 
            <!-- <div class="d-none d-xxl-block col-xxl-1 text-center">{{ translations["orders_history.customer_reference_title"] }}</div> -->

            <div class="d-none d-xxl-block col-xxl-2 text-center">{{ translations["orders_history.heading.customer_po_title"] }}</div>
            <div class="col-2 text-center">
                <div>{{ translations["orders_history.heading.order_value_title"] }}</div>
                <div class="fs-12">{{ translations["orders_history.heading.excl_shipping_vat"] }}</div>
            </div>
            <div class="col-md-1 text-center">{{ translations["orders_history.heading.details_title"] }}</div>
            <div class="col-md-3 col-xxl-2 text-center">{{ translations["orders_history.reorder_title"] }}</div>
        </div>
    `,
}

export default DefaultOrderHeadingRowConfig
