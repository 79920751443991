export default class table {
    constructor(props) {
        this.props = props
        this.tableId = props.id || null
        this.tableClass = props.class || null
        this.table = document.createElement('table')
    }
    render() {
        if (this.tableId) {
            this.table.id = this.tableId
        }
        if (this.tableClass) {
            this.table.classList.add(this.tableClass)
        }
        this.table.classList.add('v-table')
        return this.table
    }
}
