import TabsAccordion from '../../tabs-accordion/tabs-accordion'
import scrollTo from '../../components/scrollTo/scrollTo'

new TabsAccordion('.tabs-accordion').init()

// npp__shortcut-bar RFQ Button
const initButtonRfq = () => {
    const rfqButtons = document.querySelectorAll('.npp__rfq')
    const handleClick = () => {
        const scrollTarget = document.getElementById('variants_table')
        scrollTo(scrollTarget, -150)
    }
    rfqButtons.forEach((button) => {
        button.addEventListener('click', handleClick)
    })
}

initButtonRfq()
