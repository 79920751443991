//
// Orders history
//

;(($) => {
    $(() => {
        let module = $('.orders-container-web-shop')

        // Only continue if on product view page
        if (!module.length) return

        module.find('.toggle-details-web-orders').on('click', (e) => {
            $(e.currentTarget).attr('aria-expanded', $(e.currentTarget).attr('aria-expanded') !== 'true')
            $(e.currentTarget).parent().siblings('div.order-items-details-container').toggleClass('show')
            $(e.currentTarget).parents('div.order-row').toggleClass('collapsed')
        })
    })
})(jQuery)
