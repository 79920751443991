//
// Area - contact form
//

;(($) => {
    let module = $('.area-contact-form')

    // Only continue if module is on page
    if (!module.length) return

    // Recaptcha
    module.find("form[name*='contact_brick_form']").on('submit', function (e) {
        e.preventDefault()
        let widgetId = $(this).attr('data-g-widget') ? $(this).attr('data-g-widget') : false

        if (widgetId) {
            window.grecaptcha.reset(widgetId)
            window.grecaptcha.execute(widgetId)
        } else {
            window.grecaptcha.reset()
            window.grecaptcha.execute()
        }
    })

    //scroll to brick if after submitting
    if (localStorage.getItem('submitted_id') !== null) {
        const submittedElement = $('#' + localStorage.getItem('submitted_id'))

        if (submittedElement.length) {
            const pos = submittedElement.offset().top - $(window).height() / 2 + $('header.site-header').height() * 2
            $(document).scrollTop(pos)
            localStorage.removeItem('submitted_id')
        }
    }

    //save which brick was submitted
    module.find('form :submit').on('click', function () {
        localStorage.setItem('submitted_id', $(this).closest('.area-contact-form').attr('id'))
    })
})(jQuery)
