import ltTranslations from './vuejs-datepicker-lt-translations'

let searchBar

if (window.Vue) {
    const picker = window.vuejsDatepicker
    searchBar = window.Vue.component('search-bar', {
        components: {
            picker,
        },
        props: {
            translations: Object,
            currentSearchQuery: String,
            currentDateFrom: [String, Object],
            currentDateTo: [String, Object],
        },
        data() {
            return {
                isSearchAllowed: true,
                query: '',
                dateFrom: '',
                dateTo: '',
                ltTranslations: null,
            }
        },
        methods: {
            emitSearch() {
                if (this.isSearchAllowed) {
                    this.isSearchAllowed = false
                    this.$emit('query-search', this.query, this.formatDate(this.dateFrom), this.formatDate(this.dateTo))
                    setTimeout(() => {
                        this.isSearchAllowed = true
                    }, 2000)
                }
            },
            formatDate(date) {
                if (date) {
                    let dd = date.getDate()
                    let mm = date.getMonth() + 1
                    let yyyy = date.getFullYear()

                    if (dd < 10) {
                        dd = '0' + dd
                    }
                    if (mm < 10) {
                        mm = '0' + mm
                    }
                    return `${dd}-${mm}-${yyyy}`
                } else {
                    return ''
                }
            },
            clearFilters() {
                this.query = ''
                this.dateFrom = ''
                this.dateTo = ''
                if (this.currentSearchQuery || this.currentDateFrom || this.currentDateTo) {
                    this.$emit('query-search', this.query, this.formatDate(this.dateFrom), this.formatDate(this.dateTo))
                }
            },
        },
        created() {
            if (document.getElementsByTagName('html')[0].getAttribute('lang') === 'lt') {
                this.ltTranslations = ltTranslations
            }
        },
        template: `
            <form @submit.prevent="emitSearch" class="orders-search-bar">
                <div class="d-flex flex-wrap">
                    <input v-model="query" type="text" :placeholder="translations['orders_history.search_bar_input_placeholder']" id="search-query" name="search-query" class="form-control query my-2 mr-2 ml-2 ml-md-0">
                    <div class="d-flex flex-wrap">
                        <vuejs-datepicker
                            v-model="dateFrom"
                            :placeholder="translations['orders_history.search_bar_date_from']"
                            wrapper-class="position-relative m-2"
                            input-class="datepicker"
                            :clear-button="true"
                            :monday-first="true"
                            :language="ltTranslations || undefined"
                        />
                        <vuejs-datepicker
                            v-model="dateTo"
                            :placeholder="translations['orders_history.search_bar_date_to']"
                            wrapper-class="position-relative m-2"
                            input-class="datepicker"
                            :clear-button="true"
                            :monday-first="true"
                            :language="ltTranslations || undefined"
                        />
                    </div>
                    <button type="submit" class="btn btn-primary my-2 ml-2" :disabled="!isSearchAllowed">{{ translations["orders_history.search_bar_button"] }}</button>
                    <button
                        type="button"
                        class="btn refresh-history text-primary d-flex align-items-center icon-refresh my-2 ml-2"
                        @click="clearFilters"
                    >
                        <span>{{ translations["orders_history.clear"] }}</span>
                    </button>
                </div>
            </form>
        `,
    })
} else {
    searchBar = null
}

export default searchBar
