import { FilterColumn } from './components/FilterColumn'
import Util from '../utils/utils'
;(($) => {
    let module = $('.new-product-view #filters')
    let filter = $('.filter')
    let filterToggleButtonContainer = $('.toggle-button-container')
    let filterToggleButton = $('.toggle-filter-button')
    let filterCloseButton = $('.filter-categories-close-button')
    let isSlingsPage = $('.new-product-view.slings').length > 0

    // Only continue if module is on page
    if (!module.length) return

    if (isSlingsPage) {
        if ($('.slings .tabs-accordion #variants').hasClass('is-active')) {
            new FilterColumn().initialize()
        } else {
            const selector =
                Util.isMobile()
                    ? '.slings .tabs-accordion #variants-tab'
                    : '.slings .tabs-accordion #variants'
            $(selector).one('click', () => new FilterColumn().initialize())
        }
    } else {
        new FilterColumn().initialize()
    }

    const toggleFilter = () => toggleMobileFilters();

    filterToggleButton.on('click', toggleFilter);
    filterCloseButton.on('click', toggleFilter);

    const toggleMobileFilters = () => {
        filterToggleButtonContainer.toggleClass('as-apply')
        filter.toggleClass('visible')
    }
})(jQuery)
