//
// Collapse
//

;(($) => {
    $(() => {
        const module = $('button.toggle-collapse')

        // Only continue if module is on page
        if (!module.length) return

        module.on('click', (e) => {
            $(e.currentTarget)
                .toggleClass('collapsed')
                .attr('aria-expanded', $(e.currentTarget).attr('aria-expanded') !== 'true')
            $(e.currentTarget).siblings('.collapse-content').toggleClass('show')
        })
    })
})(jQuery)
