import { getFilters, postFilters } from '../../api/search-results'

import ElementsFactory from '../components/factories/ElementsFactory'
import { getUrlParams } from '../../url-params/url-params'
import { getDataUsingUrlParams, setUrlParamsFromInitData } from '../../filter-common/urlDataParser'
import FilterResults from '../components/FilterResults'
import { stopLoader } from '../../filter-common/filterLoaders'

class FilterColumn {
    initialize() {
        this.module = document.getElementById('filters')
        if (!this.module) return
        this.loadInitData()
    }

    loadInitData() {
        getFilters()
            .then((data) => {
                const filtersData = data.data.data.filters
                this.initFiltersData = filtersData
                const typesData = data.data.data.filters.types || []

                let generateUrlParams = location.href.indexOf('#') == -1
                if (generateUrlParams && filtersData !== [] && typesData.length > 0) {
                    this.generateFilterPreselectParam(filtersData)
                }

                this.loadProducts()
            })
            .catch((err) => {
                stopLoader('results-loader')
                console.log(err)
            })
    }

    getCleanFilterData() {
        let result = this.initFiltersData
        result.types.forEach((filter) => {
            filter.fields.forEach((field) => {
                field.checked = false
                field.initial = null
            })
        })
        return result
    }

    loadProducts() {
        const dataFromUrl = getUrlParams()
        const dataFromUrlParsed = getDataUsingUrlParams(dataFromUrl)
        postFilters(dataFromUrlParsed)
            .then((data) => {
                stopLoader('results-loader')
                const resData = data.data.data
                const filtersData = this.getCleanFilterData()
                const typesData = filtersData.types || []
                const productsData = resData.products
                const hideBulletPoints = resData.hideBulletPoints
                const isLastPage = resData.isLastPage
                const totalCount = resData.total || 0

                Object.keys(dataFromUrlParsed).map((item) => {
                    const groupType = getGroupType(dataFromUrlParsed[item])

                    if (groupType === 'CheckboxGroup') {
                        const itemName = item.replace('[]', '')
                        const itemValues = dataFromUrlParsed[item]
                        const groupToUpdate = getGroup(typesData, itemName)
                        if (groupToUpdate.length > 0) {
                            const fields = groupToUpdate[0].fields
                            groupToUpdate[0].collapsed = false

                            itemValues.map((value) => {
                                fields.map((field) => {
                                    if (field.id === value) {
                                        field.checked = true
                                    }
                                })
                            })
                        }
                    } else if (groupType === 'RadioGroup') {
                        const itemName = item
                        const itemValue = dataFromUrlParsed[item] === '1' ? 1 : 0

                        const groupToUpdate = getGroup(typesData, itemName)
                        if (groupToUpdate.length > 0) {
                            const fields = groupToUpdate[0].fields
                            groupToUpdate[0].collapsed = false

                            fields.map((field) => {
                                if (field.value === itemValue) {
                                    field.checked = true
                                }
                            })
                        }
                    } else if (groupType === 'RangeGroup') {
                        const itemName = item.replace('[]', '')
                        const rangeMinValue = dataFromUrlParsed[item].from
                        const rangeMaxValue = dataFromUrlParsed[item].to

                        const groupToUpdate = getGroup(typesData, itemName)
                        if (groupToUpdate.length > 0) {
                            const fields = groupToUpdate[0].fields
                            groupToUpdate[0].collapsed = false
                            if (fields.length > 0) {
                                fields[0].initial = rangeMinValue
                                fields[1].initial = rangeMaxValue
                            }
                        }
                    }
                })

                function getGroup(typesData, itemName) {
                    return typesData.filter((group) => {
                        return group.name === itemName
                    })
                }

                function getGroupType(field) {
                    if (typeof field === 'object') {
                        if (Array.isArray(field)) {
                            return 'CheckboxGroup'
                        } else {
                            return 'RangeGroup'
                        }
                    } else if (typeof field === 'string') {
                        return 'RadioGroup'
                    }
                }

                if (filtersData !== []) {
                    if (typesData.length > 0) {
                        this.prepareFilterLayout()
                        this.generateFilterColumn(filtersData)
                    }
                }

                new FilterResults(hideBulletPoints).render(productsData, totalCount, isLastPage)
            })
            .catch((error) => {
                stopLoader('results-loader')
                console.log(error)
            })
    }

    prepareFilterLayout() {
        const filterHeader = document.getElementsByClassName('filter-header')[0]
        const filterScroll = document.getElementsByClassName('scroll-disabled')[0]
        filterHeader.classList.add('show')
        filterScroll.classList.remove('scroll-disabled')
    }

    generateFilterColumn(filtersData) {
        filtersData.types
            .map((type) => {
                return ElementsFactory.createInstance(type)
            })
            .forEach((filter) => {
                if (filter) {
                    this.module.appendChild(filter.render())
                }
            })
    }

    generateFilterPreselectParam(filtersData) {
        filtersData.types.forEach((filter) => {
            setUrlParamsFromInitData(filter)
        })
    }
}

export { FilterColumn }
