//
// Show more
//

;(($) => {
    $(() => {
        const module = $('.show-more')

        // Only continue if module is on page
        if (!module.length) return

        const maxHeight = 40
        const trigger = module.find('.show-more__trigger')
        const listener = module.find('.show-more__listener')
        const button = trigger.find('.show-more__button')
        const listenerHeight = listener.outerHeight()

        if (listenerHeight > maxHeight) {
            listener.addClass('const-height')
            trigger.css('display', 'inline-block')
            trigger.on('click', (e) => {
                const parent = $(e.currentTarget.parentElement)
                parent.toggleClass('show-more--expanded')
                if (parent.hasClass('show-more--expanded')) {
                    button.text(`${window.globals.trans('filter.show_less')}`)
                } else {
                    button.text(`${window.globals.trans('filter.show_more')}`)
                }
            })
        } else {
            listener.removeClass('const-height')
            module.addClass('show-more--expanded')
        }
    })
})(jQuery)
