import axios from 'axios'
let reorderButton

if (window.Vue) {
    reorderButton = window.Vue.component('reorder-button', {
        props: {
            translations: Object,
            label: String,
            reorderPossible: Boolean,
            reorderAllowed: Boolean,
            orderId: Number,
        },
        data() {
            return {
                isButtonDisabled: false,
            }
        },
        methods: {
            reorderIfPossible() {
                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'GA Tracking',
                        eventCategory: 'Ecommerce',
                        eventAction: 'click',
                        eventLabel: 'reorder',
                        eventValue: '',
                        eventNonInteraction: false,
                    })
                }
                this.isButtonDisabled = true
                if (this.reorderPossible) {
                    const pageLang = document.getElementsByTagName('html')[0].getAttribute('lang')

                    axios
                        .get(`/${pageLang}/shop/order/reorder/${this.orderId}`)
                        .then((response) => {
                            window.location.href = response.data.redirect_url
                        })
                        .catch((error) => {
                            console.error('Error while requesting simple reorder', error)
                        })
                } else {
                    this.$emit('reorder-comment-modal', this.orderId)
                }

                // should execute only in case of request error
                setTimeout(() => {
                    this.isButtonDisabled = false
                }, 5000)
            },
        },
        computed: {
            isDisabled() {
                return this.isButtonDisabled || !this.reorderAllowed
            },
        },
        template: `
            <button class="btn btn-primary" @click="reorderIfPossible" :disabled="isDisabled">{{ translations['orders_history.reorder_button'] }}</button>
        `,
    })
} else {
    reorderButton = null
}

export default reorderButton
