const quantityInput = (element, options, observer) => {
    const spinner = element.querySelector('.quantity')
    const isFastBuyForm = element.parentElement.classList.contains('fast-buy-cell')
    const defaultOptions = {
        min: 1,
        max: 99999999,
        value: 1,
    }

    options = Object.assign({}, defaultOptions, options)

    const obj = {
        observer: observer,
        input: spinner.querySelector('input[type="number"]'),
        isDisabled: !isFastBuyForm,
        init() {
            this.setup()
            this.events()
            this.initObservers()
            return this
        },
        setup() {
            this.input.value = options.value
            this.max = options.max
            this.min = options.min

            const qUp = document.createElement('div')
            const qDown = document.createElement('div')

            qUp.setAttribute('class', 'quantity-button quantity-button--up')
            qDown.setAttribute('class', 'quantity-button quantity-button--down')

            qUp.innerHTML = '<i class="icon icon-plus"></i>'
            qDown.innerHTML = '<i class="icon icon-minus"></i>'
            spinner.insertBefore(qDown, this.input)
            spinner.appendChild(qUp)

            this.btnUp = spinner.querySelector('.quantity-button--up')
            this.btnDown = spinner.querySelector('.quantity-button--down')
        },
        publishQuantity() {
            this.observer.publish('cartQuantity', {
                quantity: this.input.value,
                isFastBuyForm: isFastBuyForm,
            })
        },
        trigger() {
            const event = document.createEvent('HTMLEvents')
            event.initEvent('change', true, false)
            this.publishQuantity()
            return event
        },
        events() {
            this.btnUp.addEventListener('click', (e) => {
                e.stopPropagation()
                if (!this.isDisabled) {
                    const oldValue = parseFloat(this.input.value)
                    let newVal
                    if (oldValue >= this.max) {
                        newVal = oldValue
                    } else {
                        newVal = oldValue + 1
                    }
                    this.input.value = newVal
                    this.input.dispatchEvent(this.trigger())
                }
            })

            this.btnDown.addEventListener('click', (e) => {
                e.stopPropagation()
                if (!this.isDisabled) {
                    const oldValue = parseFloat(this.input.value)
                    let newVal
                    if (oldValue <= this.min) {
                        newVal = oldValue
                    } else {
                        newVal = oldValue - 1
                    }
                    this.input.value = newVal
                    this.input.dispatchEvent(this.trigger())
                }
            })

            this.input.addEventListener('click', (e) => e.stopPropagation())
            this.input.addEventListener('change', () => {
                if (isNaN(parseInt(this.input.value, 16))) {
                    this.input.value = this.min
                }
                if (parseInt(this.input.value, 16) < this.min) {
                    this.input.value = this.min
                }
                if (parseInt(this.input.value, 16) > this.max) {
                    this.input.value = this.max
                }
                this.publishQuantity()
            })
        },
        initObservers() {
            this.observer.subscribe({
                event: 'disableQuantityField',
                action: (state) => {
                    if (!isFastBuyForm) {
                        this.isDisabled = state
                        this.input.disabled = state
                    }
                },
            })
        },
    }
    return obj.init()
}

export default quantityInput
