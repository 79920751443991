let DefaultOrderItemConfig = {
    props: {
        translations: Object,
        itemData: Object,
        isCustomCr: Boolean,
    },
    template: `
            <div class="d-flex flex-column flex-md-row align-items-center py-4">
                <div class="col-lg-5 mb-2 mb-md-0 py-4 py-md-0 product-image-main-info">
                    <div class="d-flex">
                        <div class="image-wrapper">
                            <img :src="itemData.imageUrl ? itemData.imageUrl : '/static/images/item-placeholder.svg'" :alt="itemData.name">
                        </div>
                        <div class="product-main-info ml-4">
                            <a v-if="itemData.productUrl" :href="itemData.productUrl" class="d-block font-weight-bold text-primary">{{ itemData.name }}</a>
                            <div v-else class="font-weight-bold text-primary">{{ itemData.name }}</div>
                            <div v-if="itemData.description2" class="my-1">{{ itemData.description2 }}</div>
                            <div class="d-flex flex-wrap item-attributes fs-12">
                                <div v-for="attr in itemData.attributes" class="mr-2">
                                    {{ attr.label }}
                                    <span class="item-attribute-value">{{ attr.value }}</span>
                                </div>
                            </div>
                            <div v-if="itemData.swrAttributes && itemData.swrAttributes.length" class="d-flex flex-column flex-wrap item-attributes rope-attributes fs-12 mt-4">
                                <div class="mr-2">
                                    <div v-for="swrAttr in itemData.swrAttributes">
                                        <span class="attribute">{{ swrAttr.label }}</span>
                                        <span class="item-attribute-value">{{ swrAttr.value }}</span>
                                    </div>
                                </div>
                            </div>
    
                            <div v-if="itemData.partCode" class="mt-1">{{ translations["orders_history.item.partcode_number_prefix"] }} {{ itemData.partCode }}</div>
                            <div v-if="itemData.comment" class="mt-4">
                                <div class="mb-2 font-weight-bold text-primary">{{ translations["orders_history.row.comment"] }}</div>
                                <div class="fs-12">{{ itemData.comment }}</div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="d-md-none d-lg-block col-md-1 text-md-center py-1 py-md-0">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.row.quantity_title"] }}: </span>
                    {{ itemData.quantity }}
                </div>
                <div class="d-md-none d-lg-block text-md-center py-1 py-md-0" :class="{'col-md-2': !isCustomCr, 'col-md-3': isCustomCr}">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.row.net_value_title"] }}: </span>
                    <span class="price-value">
                        {{ itemData.netPrice }}
                    </span>
                </div>
    
                <div class="d-md-none d-lg-block col-md-2 text-md-center py-1 py-md-0 discount" v-if="!isCustomCr">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.row.discount_value_title"] }}: </span>
                    <span class="price-value text-flame-pea">{{ itemData.discount || '-'}}</span>
                </div>
    
                <div class="d-md-none d-lg-block text-md-center py-1 py-md-0" :class="{'col-md-2': !isCustomCr, 'col-md-3': isCustomCr}">
                    <span class="mobile-label d-md-none w-50">{{ translations["orders_history.row.subtotal_value_title"] }}: </span>
                    <span class="price-value">{{ itemData.grossTotalValue }}</span>
                </div>
            </div>
        `,
}

export default DefaultOrderItemConfig
