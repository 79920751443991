import axios from 'axios'

const devMode = false

const endings = ['http://localhost:3333/mock/api/search-no-results', 'http://localhost:3333/mock/api/search']
const generateURL = () => {
    return endings[Math.floor(Math.random() * endings.length)]
}

axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
}

const PROTOCOL = window.location.protocol
const API_URL = `search/global`
const LOCALE = document.documentElement.lang || 'en'
let FULL_PATH = `/${LOCALE}/${API_URL}`
axios.defaults.baseURL = `${PROTOCOL}//${window.location.hostname}`

if (devMode) {
    FULL_PATH = generateURL()
}

const getResults = async (query) => {
    return await axios.get(FULL_PATH, {
        params: Object.assign({
            query,
        }),
    })
}

export { getResults }
