import { initCadModalForm } from '../partials/cadModal'
import VARIANTS_TYPES from '../../config/variants-config'
import { onCadDownloadClick } from '../../../../gtm/cad'

export default class cadCell {
    constructor(props) {
        this.cell = document.createElement('td')
        this.props = props
        this.observer = props.observer
        this.partNo = props.variant.id
        this.partCode = props.variant.PartCode
        this.manufacturePartCode = props.variant.manufacturePartCode
        this.$cadModal = $('#cadModal')
    }

    handleClick(e) {
        e.stopPropagation()
        const $modalTitle = this.$cadModal.find('.modal-title')
        $modalTitle.html(`${window.globals.trans('filter.download_cad')} - ${this.partCode}`)
        initCadModalForm(e, this.partNo, this.partCode)
        onCadDownloadClick(this.partCode, 'cad_view')
        this.$cadModal.modal()
    }
    render() {
        this.cell.setAttribute('class', 'v-td cad-cell count hide-everywhere')
        this.cell.setAttribute('data-key', VARIANTS_TYPES.CAD)
        if (this.manufacturePartCode) {
            let code = escape(this.manufacturePartCode).replace(/%A0/g, '')
            this.cell.setAttribute('data-manufacture', code)
        }
        const element = document.createElement('span')
        const loader = document.createElement('span')
        element.setAttribute('class', 'cad-button icon-cad hide')
        element.addEventListener('click', (e) => this.handleClick(e))
        loader.classList.add('cad-loader')
        this.cell.appendChild(element)
        this.cell.appendChild(loader)
        return this.cell
    }
}
