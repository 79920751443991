import VARIANTS_TYPES from '../../config/variants-config'

export default class headerCell {
    constructor(props) {
        this.cell = document.createElement('th')
        this.props = props
        // eslint-disable-next-line no-prototype-builtins
        this.excludeFromMoreDetails = props.hasOwnProperty('exclude')
    }
    render() {
        this.cell.setAttribute('class', `v-th header-cell ${this.excludeFromMoreDetails ? '' : 'count'}`)
        if (this.props.key) {
            this.cell.setAttribute('data-key', this.props.key)
            if (this.props.key === VARIANTS_TYPES.CAD) {
                this.cell.classList.add('hide-everywhere')
            }
        }
        this.cell.innerHTML = this.props.label || ''
        return this.cell
    }
}
