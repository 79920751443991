export default class selectCell {
    constructor(props) {
        this.cell = document.createElement('td')
        this.props = props
        this.value = props.value
        this.key = props.key
    }

    render() {
        this.cell.setAttribute('class', 'v-td select-cell count')
        this.cell.setAttribute('data-key', this.key)
        this.cell.appendChild(this.value)
        return this.cell
    }
}
