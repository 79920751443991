//
// Area - application areas
//

;(($) => {
    $(() => {
        let module = $('.npp__application-areas')

        // Only continue if module is on page
        if (!module.length) return

        let applicationAreasCarousel = module.find('.owl-carousel')

        // Call owl carousel on image carousels
        applicationAreasCarousel.each(function () {
            $(this).owlCarousel({
                items: 10,
                loop: false,
                nav: true,
                navText: [
                    '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#previous"></use></svg>',
                    '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#next"></use></svg>',
                ],
                animateOut: 'fadeOut',
                animateIn: 'fadeIn',
                responsive: {
                    0: {
                        items: 2,
                    },
                    350: {
                        items: 3,
                    },
                    450: {
                        items: 4,
                    },
                    550: {
                        items: 6,
                    },
                    650: {
                        items: 7,
                    },
                    1200: {
                        items: 10,
                    },
                },
            })
        })
    })
})(jQuery)
