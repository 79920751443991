import axios from 'axios'

const devMode = false

axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
}

const PROTOCOL = window.location.protocol
const API_URL = `catalog-data`
const LOCALE = document.documentElement.getAttribute('data-locale') || 'en_GB'
let FULL_PATH = `/${LOCALE}/${API_URL}`
let INIT_FILTERS_FULL_PATH = `/${LOCALE}/${API_URL}/initial-filters`
axios.defaults.baseURL = `${PROTOCOL}//${window.location.hostname}`

if (devMode) {
    FULL_PATH = 'http://localhost:3333/mock/api/catalog-data'
    INIT_FILTERS_FULL_PATH = 'http://localhost:3333/mock/api/catalog-data/initial-filters'
}

const getCategoryId = () => {
    return $('.new-listing').data('category-id')
}

const getFilters = async () => {
    const categoryId = getCategoryId()
    return await axios.get(INIT_FILTERS_FULL_PATH, {
        params: {
            categoryId,
        },
    })
}

const getPage = async (page, filterData) => {
    const categoryId = getCategoryId()
    return await axios.get(FULL_PATH, {
        params: {
            categoryId,
            pageNo: page,
            ...filterData,
        },
    })
}

const postFilters = async (data) => {
    if (data.pageNo) {
        delete data.pageNo
    }
    const categoryId = getCategoryId()
    return await axios.get(FULL_PATH, {
        params: Object.assign(
            {
                categoryId,
            },
            data,
        ),
    })
}

export { getFilters, getPage, postFilters }
