const VARIANTS_TYPES = {
    SUBVARIANTS: 'subvariants',
    GENERIC_VARIANTS: 'generic-variants',
    GENERIC_SUBVARIANTS: 'generic-subvariants',
    GENERIC_VARIANTS_SWR: 'generic-variants-swr',
    SWR: 'swr',
    SUBVARIANTS_PARENT: 'subvariants-parent',
    SUBVARIANTS_CHILD: 'subvariants-child',
    SWR_PARENT: 'swr-parent',
    SWR_CHILD: 'swr-child',
    GENERIC_SWR: 'generic-swr',
    MORE_DETAILS: 'more-details',
    MORE_DETAILS_SWR: 'more-details-swr',
    COLUMNS_HEADER: 'columns-header',
    SUBCOLUMNS_HEADER: 'subcolumns-header',
    EMPTY: 'empty',
    RADIO: 'radio',
    BLUEPRINT: 'blueprint',
    CAD: 'cad',
    DATA: 'data',
    SELECT: 'select',
    HEADER: 'header',
    STOCK: 'stock',
    PRICE: 'price',
    ARROW: 'arrow',
    MORE: 'more',
    FAST_BUY: 'fast-buy',
    NO_PRICE: 'no-price',
    DEFAULT: 'default',
    BUY: 'buy',
    QUOTE: 'quote',
    CONFIGURE: 'configure',
}

export default VARIANTS_TYPES
