import { onVideoStateChange } from '../../gtm/video-html5'
import { addMultipleEventListener } from '../../helpers/helpers'
//
// Hero video
//
import YouTubePlayer from 'youtube-player'
import { onPlayerError, onPlayerStateChange } from '../../gtm/youtube'
;(($) => {
    $(() => {
        let modules = document.querySelectorAll('.hero-video-element')

        // Only continue if module is on page
        if (!modules.length) return

        window.dataLayer = window.dataLayer || []

        modules.forEach((videoElement) => {
            const youtubeVideo = videoElement.querySelector('.hero-video-youtube')
            const assetVideo = videoElement.querySelector('.hero-video-asset')
            if (youtubeVideo) {
                const ytVideoID = youtubeVideo.getAttribute('data-video-id')
                const placeholderID = youtubeVideo.id
                const autoplay = Boolean(youtubeVideo.getAttribute('data-autoplay'))
                const loop = Boolean(youtubeVideo.getAttribute('data-loop'))
                const mute = Boolean(youtubeVideo.getAttribute('data-mute'))

                const player = YouTubePlayer(placeholderID, {
                    width: null,
                    height: null,
                    videoId: ytVideoID,
                    playerVars: { autoplay: +autoplay, loop: +loop },
                })

                if (mute) {
                    player.mute()
                } else {
                    player.setVolume(30)
                    player.unMute()
                }
                player.on('stateChange', (event) => {
                    onPlayerStateChange(event)
                })
                player.on('onError', (event) => {
                    onPlayerError(event)
                })
            } else if (assetVideo) {
                const video = assetVideo.querySelector('video')
                if (!video) return
                video.addEventListener(
                    'loadeddata',
                    () => {
                        const duration = video.duration % 60
                        const filename = decodeURIComponent(video.currentSrc.match(/([^/]+)(?=\.\w+$)/)[0])
                        addMultipleEventListener(video, 'loadstart waiting play playing ended pause error', (e) => onVideoStateChange(e, duration, filename))
                    },
                    false,
                )
            }
        })
    })
})(jQuery)
