import Util from '../../utils/utils'

//
// Area - Testimonials
//
;(($) => {
    $(() => {
        let module = $('.area-testimonials')

        // Only continue if module is on page
        if (!module.length) return

        let testimonialCarousel = module.find('.owl-carousel')

        // Call owl carousel on image carousels
        testimonialCarousel.each(function () {
            $(this).owlCarousel({
                items: 1,
                loop: !Util.isEditmode(),
                nav: true,
                navText: [
                    '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#previous"></use></svg>',
                    '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#next"></use></svg>',
                ],
                animateOut: 'fadeOut',
                animateIn: 'fadeIn',
                mouseDrag: false,
                thumbs: true,
                thumbsPrerendered: true,
            })
        })
    })
})(jQuery)
