let itemDetailsTablet

if (window.Vue) {
    itemDetailsTablet = window.Vue.component('item-details-tablet', {
        props: {
            translations: Object,
            quantity: String,
            netPrice: String,
            discount: String,
            grossPrice: String,
        },
        template: `
            <div class="row py-4">
                <div class="col">
                    {{ quantity }}
                </div>
                <div class="col">
                    <span class="price-value">
                        {{ netPrice }}
                    </span>
                </div>
                <div class="col">
                    <span class="price-value text-flame-pea">
                        {{ discount }}
                    </span>
                </div>
                <div class="col">
                    <span class="price-value">
                        {{ grossPrice }}
                    </span>
                </div>
            </div>
        `,
    })
} else {
    itemDetailsTablet = null
}

export default itemDetailsTablet
