//
// IE Message
//

import whichBrowser from '../browser/browser'
;(($) => {
    $(() => {
        let module = $('.ie-message')

        // Only continue if module is on page
        if (!module.length) return

        $(document).ready(function () {
            const browser = whichBrowser()
            if (browser === 'Internet Explorer') {
                module.show()
            }
        })
    })
})(jQuery)
