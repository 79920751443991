import cellsFactory from '../cellsFactory/cellsFactory'
import headersFactory from '../headersFactory/headersFactory'
import bodiesFactory from '../bodiesFactory/bodiesFactory'
import table from './../table'
import VARIANTS_TYPES from '../../config/variants-config'

export default class subvariantsChildRow {
    constructor(props) {
        this.props = props
        this.options = props.data.options
        this.row = document.createElement('tr')
        this.table = new table({ class: 'subvariants-table' }).render()
    }
    getHeaders(thead) {
        return thead.querySelectorAll('.v-th.count')
    }
    render() {
        this.row.className = 'v-tr subvariants-child-row'
        const td = new cellsFactory(VARIANTS_TYPES.EMPTY, { colspan: '100%' }).render()
        const container = document.createElement('div')
        container.className = 'subvariants-content'
        const bodyType = this.options.isSwr && this.options.isWebshopActive ? VARIANTS_TYPES.SWR : VARIANTS_TYPES.GENERIC_SUBVARIANTS

        const thead = new headersFactory(VARIANTS_TYPES.SUBCOLUMNS_HEADER, { ...this.props, bodyType }).render()
        const tbody = new bodiesFactory(bodyType, { ...this.props, headers: this.getHeaders(thead) }).render()

        this.table.appendChild(thead)
        this.table.appendChild(tbody)
        container.appendChild(this.table)
        td.appendChild(container)
        this.row.appendChild(td)
        return this.row
    }
}
