import axios from 'axios'

axios.defaults.headers = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
}
const PROTOCOL = window.location.protocol
const API_URL = `traceparts`
const LOCALE = document.documentElement.lang || 'en'
axios.defaults.baseURL = `${PROTOCOL}//${window.location.hostname}`

const getDownloadPaths = async (payload) => {
    return await axios.post(`${API_URL}/${LOCALE}/get-download-paths`, {
        id: payload.id,
        formatId: payload.formatId,
        email: payload.email,
    })
}

const getFormats = async (payload) => {
    return await axios.post(`${API_URL}/${LOCALE}/get-formats`, {
        id: payload.id,
    })
}

const checkCadAvailability = async (payload) => {
    return await axios.post(`${API_URL}/check-cad-availability`, {
        id: payload.id,
    })
}

export { getDownloadPaths, getFormats, checkCadAvailability }
