import radioCell from './radioCell'
import blueprintCell from './blueprintCell'
import cadCell from './cadCell'
import emptyCell from './emptyCell'
import dataCell from './dataCell'
import headerCell from './headerCell'
import stockCell from './stockCell'
import priceCell from './priceCell'
import arrowCell from './arrowCell'
import moreCell from './moreCell'
import fastBuyCell from './fastBuyCell'
import selectCell from './selectCell'
import configureCell from './configureCell'
import VARIANTS_TYPES from '../../config/variants-config'

export default class cellsFactory {
    constructor(type, props = {}) {
        if (type === VARIANTS_TYPES.EMPTY) return new emptyCell(props)
        if (type === VARIANTS_TYPES.RADIO) return new radioCell(props)
        if (type === VARIANTS_TYPES.BLUEPRINT) return new blueprintCell(props)
        if (type === VARIANTS_TYPES.CAD) return new cadCell(props)
        if (type === VARIANTS_TYPES.DATA) return new dataCell(props)
        if (type === VARIANTS_TYPES.HEADER) return new headerCell(props)
        if (type === VARIANTS_TYPES.STOCK) return new stockCell(props)
        if (type === VARIANTS_TYPES.PRICE) return new priceCell(props)
        if (type === VARIANTS_TYPES.ARROW) return new arrowCell(props)
        if (type === VARIANTS_TYPES.FAST_BUY) return new fastBuyCell(props)
        if (type === VARIANTS_TYPES.MORE) return new moreCell(props)
        if (type === VARIANTS_TYPES.SELECT) return new selectCell(props)
        if (type === VARIANTS_TYPES.CONFIGURE) return new configureCell(props)
    }
}
