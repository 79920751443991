//
// Utils
//

const Utils = (($) => {
    return {
        isIE11() {
            return !!window.MSInputMethodContext && !!document.documentMode
        },
        isEditmode() {
            if ($('body').hasClass('editmode')) {
                return true
            } else {
                return false
            }
        },
        viewport() {
            let viewport = $('.viewport')
            let currentViewport = viewport.find('div:visible')
            return currentViewport.data('viewport')
        },
        isMobile(){
            return this.viewport() === 'xs' || this.viewport() === 'sm' || this.viewport() === 'md'
        },
        getUrlParameter(sParam) {
            let sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=')

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : sParameterName[1]
                }
            }
        },
        mediaQueries() {
            return window.matchMedia('screen and (max-width: 991px)')
        },
        updateContentPadding() {
            const windowWidth = window.outerWidth
            const body = $('body')
            const main = $('main')
            const newSlingsTabs = $('.tabs-accordion--tabs.sticky-tabs')
            const header = $('.site-header')
            const filter = $('.filter')
            const productListing = $('.product-listing-info')

            const headerHeight = header.length > 0 ? header.outerHeight() : 0
            const filterHeight = filter.length > 0 ? filter.outerHeight() : 0
            const productInfoHeight = productListing.length > 0 ? productListing.outerHeight() : 0

            if (windowWidth >= 768) {
                body.css('paddingTop', `${headerHeight}px`)
                newSlingsTabs.css('top', `${headerHeight}px`)
            } else {
                body.css('paddingTop', '0')
                filter.css('top', `${headerHeight}px`)
                productListing.css('top', `${headerHeight + filterHeight}px`)
                main.css('marginTop', `${headerHeight + filterHeight + productInfoHeight}px`)
            }
        },
    }
})(jQuery)

export default Utils
