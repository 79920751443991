export default class swrForm {
    constructor(props) {
        this.props = props
        this.observer = props.observer
        this.swrForm = document.createElement('form')
    }
    render() {
        this.swrForm.setAttribute('class', 'swr-form')
        this.swrForm.id = 'swr_form'
        const endings = this.props.data.options.swrOptions.endings
        const packingList = this.props.data.options.swrOptions.packingList
        const ropeEndA = this.generateSelect('rope_ending_a', `${window.globals.trans('filter.end_a')}`, endings)
        const ropeLength = this.generateNumericInput('rope_length', `${window.globals.trans('filter.rope_length')}`, 'any')
        const ropeEndB = this.generateSelect('rope_ending_b', `${window.globals.trans('filter.end_b')}`, endings)
        const radioGroup = this.generateRadioGroup('packing_type', packingList)
        const template = `
            <div class="row">
                <div class="col-lg-12">
                    <h5 class="swr-header">${window.globals.trans('filter.ending_type')}</h5>
                </div>
            </div>
            <div class="row justify-center">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-sm-12 col-lg-4">
                            ${ropeEndA.outerHTML}
                        </div>
                        <div class="col-sm-12 col-lg-4">
                            ${ropeLength.outerHTML}
                        </div>
                        <div class="col-sm-12 col-lg-4 mb-4">
                            ${ropeEndB.outerHTML}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <h5 class="swr-header required">${window.globals.trans('filter.package_type')}</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="field-wrapper">${radioGroup.outerHTML}</div>
                 </div>
            </div>`
        this.swrForm.innerHTML = template
        this.initEventListeners()
        return this.swrForm
    }

    initEventListeners() {
        const numeric = this.swrForm.querySelector('.numeric-input')
        numeric.addEventListener('input', (e) => {
            if (this.isNumberKey(e)) {
                if (e.target.value === '0') {
                    e.target.value = '1'
                }
                this.observer.publish('ropeLength', e.target.value)
            }
        })
    }

    initSelectric() {
        const formSelects = this.swrForm.getElementsByClassName('filter-select')
        $(formSelects).selectric()
    }

    isNumberKey(evt) {
        var charCode = evt.which ? evt.which : evt.keyCode
        if (charCode == 46 || (charCode > 31 && (charCode < 48 || charCode > 57))) {
            evt.preventDefault()
            return false
        }
        return true
    }

    generateNumericInput(name, label, step) {
        const wrapper = document.createElement('div')
        const inputElem = document.createElement('input')
        const inputLabel = document.createElement('label')
        wrapper.classList.add('field-wrapper')
        inputLabel.classList.add('required')
        inputLabel.innerText = label
        inputElem.id = name
        inputElem.name = name
        inputElem.classList.add('numeric-input')
        inputElem.type = 'number'
        inputElem.setAttribute('value', 1)
        inputElem.min = 1
        inputElem.step = step
        wrapper.appendChild(inputLabel)
        wrapper.appendChild(inputElem)

        return wrapper
    }

    generateOption(value, label, isDefault = false) {
        const optionElem = document.createElement('option')
        optionElem.setAttribute('value', value)
        if (isDefault) {
            optionElem.setAttribute('disabled', true)
            optionElem.setAttribute('selected', true)
        }
        optionElem.innerText = label
        return optionElem
    }

    generateSelect(id, label, options) {
        const wrapper = document.createElement('div')
        const selectElem = document.createElement('select')
        const inputLabel = document.createElement('label')
        wrapper.classList.add('field-wrapper')
        inputLabel.classList.add('required')
        inputLabel.innerText = label
        selectElem.id = id
        selectElem.classList.add('filter-select')
        selectElem.appendChild(this.generateOption('', `${window.globals.trans('filter.select_type')}`, true))
        options.map((option) => {
            selectElem.appendChild(this.generateOption(option.id, option.label))
        })

        wrapper.appendChild(inputLabel)
        wrapper.appendChild(selectElem)

        return wrapper
    }

    generateRadioGroup(id, options) {
        const radioGroup = document.createElement('div')
        const formContainer = document.createElement('div')
        formContainer.classList.add('form-container')
        radioGroup.classList.add('radio-group')
        options.map((option) => {
            const formGroup = document.createElement('div')
            const radioElem = document.createElement('input')
            const radioLabel = document.createElement('label')
            formGroup.classList.add('form-group')
            radioElem.id = option.id
            radioElem.name = id
            radioElem.type = 'radio'
            radioElem.value = option.id
            radioElem.classList.add('radio')
            radioLabel.classList.add('radio-label')
            radioLabel.setAttribute('for', option.id)
            radioLabel.innerHTML = option.label
            formGroup.appendChild(radioElem)
            formGroup.appendChild(radioLabel)
            formContainer.appendChild(formGroup)
        })
        radioGroup.appendChild(formContainer)
        return radioGroup
    }
}
