let modalBlock

if (window.Vue) {
    modalBlock = window.Vue.component('modal-block', {
        template: `
            <div class="modal-overlay" @click="$emit('modal-click-outside')">
                <div class="modal-container position-relative mx-auto py-4" @click.stop>
                    <button type="button" class="close-modal" aria-label="close modal" @click="$emit('close-modal')">
                        <svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#close"></use></svg>
                    </button>
                    <slot />
                </div>
            </div>
        `,
    })
} else {
    modalBlock = null
}

export default modalBlock
