//
// Area - faq block
//

;(($) => {
    $(() => {
        let module = $('.area-faq-block')

        // Only continue if module is on page
        if (!module.length) return

        const items = document.querySelectorAll('.accordion__button')

        function toggleAccordion() {
            const isExpanded = this.getAttribute('aria-expanded') === 'true';
            this.setAttribute('aria-expanded', !isExpanded);
        }

        items.forEach((item) => item.addEventListener('click', toggleAccordion))
    })
})(jQuery)
