import { getArticles } from '../api/search-results'
import { getUrlParam, getUrlParams, updateUrlParam } from '../url-params/url-params'
import { startLoader, stopLoader } from '../filter-common/filterLoaders'
import Utils from '../utils/utils'
;(($) => {
    $(() => {
        const module = $('.search-results-wrapper')

        // Only continue if module is on page
        if (!module.length) return

        const pageSection = $('.search-results')
        const tabs = module.find('input[name=tabs-simple]')
        const articlesWrapper = module.find('.articles-wrapper')
        const articlesTotalElem = module.find('.articles-total')
        const placeholderImage = articlesWrapper.data('placeholderImage')
        const searchResultsQueryElem = module.find('.search-results__query')

        const dataFromUrl = getUrlParams()
        const query = dataFromUrl.query || ''
        const tab = dataFromUrl.tab || 'products'

        let page = parseInt(getUrlParam('page')) || 1
        let isLoading = true
        let isLast = false
        let articles = []
        let articlesTotal = 0

        tabs.on('change', (e) => {
            const activeTab = e.target.id
            Utils.updateContentPadding()
            if (activeTab === 'productsTab') {
                updateUrlParam('tab', 'products')
                setTopMargin('56px', '165px')
            } else {
                updateUrlParam('tab', 'articles')
                setTopMargin('165px', '56px')
            }
        })

        const setQueryText = (text) => {
            searchResultsQueryElem.html(text)
        }

        const setActiveTab = (tab) => {
            const activeTab = module.find(`#${tab}Tab`)
            activeTab.attr('checked', 'checked')
            if (tab === 'products') {
                setTopMargin('165px', '165px')
            } else {
                setTopMargin('56px', '56px')
            }
        }

        const setTopMargin = (from, to) => {
            if (window.innerWidth < '768') {
                pageSection.show().css({ marginTop: from }).animate({ marginTop: to }, 150)
            }
        }

        const initArticles = () => {
            startLoader('more-articles-loader')
            getArticles()
                .then((data) => {
                    articles = data.data.data.entries
                    articlesTotal = data.data.data.total
                    isLast = data.data.data.isLast
                    generateArticles(articles)
                    setArticlesTotal(articlesTotal)
                    isLoading = false
                    stopLoader('more-articles-loader')
                })
                .catch((err) => {
                    console.log(err)
                    isLoading = false
                    stopLoader('more-articles-loader')
                })
        }

        const setArticlesTotal = (total) => {
            articlesTotalElem.html(total)
        }

        const generateArticles = (articles) => {
            const articlesHTML = articles
                .map((article) => {
                    const imageElement =
                        article.image !== ''
                            ? `<figure class="article-item__photo--figure" style="background-image: url('${article.image}')"></figure>`
                            : `<img alt="${article.name}" class="article-item__photo--img" src="${placeholderImage}" />`

                    return `<a href="${article.url}" class="col-sm-6 col-lg-4 article-item">
                        <div class="article-item__photo ${article.image === '' ? 'use-placeholder' : ''}">${imageElement}</div>
                        <div class="article-item__title">${article.name}</div>
                    </a>`
                })
                .join('')
            articlesWrapper.html(articlesHTML)
        }

        const initInfiniteScroll = () => {
            $(window).scroll(() => {
                const isArticlesTabActive = document.getElementById('articlesTab').checked
                let pixelOffset = document.getElementsByClassName('site-footer')[0].clientHeight
                if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight - pixelOffset && isArticlesTabActive) {
                    getNextPage()
                }
            })
        }

        const getNextPage = () => {
            if (!isLoading && !isLast) {
                startLoader('more-articles-loader')
                page++
                isLoading = true
                getArticles(page)
                    .then((data) => {
                        isLoading = false
                        isLast = data.data.data.isLast
                        const newArticles = data.data.data.entries
                        articles = [...articles, ...newArticles]
                        generateArticles(articles)
                        stopLoader('more-articles-loader')
                    })
                    .catch((err) => {
                        console.log(err)
                        isLoading = false
                        stopLoader('more-results-loader')
                    })
            }
        }

        setActiveTab(tab)
        setQueryText(query)
        initArticles()
        initInfiniteScroll()
    })
})(jQuery)
