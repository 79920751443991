import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import Util from '../utils/utils'

//
// Featured products
//
;(($) => {
    $(() => {
        let modules = $('.featured-products')

        // Only continue if on product view page
        if (!modules.length) return

        const minSlides = 5

        modules.each(function (index, module) {
            const countSlides = $(this).find('.swiper-slide').length
            const carouselIndex = $(this).attr('data-index')

            if (countSlides > minSlides || Util.isMobile() ) {
                $(this).find('.swiper-button-next, .swiper-button-prev').removeClass('d-none')
                const sliderClass = '.featured-products_' + carouselIndex + ' .swiper-featured-products'

                new Swiper(sliderClass, {
                    modules: [Navigation],
                    slidesPerView: 2.5,
                    spaceBetween: 16,
                    grabCursor: true,
                    breakpoints: {
                        768: {
                            slidesPerView: 4,
                        },
                        992: {
                            slidesPerView: 5,
                        },
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    on: {
                        afterInit: function () {
                            $(module).removeClass('on-loading')
                        },
                    },
                })
            }
        })
    })
})(jQuery)
