import { FilterColumn } from './components/FilterColumn'
import { submitFilter } from './components/filterGroups/submit'
import { getSortingParams } from '../filter-common/sortingResults'
import { onCategoryBannerClick } from '../gtm/category-banner'
;(($) => {
    const module = $('.container.new-category-view #filters')
    const filter = $('.filter')
    const filterToggleButtonContainer = $('.toggle-button-container')
    const filterToggleButton = $('.toggle-filter-button')
    const filterCloseButton = $('.filter-categories-close-button')
    const listingContainer = $('.product-listing')
    const layoutButton = $('.layout-button')
    const sortButton = $('.sorting-button')
    const categoryBanners = $('.category-banner')

    // Only continue if module is on page
    if (!module.length) return

    new FilterColumn().initialize()

    filterToggleButton.on('click', () => {
        toggleMobileFilters()
    })

    filterCloseButton.on('click', () => {
        toggleMobileFilters()
    })

    const toggleMobileFilters = () => {
        filterToggleButtonContainer.toggleClass('as-apply')
        filter.toggleClass('visible')
    }

    layoutButton.on('click', (e) => {
        e.preventDefault()
        let button = e.target
        let isGrid = button.classList.contains('grid')
        layoutButton.removeClass('selected')
        button.classList.add('selected')
        if (isGrid) {
            listingContainer.addClass('grid-view')
            localStorage.setItem('view-type', 'grid')
        } else {
            listingContainer.removeClass('grid-view')
            localStorage.setItem('view-type', 'list')
        }
    })

    $(document).ready(function () {
        if ($.fn.selectric) {
            sortButton.selectric().on('change', function () {
                submitFilter(getSortingParams())
            })
        }
    })

    categoryBanners.each(function () {
        $(this).on('click', (e) => onCategoryBannerClick(e, e.currentTarget.id))
    })

    const checkViewSelectedByUser = () => {
        let viewType = localStorage.getItem('view-type')
        let isGrid = viewType === 'grid'
        if (typeof viewType === 'undefined' || viewType === null) {
            isGrid = listingContainer.hasClass('grid-view')
        }
        const gridButton = $('.layout-button.grid')
        const listButton = $('.layout-button.list')
        if (isGrid) {
            gridButton.addClass('selected')
            listButton.removeClass('selected')
            listingContainer.addClass('grid-view')
        } else {
            gridButton.removeClass('selected')
            listButton.addClass('selected')
            listingContainer.removeClass('grid-view')
        }
    }

    checkViewSelectedByUser()
})(jQuery)
