import OrderHeadingModuleConfig from '../common/orderHeadingModuleLoader'
import OrderRow from './order-row'
import ReorderModal from '../common/reorder-modal'
import SearchBar from '../common/search-bar'
import ProgressbarComponent from '../common/progressbar'
import axios from 'axios'

let OrderHeadingRow
if (window.Vue) {
    OrderHeadingRow = window.Vue.component('orders-heading-row', OrderHeadingModuleConfig)
} else {
    OrderHeadingRow = null
}

const CyesaOrdersConfig = {
    components: {
        OrderHeadingRow,
        OrderRow,
        ReorderModal,
        SearchBar,
        ProgressbarComponent,
    },
    data: function () {
        return {
            showNoOrdersSnippet: false,
            translations: {},
            ordersData: null,
            reorderId: null,
            currentPage: 1,
            pageCount: '',
            itemsPerPage: 20,
            searchQuery: '',
            dateFrom: '',
            dateTo: '',
            erpOrdersSyncInt: null,
            showSpinner: true,
            showProgressbar: false,
            progressBarPercentage: 0,
            smallProgressbar: true,
        }
    },
    methods: {
        fetchTranslations() {
            let locale = document.getElementsByTagName('html')[0].getAttribute('data-locale')
            axios
                .get(`/shop/my-orders/translation/${locale}`)
                .then((response) => {
                    this.translations = response.data
                })
                .catch((error) => {
                    console.error('Error while fetching translations', error)
                })
        },
        fetchOrdersData(initFetch = false, scrollToTop = false, hideProgressbar = false) {
            let locale = document.getElementsByTagName('html')[0].getAttribute('data-locale')
            axios
                .get('/shop/customer/orders/' + locale, {
                    params: {
                        limit: this.itemsPerPage,
                        query: this.searchQuery,
                        dateFrom: this.dateFrom,
                        dateTo: this.dateTo,
                        offset: this.currentPage * this.itemsPerPage - this.itemsPerPage,
                        ts: new Date().getTime(),
                    },
                })
                .then((response) => {
                    this.showSpinner = false
                    if (initFetch && !response.data.orders.length) {
                        this.ordersData = null
                    } else {
                        this.ordersData = response.data.orders
                    }

                    this.pageCount = Math.floor(response.data.max_results / this.itemsPerPage)

                    if (scrollToTop) {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        })
                    }

                    if (hideProgressbar) {
                        this.showProgressbar = false
                    }
                })
                .catch((error) => {
                    console.error('Error while fetching orders data', error)
                })
        },
        showReorderModal(orderId) {
            this.reorderId = orderId
        },
        setFiltersAndResetPagination(query, dateFrom, dateTo) {
            this.searchQuery = query
            this.dateFrom = dateFrom
            this.dateTo = dateTo
            this.currentPage = 1
            this.showSpinner = true
            this.fetchOrdersData()
        },
        handleQuerySearch(query, dateFrom, dateTo) {
            this.setFiltersAndResetPagination(query, dateFrom, dateTo)
        },
        handlePaginationChange() {
            this.showSpinner = true
            this.fetchOrdersData(false, true, false)
        },
        startErpPolling() {
            this.queryForSyncStatus()
            this.erpOrdersSyncInt = setInterval(() => {
                this.queryForSyncStatus()
            }, 1500)
        },
        queryForSyncStatus() {
            axios
                .get('/shop/customer/sync-orders-status', {
                    params: {
                        ts: new Date().getTime(),
                    },
                })
                .then((response) => {
                    if (response.data.active === false) {
                        this.showProgressbar = false
                        clearInterval(this.erpOrdersSyncInt)
                        this.fetchOrdersData(true, false, true)
                        if (response.data.total === 0) {
                            this.showSpinner = false
                            this.showNoOrdersSnippet = true
                            document.getElementById('no-orders-snippet').style.display = ''
                        }
                    } else {
                        if (response.data.total) {
                            if (!this.showProgressbar) this.showProgressbar = true

                            if (response.data.proceed !== 0 && response.data.total !== 0) {
                                this.progressBarPercentage = Math.floor((parseInt(response.data.proceed, 10) / parseInt(response.data.total, 10)) * 100)
                            } else {
                                this.progressBarPercentage = 0
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.error('Error while querying for orders sync status', error)
                })
        },
    },
    created() {
        this.fetchTranslations()
        this.fetchOrdersData(true, false, false)
        this.startErpPolling()
    },
    template: `
        <div v-if="!showNoOrdersSnippet && Object.keys(translations).length" class="my-orders fs-14">
            <progressbar-component
                v-if="showProgressbar"
                :translations="translations"
                :progress="progressBarPercentage"
                :small-progressbar="smallProgressbar"
            />
            <template v-if="ordersData">
                <reorder-modal v-if="reorderId" :order-id="reorderId" :translations="translations" @close-modal="reorderId = null" />
                <search-bar class="my-2"
                    @query-search="handleQuerySearch"
                    :translations="translations"
                    :current-search-query="searchQuery"
                    :current-date-from="dateFrom"
                    :current-date-to="dateTo"
                />

                <orders-heading-row :translations="translations" />
                <template v-if="!showSpinner">
                    <template v-if="ordersData.length">
                        <order-row
                            v-for="order in ordersData"
                            :order-data="order"
                            :key="order.orderId"
                            :translations="translations"
                            @reorder-comment-modal="showReorderModal"
                        />
                        <pagination-block
                            v-if="pageCount"
                            v-model="currentPage"
                            :page-count="pageCount"
                            :click-handler="handlePaginationChange"
                            :prev-text="translations['orders_history.pagination_prev']"
                            :next-text="translations['orders_history.pagination_next']"
                            :containerClass="'pagination'"
                        />
                    </template>
                    <template v-else>
                        <div class="text-primary text-center font-weight-bold fs-16 py-4">{{ translations["orders_history.no_results"] }}</div>
                    </template>
                </template>
                <div v-else class="lds-dual-ring mx-auto my-8 mb-md-0" role="status" />
            </template>
            <div v-else-if="!showProgressbar" class="lds-dual-ring mx-auto my-8 mb-md-0" role="status" />
        </div>
        <div v-else-if="!showNoOrdersSnippet" class="lds-dual-ring mx-auto my-8 mb-md-0" role="status" />
    `,
}

export default CyesaOrdersConfig
