import generateId from '../../../uuid/uuid'
import noUiSlider from 'nouislider'
import debounce from 'debounce'
import ClearButton from './clearButton'
import { toggleRangeTag, addTag } from './filterTags'

export default class RangeGroup {
    constructor(element) {
        this.name = element.name
        this.isEqual = element.equal
        this.text = element.text
        this.fieldsAttributes = element.fields
        this.unit = this.fieldsAttributes[0].unit || ''
        this.id = generateId()
        this.clearButton = new ClearButton(this.id).render()
        this.inputs = []
    }

    getInitialValues() {
        if (this.hasInitialValues()) {
            this.minValInit = Math.max(this.fieldsAttributes[0].initial, this.fieldsAttributes[0].value)
            this.maxValInit = Math.min(this.fieldsAttributes[1].initial, this.fieldsAttributes[1].value)
            return [this.minValInit.toFixed(2), this.maxValInit.toFixed(2)]
        } else {
            return [this.minVal.toFixed(2), this.maxVal.toFixed(2)]
        }
    }

    hasInitialValues() {
        if (this.fieldsAttributes[0].initial || this.fieldsAttributes[1].initial) {
            return true
        }
        return false
    }

    canRender() {
        this.minVal = this.fieldsAttributes[0].value
        this.maxVal = this.fieldsAttributes[1].value
        if (this.minVal === null && this.maxVal === null) {
            return false
        }

        return true
    }

    render() {
        if (this.canRender()) {
            const fields = this.fieldsAttributes.map((field, idx) => {
                const formGroup = document.createElement('div')
                const formInput = document.createElement('input')
                const units = document.createElement('span')
                const range = idx === 0 ? 'min' : 'max'
                const titleText = `${range}: ${field.value.toFixed(2)}`

                formGroup.className = 'form-group'

                formInput.setAttribute('type', 'input')
                formInput.setAttribute('class', 'input input-range')
                formInput.name = field.name
                formInput.id = field.id
                formInput.autocomplete = 'off'
                formInput.setAttribute('data-range', range)
                formInput.setAttribute('data-value', field.value)
                formInput.setAttribute('title', titleText)
                if (this.isEqual) {
                    formInput.setAttribute('value', field.value)
                }
                if (field.disabled) formInput.disabled = 'disabled'

                units.className = 'units-label'
                units.setAttribute('for', field.id)
                units.innerText = field.unit || ''
                this.inputs.push(formInput)
                formGroup.appendChild(formInput)
                formGroup.appendChild(units)

                return formGroup
            })

            const filterGroup = document.createElement('div')
            const containerGroup = document.createElement('div')
            const title = document.createElement('div')
            const icon = document.createElement('i')
            const rangeGroup = document.createElement('div')
            const buttonsContainer = document.createElement('div')
            const formSlider = document.createElement('div')

            containerGroup.className = 'form-container'
            filterGroup.id = this.id
            filterGroup.setAttribute('class', 'filter-group filter-range')
            title.className = 'title'
            title.innerText = this.text

            rangeGroup.setAttribute('class', 'fields range-group')
            rangeGroup.setAttribute('data-name', this.name)

            fields.forEach((element) => {
                containerGroup.appendChild(element)
            })

            formSlider.id = `slider_${this.name}`
            formSlider.className = 'filter-range-slider'

            if (!this.isEqual) {
                this.initRangeSlider(formSlider)
                if (this.hasInitialValues()) {
                    addTag(this.name, `${this.text} ${this.minValInit} - ${this.maxValInit} ${this.unit}`, this.id, 'range', this.rangeSlider)
                }
            }

            buttonsContainer.className = 'display-inline'

            this.clearButton.addEventListener('click', () => {
                this.rangeSlider.set([this.minVal, this.maxVal])
            })

            // Listen to keydown events on the input field.
            this.inputs.forEach((input, handle) => {
                this.addKeyboardSupport(input, handle)
            })

            title.appendChild(icon)
            filterGroup.appendChild(title)
            if (!this.isEqual) {
                rangeGroup.appendChild(formSlider)
            }
            rangeGroup.appendChild(containerGroup)
            if (!this.isEqual) {
                buttonsContainer.appendChild(this.clearButton)
            }
            rangeGroup.appendChild(buttonsContainer)
            filterGroup.appendChild(rangeGroup)

            return filterGroup
        }

        return document.createElement('div')
    }

    initRangeSlider(slider) {
        this.rangeSlider = noUiSlider.create(slider, {
            start: this.getInitialValues(),
            step: 0.01,
            animate: true,
            animationDuration: 1600,
            connect: true,
            range: {
                min: this.minVal,
                max: this.maxVal,
            },
        })
        this.rangeSlider.on('update', (values, handle) => {
            this.inputs[handle].value = values[handle]
        })
        this.rangeSlider.on('end', () => {
            toggleRangeTag(this.id, this.rangeSlider)
        })
    }

    addKeyboardSupport(input, handle) {
        input.addEventListener(
            'keyup',
            debounce((e) => {
                const userValue = e.target.value

                if (userValue) {
                    this.rangeSlider.setHandle(handle, e.target.value)
                    toggleRangeTag(this.id, this.rangeSlider)
                }
            }, 1000),
        )
    }
}
