import VARIANTS_TYPES from '../../config/variants-config'

export default class blueprintCell {
    constructor(props) {
        this.cell = document.createElement('td')
        this.props = props
        this.productName = document.getElementsByClassName('npp__title--heading1')[0].innerText
        this.blueprintUrl = props.variant.blueprintUrl || null
        this.$blueprintModal = $('#blueprintModal')
    }

    handleClick(e) {
        e.stopPropagation()
        window.dataLayer.push({
            event: 'GA Tracking',
            eventCategory: 'Blueprint',
            eventAction: 'View blueprint',
            eventLabel: this.productName,
            eventValue: 0,
            eventNonInteraction: false,
        })
        const $modalTitle = this.$blueprintModal.find('.modal-title')
        const $modalDownloadButton = this.$blueprintModal.find('.blueprint-download')
        const $modalCarousel = this.$blueprintModal.find('.blueprint-carousel')
        const $modalTable = this.$blueprintModal.find('.blueprint-measurement-table')
        $modalTitle.html(`${window.globals.trans('filter.blueprint')} - ${this.props.variant.PartCode}`)
        $modalDownloadButton.attr('href', this.blueprintUrl)
        if (this.blueprintUrl) {
            $modalDownloadButton.addClass('show')
        } else {
            $modalDownloadButton.removeClass('show')
        }
        $modalTable.html(this.generateMeasurementTable())
        $modalCarousel.trigger('destroy.owl.carousel')
        $modalCarousel.find('.owl-stage-outer').children().unwrap()
        $modalCarousel.removeClass('owl-center owl-loaded owl-text-select-on')
        $modalCarousel.html(this.generateBlueprintDrawings())
        $modalCarousel.owlCarousel({
            loop: false,
            margin: 10,
            items: 1,
            lazyLoad: true,
            autoHeight: false,
            nav: true,
            dots: false,
            navText: [
                '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#previous"></use></svg>',
                '<svg class="icon icon-expand"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/static/icons/symbol/svg/sprite.symbol.svg#next"></use></svg>',
            ],
        })

        this.$blueprintModal.modal()
    }

    render() {
        this.cell.className = 'v-td blueprint-cell count'
        this.cell.setAttribute('data-key', VARIANTS_TYPES.BLUEPRINT)
        const element = document.createElement('i')
        element.setAttribute('class', 'icon-blueprint')
        element.addEventListener('click', (e) => this.handleClick(e))
        this.cell.appendChild(element)
        return this.cell
    }

    generateMeasurementTable() {
        const measurements = this.prepareTableData()
        let mContainer = ''
        measurements.map((measure) => {
            mContainer += `<ul><li class="label">${measure.label}</li><li class="value">${measure.value}</li></ul>`
        })
        return mContainer
    }

    generateBlueprintDrawings() {
        return this.props.variant.blueprintImages
            .map(
                (image) =>
                    `<div class="item">${image.html}${image.heading?.length > 0 ? `<span class="d-inline-block mb-2">${image.heading}</span>` : ``}</div>`,
            )
            .join('')
    }

    prepareTableData() {
        let tableData = []
        this.props.columns.filter((column) => {
            if (column.key.startsWith('Measurement.')) {
                const value = this.props.variant[column.key] || '-'
                tableData.push({ label: column.label, value: value })
            }
        })
        return tableData
    }
}
