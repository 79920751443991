import cellsFactory from '../cellsFactory/cellsFactory'
import { hideMoreDetailsColumns, isMeasurementKey } from '../partials/columnsController'
import table from '../table'
import VARIANTS_TYPES from '../../config/variants-config'

export default class moreDetailsSwrRow {
    constructor(props) {
        this.props = props
        this.observer = props.observer
        this.productName = props.data.productName
        this.row = document.createElement('tr')
        this.table = new table({ class: 'more-details-table' }).render()
        this.headers = props.headers
        this.columnsData = props.columnsData
        this.options = props.data.options
        this.updatePrice = this.options.isCustomerUniquePriceActive
        this.subscribe = true
    }

    update(data) {
        const { id, variant } = data
        if (this.props.variantsData.id !== id) return
        this.props.variantsData = variant
        this.table = new table({ class: 'more-details-table' }).render()
        this.render()
    }

    render() {
        const isOpened = this.row.classList.contains('open')
        this.row.innerHTML = ''
        this.row.className = `v-tr more-details-row${isOpened ? ' open' : ''}`
        if (this.props.variantsData.id) {
            this.row.setAttribute('id', this.props.variantsData.id)
        }
        const td = new cellsFactory(VARIANTS_TYPES.EMPTY, { colspan: '100%' }).render()
        const tbody = document.createElement('tbody')
        Array.from(this.headers).map((header) => {
            const headerKey = header.getAttribute('data-key')
            const cellValue = this.props.variantsData[headerKey]
            const tr = document.createElement('tr')
            tr.classList.add('more-detail-item')
            const tdLabel = document.createElement('td')
            tdLabel.classList.add('more-details-label')
            tdLabel.innerHTML = header.innerHTML
            tr.appendChild(tdLabel)

            if (headerKey === 'Stock') {
                tr.appendChild(new cellsFactory(VARIANTS_TYPES.STOCK, { variant: this.props.variantsData, stockDisplay: this.options.stockDisplay }).render())
                tr.classList.add('hide-on-mobile', 'stock-more')
            } else if (headerKey === 'Price') {
                tr.appendChild(
                    new cellsFactory(VARIANTS_TYPES.PRICE, {
                        variant: this.props.variantsData,
                        priceType: 'piece',
                        observer: this.observer,
                        updatePrice: this.updatePrice,
                    }).render(),
                )
                tr.classList.add('price-more')
            } else if (headerKey === 'BatchPrice') {
                tr.appendChild(
                    new cellsFactory(VARIANTS_TYPES.PRICE, {
                        variant: this.props.variantsData,
                        priceType: 'batch',
                        observer: this.observer,
                        updatePrice: this.updatePrice,
                    }).render(),
                )
                tr.classList.add('price-more')
            } else if (headerKey === VARIANTS_TYPES.BLUEPRINT && this.options.isBlueprint) {
                tr.appendChild(
                    new cellsFactory(VARIANTS_TYPES.BLUEPRINT, {
                        variant: this.props.variantsData,
                        columns: this.columnsData,
                        productName: this.productName,
                    }).render(),
                )
                tr.classList.add('blueprint-more')
            } else if (headerKey === VARIANTS_TYPES.CAD && this.options.isCadDownloadActive) {
                tr.appendChild(
                    new cellsFactory(VARIANTS_TYPES.CAD, { variant: this.props.variantsData, columns: this.columnsData, observer: this.observer }).render(),
                )
                tr.classList.add('cad-more', 'hide-everywhere')
            } else {
                if (!isMeasurementKey(headerKey)) {
                    tr.appendChild(new cellsFactory('data', { value: cellValue, key: headerKey }).render())
                }
            }
            tbody.appendChild(tr)
        })

        hideMoreDetailsColumns(tbody)

        this.table.appendChild(tbody)
        td.appendChild(this.table)
        this.row.appendChild(td)

        if (this.subscribe) {
            this.subscribe = false
            this.observer.subscribe({
                event: 'updateMoreDetailsSwrRow',
                action: (data) => this.update(data),
            })
        }

        return this.row
    }
}
