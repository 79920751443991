export default class radioCell {
    constructor(props) {
        this.cell = document.createElement('td')
        this.props = props
    }
    render() {
        this.cell.setAttribute('class', 'v-td radio-cell')
        const element = document.createElement('i')
        element.setAttribute('class', 'icon-radio')
        this.cell.appendChild(element)
        return this.cell
    }
}
