import Util from '../../utils/utils'

//
// Main navigation
//
;(($) => {
    $(() => {
        let module = $('.main-navigation')
        let isLegacyNavigationActive = $('.site-header').hasClass('legacy-nav')

        // Only continue if module is on page
        if (!module.length) return

        if (isLegacyNavigationActive) {
            let toggle = $('.site-header .main-navigation-toggle button')
            let dropdown = module.find('.main-navigation-items .dropdown')
            let dropDownToggle = module.find('.main-navigation-items .collapse-toggle')

            toggle.click(() => {
                module.toggleClass('open')
            })

            // Click outside of main navigation
            $(document).on('click', (event) => {
                if (
                    !$(event.target).closest('.main-navigation > .container').length &&
                    !$(event.target).closest('.site-header .main-navigation-toggle button').length
                ) {
                    module.removeClass('open')
                }
            })

            // Mobile and Tablet dropdown functionality

            dropDownToggle.click(function (e) {
                if (Util.isMobile()) {
                    const thisDropDownToggle = $(this)
                    let dropdown = thisDropDownToggle.closest('li').find('.dropdown')

                    e.preventDefault()
                    dropdown.collapse('toggle')
                }
            })

            dropdown.on('hide.bs.collapse', function () {
                if (Util.isMobile()) {
                    $(this).closest('li').find('.collapse-toggle').addClass('collapsed')
                }
            })

            dropdown.on('show.bs.collapse', function () {
                if (Util.isMobile()) {
                    $(this).closest('li').find('.collapse-toggle').removeClass('collapsed')
                }
            })
        } else {
            let toggle = $('.site-header .main-navigation-toggle button')
            let dropDownToggle = module.find('.main-navigation-items .collapse-toggle')
            let slinkyMenu = module.find('.slinky-menu')
            let productsList = module.find('.menu-group.js-height')
            let mobileMenuFooter = module.find('.menu-footer')
            let menuItem = module.find('.menu-item')
            let jsAutoHeight = module.find('.js-auto-height')
            let body = $('body')
            let backdrop = body.find('.backdrop')
            let simpleMenu = body.find('.simple-menu')
            let mobileMenu = null
            let desktopMenu = false

            // Maintain menu item hover state when scrollbar is enabled
            const initMaintainHoverState = () => {
                const menuProductsSubmenu = $('.menu-products-submenu')
                const submenuItemsGroup = menuProductsSubmenu.find('.submenu-items-group')

                $('.root-item').hover(function () {
                    $('.menu-item').find('.active').removeClass('active').find('.submenu-items').css({
                        display: 'none',
                        visibility: 'hidden',
                        opacity: '0',
                    })
                })

                submenuItemsGroup.hover(function () {
                    $(this).addClass('active').siblings('.active').removeClass('active').find('.submenu-items').css({
                        display: 'none',
                        visibility: 'hidden',
                        opacity: '0',
                    })
                    $('.active').find('.submenu-items').css({
                        display: 'block',
                        visibility: 'visible',
                        opacity: '1',
                    })
                })
            }

            // Init backdrop cover
            const initBackdropAnim = () => {
                menuItem.hover(
                    function () {
                        backdrop.css({ display: 'block', 'z-index': -1 })
                    },
                    function () {
                        backdrop[0].removeAttribute('style')
                    },
                )
            }

            // Init on resize browser window
            $(window).resize(function () {
                let windowWidth = window.innerWidth
                let breakpoint = 991
                module.removeClass('open')
                if (windowWidth > breakpoint) {
                    destroyMobileMenu()
                    createDesktopMenu()
                    removeMobileMenuHeight()
                } else {
                    destroyDesktopMenu()
                    createMobileMenu()
                }
            })

            // Init desktop mega menu
            const createDesktopMenu = () => {
                setHeight(productsList)
                desktopMenu = true
            }

            // Destroys all inline styles added by js, on desktop view
            const destroyDesktopMenu = () => {
                if (desktopMenu) {
                    productsList.css({ height: 'auto' })
                    desktopMenu = false
                }
            }

            // Init Slinky mobile menu
            const createMobileMenu = () => {
                setMobileMenuHeight()
                if (mobileMenu == null) {
                    jsAutoHeight.find('.active').removeClass('active')
                    mobileMenu = slinkyMenu.slinky({
                        title: true,
                    })
                }
            }

            // Destroys Slinky mobile menu
            const destroyMobileMenu = () => {
                if (mobileMenu != null) {
                    mobileMenu.destroy()
                    removeAllInlineStyles()
                    mobileMenu = null
                }
            }

            // On mobile, we have to set elements height for scrollbar
            const setMobileMenuHeight = () => {
                let deviceHeight = document.documentElement.clientHeight
                let mobileMenuHeader = module.find('.default-header')
                let footerHeight = mobileMenuFooter[0].offsetHeight
                let headerHeight = mobileMenuHeader[0].offsetHeight

                let treeHeight = deviceHeight - (headerHeight + footerHeight)

                jsAutoHeight.each(function () {
                    $(this).height(treeHeight)
                    $(this).addClass('overflow-y-scroll')
                })
            }

            // When browser window is resized, then we have to remove inline styles and classes from element
            const removeMobileMenuHeight = () => {
                jsAutoHeight.each(function () {
                    this.removeAttribute('style')
                    $(this).removeClass('overflow-y-scroll')
                })
            }

            // When browser window is resized, then we have to remove all inline styles added by Slinky plugin
            const removeAllInlineStyles = () => {
                let allMenuItems = module.find('ul')
                allMenuItems.each(function () {
                    this.removeAttribute('style')
                })
                removeMobileMenuHeight()
            }

            // Calculating mega menu height on desktop
            const setHeight = (menu) => {
                const maxHeight = 792
                menu.each(function () {
                    const minHeight = this.offsetHeight
                    const bgFillSelector = $(this).find('.bg-fill')
                    const rootItems = $(this).find('.root-item')
                    const menuProductsSubmenu = $(this).find('.menu-products-submenu')
                    const submenuItems = $(this).find('.submenu-items')
                    const menuGroupArticles = $(this).find('.menu-group-articles')
                    let outerHeight = 0

                    let newHeight = findHighestElement(this, minHeight)
                    if (newHeight > minHeight) {
                        newHeight = newHeight + 30
                    }

                    if (newHeight > maxHeight) {
                        newHeight = maxHeight
                    }

                    $(this).height(newHeight)
                    menuProductsSubmenu.height(newHeight)
                    submenuItems.height(newHeight)
                    menuGroupArticles.height(newHeight)

                    // Under menu bg-fill element height
                    rootItems.each(function () {
                        outerHeight += $(this).height()
                    })

                    let bgFillHeight = newHeight - outerHeight
                    if (bgFillHeight > 0) {
                        bgFillSelector.height(bgFillHeight)
                    }
                })
            }

            // Finding highest element in mega menu
            const findHighestElement = (elem, minHeight) => {
                let menuContent = $(elem).find('.js-auto-height')
                let newHeight = minHeight
                menuContent.each(function () {
                    if (this.offsetHeight > newHeight) {
                        newHeight = this.offsetHeight
                    }
                })
                return newHeight
            }

            // Click outside of main navigation
            $(document).on('click', (event) => {
                if (
                    !$(event.target).closest('.main-navigation > .container').length &&
                    !$(event.target).closest('.site-header .main-navigation-toggle button').length
                ) {
                    module.removeClass('open')
                }
            })

            // Mobile and Tablet dropdown functionality
            dropDownToggle.click(function (e) {
                //close previous item
                let clickedArrow = $(this)
                let clickedDropdown = clickedArrow.closest('li').find('.dropdown')
                let prevDropdown = module.find('.collapse.show')
                let prevArrow = prevDropdown.parent().find('.collapse-toggle')
                closeCategory()

                e.preventDefault()
                if (clickedArrow[0] !== prevArrow[0]) {
                    clickedDropdown.collapse('show')
                    clickedArrow.removeClass('collapsed')
                }
            })

            const closeCategory = () => {
                let prevDropdown = module.find('.collapse.show')
                let prevArrow = prevDropdown.parent().find('.collapse-toggle')
                prevDropdown.collapse('hide')
                prevArrow.addClass('collapsed')
            }

            toggle.click(() => {
                module.toggleClass('open')
                $('#table_cart').toggleClass('z-index-1000')
            })

            if (Util.isMobile()) {
                module.find('.menu-group-articles').remove()
                createMobileMenu()
            } else {
                createDesktopMenu()
                initBackdropAnim()
                initMaintainHoverState()
            }

            simpleMenu.hover(null, closeCategory)
        }
    })
})(jQuery)
