import { postFilters } from '../../../api/variants'
import debounce from 'debounce'
import { startLoader, stopLoader } from '../../../filter-common/filterLoaders'
import generateResults from '../FilterResultsGenerator'
import prepareFilterData from '../../../filter-common/prepareFilterData'

const submitFilter = debounce(() => {
    startLoader('results-loader')

    const fData = prepareFilterData()

    postFilters(fData)
        .then((data) => {
            stopLoader('results-loader')
            const resData = data.data.data
            generateResults(resData)
        })
        .catch((error) => {
            stopLoader('results-loader')
            console.log(error)
        })
}, 500)

export { submitFilter }
