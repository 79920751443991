const whichBrowser = () => {
    if (isFirefox()) {
        return 'Firefox'
    } else if (isEdge()) {
        return 'Edge'
    } else if (isIE()) {
        return 'Internet Explorer'
    } else if (isOpera()) {
        return 'Opera'
    } else if (isVivaldi()) {
        return 'Vivalid'
    } else if (isChrome()) {
        return 'Chrome'
    } else if (isSafari()) {
        return 'Safari'
    } else {
        return 'Unknown'
    }
}

const agentHas = (keyword) => {
    return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1
}

const isIE = () => {
    return !!document.documentMode
}

const isSafari = () => {
    return (!!window.ApplePaySetupFeature || !!window.safari) && agentHas('Safari') && !agentHas('Chrome') && !agentHas('CriOS')
}

const isChrome = () => {
    return agentHas('CriOS') || agentHas('Chrome') || !!window.chrome
}

const isFirefox = () => {
    return agentHas('Firefox') || agentHas('FxiOS') || agentHas('Focus')
}

const isEdge = () => {
    return agentHas('Edg')
}

const isOpera = () => {
    return agentHas('OPR')
}

const isVivaldi = () => {
    return agentHas('Vivaldi')
}

export default whichBrowser
